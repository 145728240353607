import { ratioAsNumber } from '../../utils/images'

interface ShopifyImageOptions {
  modifiers: {
    width: number;
    ratio?: string;
  };
}

interface ShopifyImageParams {
  src: string;
  width: number;
  height?: number;
  cropping?: '_crop_center';
}

interface ShopifyImageResult {
  url: string;
}

function constructShopifyImageUrl({
  src,
  width,
  height,
  cropping = '_crop_center',
}: ShopifyImageParams): string {
  const url = `${src}?`
  const dotIndex = url.lastIndexOf('.')
  const format = width ? `_${Math.ceil(width)}x${height ? `${Math.ceil(height)}` : ''}${cropping}` : ''

  return [url.slice(0, dotIndex), `${format}`, url.slice(dotIndex)].join('')
}

export const getImage = (src: string, { modifiers }: ShopifyImageOptions): ShopifyImageResult => {
  if (!modifiers.ratio) return getImageNatural(src, { modifiers })

  const finalWidth = Math.min(modifiers.width, 1600)
  const ratio = ratioAsNumber(modifiers?.ratio)
  const height = Math.ceil(finalWidth / ratio)

  return {
    url: constructShopifyImageUrl({
      src,
      width: finalWidth,
      height,
      // quality,
    }),
  }
}

export const getImageNatural = (src: string, { modifiers }: ShopifyImageOptions): ShopifyImageResult => {
  const finalWidth = Math.min(modifiers.width, 1600)

  return {
    url: constructShopifyImageUrl({
      src,
      width: finalWidth,
      // height,
      // quality,
    }),
  }
}
