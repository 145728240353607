import SanityContentImage from './content/SanityContentImage.vue'
import SanityContentShare from './content/SanityContentShare.vue'
import SanityContentColumns from './content/SanityContentColumns.js'
import SanityContentBlocks from './content/SanityContentBlocks.js'
import { h } from 'vue'

const SanityContentTemplate = {
  name: 'SanityContentTemplate',
  props: {
    content: String,
  },
  render() {
    return h({
      template: this.content,
      components: {
        SanityContentImage,
        SanityContentShare,
        SanityContentColumns,
        SanityContentBlocks,
      },
    })
  },
}

export default SanityContentTemplate
