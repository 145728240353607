import { h } from 'vue'

export default {
  name: 'Link',
  functional: true,
  props: { value: Object, text: String },
  render(ctx) {
    console.log(ctx.$props)
    if (!ctx?.$props) return null
    const url = ctx.$props?.value.url
    const text = ctx.$props?.text

    return h(
      'a',
      {
        class: 'sanity-content-link',
        href: url,
      },
      text
    )
  },
}
