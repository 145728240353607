<template>
  <div class="header-submenu-menu">
    <h3 v-if="title" class="header-submenu-menu__heading">{{ title }}</h3>
    <GlobalLinks v-if="getMenu" class="header-submenu-menu__links" :links="getMenu" />
  </div>
</template>

<script>
import GlobalLinks from '../global/GlobalLinks.vue'

export default {
  components: {
    GlobalLinks,
  },
  props: {
    title: String,
    menu: String,
  },
  computed: {
    getMenu() {
      return this.$store.state.shopify.menus[this.menu]
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.header-submenu-menu {
  &__heading {
    @include serif;
    @include cx-uc;
    /* margin-bottom: 5px; */
  }

  &__links {
    --foreground: var(--color-accent);
    display: flex;
    flex-direction: column;
    counter-reset: linkCounter;
  }

  .global-link {
    position: relative;
    counter-increment: linkCounter;

    &:before {
      content: counter(linkCounter, decimal-leading-zero);
      position: absolute;
      right: calc(100% + 16px);
      top: 0;
      opacity: 0;
      transition: opacity var(--easing-time) var(--easing);
    }

    &:hover,
    &--active {
      color: var(--color-off-black);

      &:before {
        opacity: 1;
      }
    }
  }
}
</style>
