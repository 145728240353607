interface OverlayState {
  current: string;
}

export const state = (): OverlayState => ({
  current: '',
})

export const actions = {}

export const mutations = {
  open(state: OverlayState, value: string): void {
    state.current = value
  },
  toggle(state: OverlayState, value: string): void {
    state.current = state.current === value ? '' : value
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
