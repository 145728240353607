<template>
  <a :href="link.url" :class="getLinkClass">
    {{ link.title }}
  </a>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'GlobalLink',
  props: {
    link: Object,
  },
  computed: {
    ...Vuex.mapState({
      shopify: (state) => state.shopify,
    }),
    getLinkClass() {
      const { template } = this.shopify
      const { type, title } = this.link

      let active = false

      if (this.link.active) active = true

      // Active in shop
      if (type == 'catalog_link' && title == 'Shop') {
        if (template.indexOf('product') > -1) active = true
      }

      // TODO: Active on product url
      // const {handle} = this.link.object;
      // if(type == 'collection')

      return {
        'global-link--active': active,
        active,
      }
    },
  },
}
</script>

<style lang="scss"></style>
