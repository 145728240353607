<template>
  <div class="page-article">
    <ArticleShow v-if="blog.handle == 'journal'" :article="article" :blog="blog" />
  </div>
</template>

<script>
import ArticleShow from '../components/article/ArticleShow.vue'

export default {
  components: {
    ArticleShow,
  },
  props: {
    blog: Object,
    article: Object,
  },
}
</script>

<style lang="scss"></style>
