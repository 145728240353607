<template>
  <transition name="fade">
    <div class="overlay overlay-currency" :class="`${overlay == 'currency' ? 'active' : ''}`">
      <div class="currency-inner">
        <button class="close" @click="$store.commit('overlay/open', '')">
          <SvgClose />
        </button>
        <h2>Currency</h2>
        <ul>
          <li v-for="(_currency, index) in currencies" :key="`overlay-currency__currency_${index}`">
            <button
              class="a"
              @click.prevent="$store.commit('currency/set', _currency)"
              :class="`${_currency == currency ? 'active' : ''}`"
            >
              {{ _currency }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex'
import SvgClose from '~/components/svg/SvgClose.vue'

export default {
  data() {
    return {
      currencies: ['USD', 'GBP', 'CAD', 'EUR', 'NZD', 'AUD'],
    }
  },
  computed: {
    ...Vuex.mapState({
      overlay: (state) => state.overlay.current,
      currency: (state) => state.currency.current,
    }),
    isActive(overlay) {
      return overlay.s.current == 'currency'
    },
  },
  components: {
    SvgClose,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay.overlay-currency {
  @include helper-overlay-close();
  .currency-inner {
    @include helper-overlay-inner($width: 200px);
    @include cx-s();

    h2 {
      @include cx-m();
      padding: 20px 20px 15px;
    }
    ul {
      @include cx-m();
      @include helper-link-underline();
      padding: 10px 20px 40px;
      li {
        + li {
          padding-top: 10px;
        }
      }
    }
  }
  &.active {
    .currency-inner {
      @include helper-overlay-inner-active();
    }
  }

  @include respond-to(s) {
    .currency-inner {
      @include helper-overlay-mobile();
    }
    &.active {
      .currency-inner {
        right: 0;
      }
    }
  }
}
</style>
