<template>
  <section class="product-gallery">
    <div class="product-gallery__slides">
      <button
        v-for="(image, index) in product.images"
        :key="`product-gallery__slide_${index}`"
        class="product-gallery__slide"
        @click="$store.commit('overlay/open', 'gallery')"
      >
        <GlobalReveal>
          <img
            :ref="`gallery_image_${image.src}`"
            class="product-gallery__image"
            :src="image.src"
            :alt="image.alt"
          />
        </GlobalReveal>
      </button>

      <div v-if="product.metafields.video" class="product-gallery__slide">
        <video class="product-gallery__video" controls mute>
          <source :src="product.metafields.video" />
        </video>
      </div>
    </div>
  </section>
</template>

<script>
import GlobalReveal from '../global/GlobalReveal.vue'
export default {
  components: { GlobalReveal },
  props: {
    product: Object,
  },
}
</script>

<style lang="scss">
.product-gallery {
  margin-top: var(--page-top);
  &__slide {
    /* margin: 0 $gutter; */
    + .product-gallery__slide {
      margin-top: 10px;
    }
  }

  &__image {
    width: 100%;
    cursor: pointer;
  }

  &__video {
    width: 100%;
    height: auto;
    display: block;
  }
}
</style>
