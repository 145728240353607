<template>
  <section class="page-heading">
    <div class="heading-inner">
      <GlobalReveal>
        <h1>{{ title }}</h1>
      </GlobalReveal>
    </div>
  </section>
</template>

<script>
import GlobalReveal from '../global/GlobalReveal.vue'
export default {
  components: { GlobalReveal },
  props: {
    title: String,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.page-heading {
  @include wrapper();
  padding-bottom: 80px;
  .heading-inner {
    @include sans;
    @include cx-xl;
    @include cx-uc;
    @include offset(4);
    @include offset(4, right);
    text-align: center;
  }
}
</style>
