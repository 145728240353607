<template>
  <section class="product-reviews" id="reviews">
    <h2 class="product-reviews__heading">Product Reviews</h2>
    <ReviewsSummary class="product-reviews__status" />
    <ReviewsList class="product-reviews__list" />
  </section>
</template>

<script>
import Vuex from 'vuex'

import ReviewsSummary from '~/components/review/ReviewSummary.vue'
import ReviewsList from '~/components/review/ReviewList.vue'

export default {
  props: {
    productId: Number,
  },
  computed: {
    ...Vuex.mapState({
      reviews: (state) => state.product.reviews,
    }),
  },
  components: {
    ReviewsSummary,
    ReviewsList,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-reviews {
  @include wrapper;
  padding-left: columns(4);
  padding-right: columns(4);

  @include respond-to(xl) {
    padding-left: columns(3);
    padding-right: columns(3);
  }

  @include respond-to(m) {
    padding-left: columns(1);
    padding-right: columns(1);
  }

  &__heading {
    padding: 12px 0;
    text-transform: uppercase;
    @include sans;
    @include cx-l;
    border-top: 1px solid $dark;
    border-bottom: 1px solid $dark;
  }
}
</style>
