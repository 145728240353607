<template>
  <GlobalReveal class="component component-pagination" v-if="pages > 1">
    <div class="pagination-inner">
      <nav>
        <ul>
          <li class="control control-prev" v-if="previousUrl">
            <a :href="previousUrl">
              <div class="arrow">
                <SvgArrowNewsletter />
              </div>
              Previous Page
            </a>
          </li>

          <li class="control control-next" v-if="nextUrl">
            <a :href="nextUrl">
              Next Page
              <div class="arrow">
                <SvgArrowNewsletter />
              </div>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </GlobalReveal>
</template>

<script>
import GlobalReveal from './GlobalReveal.vue'
import SvgArrowNewsletter from '../svg/SvgArrowNewsletter.vue'

export default {
  props: {
    pages: Number,
    previousUrl: String,
    nextUrl: String,
  },
  components: { GlobalReveal, SvgArrowNewsletter },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.component-pagination {
  @include clearfix();
  @include wrapper();
  .pagination-inner {
    padding: 0 #{$mobile_inset - 15px} 100px;
    nav {
      text-align: center;
      ul {
        @include hlist();
        li {
          @include cx-s();
          @include cx-uc();
          @include sans();
          padding: 0 15px;

          svg {
            width: 18px;
            height: 10px;
            padding: 0px;
            display: inline-block;
          }
          svg {
            transition: transform 0.4s ease;
          }
          &.control-next,
          &.control-prev {
            .arrow {
              display: inline-block;
            }
            &:hover .arrow svg {
              transform: translateX(0px);
            }
          }
          &.control-next {
            .arrow {
              svg {
                margin-left: 10px;
                transform: translateX(-5px);
              }
            }
          }
          &.control-prev {
            .arrow {
              transform: rotate(180deg);
              svg {
                margin-left: 10px;
                transform: translateX(-5px);
              }
            }
          }
        }
      }
    }
  }
  @include respond-to(s) {
    .pagination-inner {
      nav {
        text-align: right;
        ul {
          li {
          }
        }
      }
    }
  }
}
</style>
