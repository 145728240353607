<template>
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.39926 1.22494L14.349 6.17469L9.39925 11.1244M13.9998 6.17418L6.48191e-08 6.17436"
      stroke="#585045"
      stroke-width="0.75"
    />
  </svg>
</template>

<script></script>

<style lang="scss"></style>
