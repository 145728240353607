<template>
  <GlobalReveal class="article-card">
    <a :href="`/blogs/${article.handle}`" class="article-card__link">
      <ShopifyImage class="article-card__image" :src="article.image" ratio="7x5" />

      <div class="article-card__heading">
        <h2 class="article-card__title">{{ article.title }}</h2>
        <p
          v-if="article.metafields.attributions"
          class="article-card__attributions"
          v-html="article.metafields.attributions"
        ></p>
      </div>
    </a>
  </GlobalReveal>
</template>

<script>
import GlobalReveal from '../global/GlobalReveal.vue'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import SanityContent from '../sanity/SanityContent.vue'

export default {
  props: {
    article: Object,
  },
  components: {
    GlobalReveal,
    ShopifyImage,
    SanityContent,
  },
  computed: {
    sanityExcerpt() {
      return this.article.sanity?.data?.excerpt
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';
.article-card {
  &__link {
    display: block;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
  }
  &__image {
    width: 100%;
    padding-bottom: 16px;
  }

  &__heading {
    @include cx-s;
    @include sans;
    text-align: center;
  }

  &__title {
    @include cx-uc;
  }

  &__attributions {
    font-style: italic;
  }
}
</style>
