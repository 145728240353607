<template>
  <video
    class="accentuate-video"
    :src="src"
    :muted="isMuted"
    :autoplay="isAutoplay"
    :loop="isLoop"
    :playsinline="isInline"
  ></video>
</template>

<script>
import PreloadMixin from '~/mixins/preload'

export default {
  props: {
    src: String,
    isMuted: { default: true },
    isAutoplay: { default: true },
    isLoop: { default: true },
    isInline: { default: true },
  },
  data() {
    return {
      loaded: false,
    }
  },
  async mounted() {
    this.loaded = true

    this.$nextTick(() => {
      // if (!!process.server) return;
    })
  },
}
</script>

<style lang="scss" scoped>
//@import '~styles/base.scss';

.accentuate-video {
}
</style>
