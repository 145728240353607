import './GlobalLegacyContent.scss'
import Reveal from './GlobalReveal.vue'
import Share from './GlobalShare.vue'
import { h } from 'vue'

import { legacyArticleContent, legacyPageContent } from '~/lib/content'

export default {
  props: {
    html: {
      required: true,
      type: String,
    },
    shareUrl: String,
    type: {
      required: true,
      type: String,
      default: 'page',
    },
  },
  render() {
    const formatter = this.type == 'page' ? legacyPageContent : legacyArticleContent
    return h({
      template: `<div class="component-rows">${formatter(this.html)}</div>`,
      components: {
        Reveal,
        Share,
      },
      data() {
        return {
          shareUrl: this.shareUrl,
        }
      },
    })
  },
}
