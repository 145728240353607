<template>
  <ResizeObserver @resize="onResize">
    <IntersectionObserver @intersect="onIntersect">
      <div class="sanity-image">
        <img
          class="sanity-image__image"
          :class="getClass"
          :src="getSrc"
          :loading="isLazy ? 'loading' : ''"
          :alt="alt || ''"
          v-if="getSrc"
        />
        <div class="sanity-image__background" :style="getStyle"></div>
      </div>
    </IntersectionObserver>
  </ResizeObserver>
</template>

<script>
import ResizeObserver from '~/components/observe/ObserveResize.vue'
import IntersectionObserver from '~/components/observe/ObserveIntersection.vue'

import { SanityImageUrl } from '~/lib/sanity/urls'
import { preloadImage } from '~/lib/preload'

export default {
  components: {
    ResizeObserver,
    IntersectionObserver,
  },
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
    alt: {
      type: [String, Boolean],
      required: true,
    },
    ratio: {
      type: [String, Boolean],
      default: false,
    },
    mode: {
      type: [String],
      default: 'default',
    },
    imageQuality: {
      type: [String],
      default: '85',
    },
    imageFormat: {
      type: [String],
      default: 'jpg',
    },
    imageCrop: {
      type: [String],
      default: 'center',
    },
    imageFit: {
      type: [String],
      default: 'crop',
    },
    setWidth: {
      type: [Boolean, Number],
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 2500,
    },
    minWidth: {
      type: Number,
      default: 400,
    },
    lazyDelay: {
      type: Number,
      default: 100,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    dpiScaling: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      inititialised: false,
      intersecting: false,
      width: false,
      height: false,
      loaded: false,
      isLazy: false,
      show: false,
    }
  },

  computed: {
    backgroundColor() {
      return this.asset?.dominantColor || null
    },
    getLoading() {
      return this.isLazy ? 'lazy' : ''
    },
    getScaling() {
      return Math.max(window?.devicePixelRatio || 0, this.dpiScaling)
    },
    getWidth() {
      let width = 400

      // Allow parent to set width (eg for slideshow)
      if (this.setWidth) {
        width = Math.round(Math.ceil((this.setWidth * this.getScaling) / 50) * 50)
        if (width < this.minWidth) width = this.minWidth
      }

      // Get width from element
      else if (this.width) {
        width = Math.round(Math.ceil((this.width * this.getScaling) / 50) * 50)
        if (width < this.minWidth) width = this.minWidth
      }

      console.log(width, this.width, this.getScaling)

      return width
    },
    getHeight() {
      let height = null

      if (this.ratio) {
        const numbers = this.ratio.split('x')
        const ratio = numbers[1] / numbers[0]

        height = Math.ceil(this.getWidth * ratio)
      } else {
        // height = 'auto'
        return false
      }

      return height
    },
    showPlaceholder() {
      if (this.show) return false
      if (this.simple) return false
      if (this.isLazy) return false
      return true
    },
    getSrc() {
      return this.showPlaceholder ? this.getPlaceholderUrl : this.getUrl
    },
    getPlaceholderUrl() {
      // if (this?.asset?.preview) return this?.asset?.preview;

      return this.getWidth && this.getHeight
        ? `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${this.getWidth} ${this.getHeight}'%3E%3C/svg%3E`
        : ''
    },
    getUrl() {
      const src = SanityImageUrl(this.asset)
      // console.log(this.asset)
      // const src = this.asset?.url
      if (!src) return
      if (this.ratio == 'default') return src

      const url = new URL(src)
      let params = `?fm=${this.imageFormat}&q=${this.imageQuality}&auto=format&crop=${this.imageCrop}&fit=${this.imageFit}&w=${this.getWidth}`
      if (this.getHeight) params += `&h=${this.getHeight}`

      return `${url.origin}${url.pathname}${params}`
    },
    getStyle() {
      if (this.backgroundColor) return `background-color: ${this.backgroundColor}`
      return false
    },
    getClass() {
      const classes = []
      if (this.loaded && (this.show || this.simple)) classes.push('sanity-image__image--loaded')

      if (this.getHeight) {
        classes.push(this.isPortrait ? 'sanity-image--portrait' : 'sanity-image--landscape')
      }

      return classes
    },
    isPortrait() {
      const isPortrait = this.width < this.height
      this.$emit('onPortrait', isPortrait)
      return isPortrait
    },
  },

  watch: {
    // Load as soon as element has a width
    width() {
      if (this.inititialised) return
      this.onLoad()
    },
  },

  mounted() {
    if (this.simple) {
      this.loaded = true
      this.show = true
    }
  },

  methods: {
    onResize({ width, height }) {
      this.width = width
      this.height = height
    },
    onIntersect(isIntersecting) {
      if (isIntersecting && !this.intersecting) {
        this.intersecting = true
        setTimeout(() => {
          this.show = true
        }, this.lazyDelay)
      }
    },
    async onLoad() {
      this.inititialised = true

      // In this mode, load the images and display once ready
      if (this.mode == 'default') {
        const url = this.getUrl
        await preloadImage(url)
        this.loaded = true
        this.show = true

        // In this mode, load the images but display when on screen
      } else if (this.mode == 'lazy') {
        const url = this.getUrl
        await preloadImage(url)
        this.loaded = true
        this.isLazy = true
      }
    },
  },
}
</script>

<style lang="scss">
.sanity-image {
  position: relative;
  width: 100%;
  // height: auto;

  &__image {
    opacity: 0;
    transition: all 1s ease;
    position: relative;
    z-index: 2;
    object-fit: cover;
    height: 100%;
    width: 100%;
    &--loaded {
      opacity: 1;
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
