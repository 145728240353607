<template>
  <div class="form-wrap">
    <h3 class="form-wrap__title">{{ title }}</h3>
    <div v-if="error" class="form-wrap__error" v-text="error"></div>
    <form v-if="submit" class="form-wrap__form" :action="action" @submit="onSubmit">
      <slot />
    </form>

    <slot name="note" />
  </div>
</template>

<script>
export default {
  props: {
    submit: Function,
    title: String,
    error: String,
    action: String,
  },
  methods: {
    onSubmit(e) {
      e.preventDefault()
      this.submit(e)
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.form-wrap {
  &__title {
    @include sans;
    @include cx-uc;
    min-height: 3em;
    padding-bottom: 1em;
  }

  &__error {
    margin-bottom: 1em;
  }
}
</style>
