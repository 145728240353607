<template>
  <div class="component-sidebar component-sidebar-shop">
    <div class="sidebar-inner">
      <h3>{{ title || 'Shop' }}</h3>
      <nav>
        <GlobalLinks :links="getLinks" />
      </nav>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import GlobalLinks from './GlobalLinks.vue'
import { paramCase } from 'param-case'

export default {
  components: { GlobalLinks },
  props: {
    title: {
      type: String,
      default: '',
    },
    links: String,
  },
  computed: {
    ...Vuex.mapState({
      shopify: (state) => state.shopify,
    }),
    getLinks() {
      return this.shopify.menus[`${paramCase(this.links || 'sidebar-shop')}`]
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.component-sidebar {
  position: relative;
  z-index: 100;

  .sidebar-inner {
    padding-left: var(--inset);
    @include cx-xs();
    position: sticky;
    top: var(--page-top);
  }

  h3 {
    @include cx-uc;
    padding-bottom: 5px;
  }

  nav {
    @include helper-link-color();

    counter-reset: link_counter;
    .global-link {
      display: block;
      counter-increment: link_counter;
      position: relative;
      cursor: pointer;
      padding-left: 20px;

      &:hover,
      &.active {
        &:before {
          content: counter(link_counter, decimal-leading-zero);
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

body[data-template^='customers'] {
  .component-sidebar {
    @include respond-to(xl) {
      display: none;
    }
  }
}
</style>
