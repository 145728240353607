<template>
  <svg
    class="cross"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.8 56.8"
    style="enable-background: new 0 0 56.8 56.8"
    xml:space="preserve"
  >
    <polygon
      class="st0"
      points="52.3,50 30.7,28.4 52.3,6.8 50,4.5 28.4,26.1 6.8,4.5 4.5,6.8 26.1,28.4 4.5,50 6.8,52.3 28.4,30.7 
	50,52.3 "
    />
  </svg>
</template>

<script></script>

<style lang="scss"></style>
