<template>
  <div class="home-rows">
    <component
      :is="getRowType(row)"
      v-for="(row, idx) in getSections"
      :key="'home_row_' + idx"
      class="home-row"
      :row="row"
    ></component>
  </div>
</template>

<script>
import BlockCta from '~/components/home/HomeCta.vue'
import BlockFullbleed from '~/components/home/HomeFullbleed.vue'
import BlockFeature1 from '~/components/home/HomeFeature1.vue'
import BlockFeature2 from '~/components/home/HomeFeature2.vue'

export default {
  components: {
    BlockCta,
    BlockFullbleed,
    BlockFeature1,
    BlockFeature2,
  },
  props: {
    sections: Array,
  },
  computed: {
    getSections() {
      return this.sections.filter((row) => this.getRowType(row))
    },
  },
  methods: {
    getRowType(row) {
      let ret = false
      if (row.type == 'cta') ret = 'BlockCta'
      if (row.type == 'fullbleed') ret = 'BlockFullbleed'
      if (row.type == 'feature1') ret = 'BlockFeature1'
      if (row.type == 'feature2') ret = 'BlockFeature2'
      return ret
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-rows {
  /* width:calc(100% - #{$gutter * 2}); */
  margin: 0 auto;
  img {
    width: 100%;
  }
}

.home-row {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  padding: 0;
  grid-template-columns: repeat(24, 1fr);
  grid-column-gap: #{$gutter * 2};
  margin: 110px auto;
}
</style>
