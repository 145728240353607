<template>
  <FormWrap
    class="form-register"
    title="Sign In"
    :error="error"
    :submit="register"
    action="/account"
    name="form"
  >
    <input type="hidden" name="customer[tags]" value="newsletter" />
    <!-- <label class="form-register__label" for="email">First Name</label> -->
    <InputText
      v-model="firstName"
      class="form-register__input form-register__input--text"
      name="firstName"
      placeholder="First Name"
      required="true"
    />

    <!-- <label class="form-register__label" for="email">Last Name</label> -->
    <InputText
      v-model="lastName"
      class="form-register__input form-register__input--text"
      name="lastName"
      placeholder="Last Name"
      required="true"
    />

    <!-- <label class="form-register__label" for="email">Email</label> -->
    <InputEmail
      v-model="email"
      class="form-register__input form-register__input--email"
      name="email"
      placeholder="Email Address"
      required="true"
    />

    <!-- <label class="form-register__label" for="password">Password</label> -->
    <InputPassword
      v-model="password"
      class="form-register__input form-register__input--password"
      name="password"
      placeholder="Password"
      required="true"
    />

    <!-- <label class="form-register__label" for="password">Sign up to our newsletter</label> -->
    <InputCheckbox
      v-model="acceptsMarketing"
      class="form-register__input form-register__input--checkbox"
      name="accepts_marketing"
      title=" Sign up to our newsletter"
    />
    <InputButton class="form-register__button" title="register" />
  </FormWrap>
</template>

<script>
import Vuex from 'vuex'
import InputEmail from '../input/InputEmail.vue'
import InputPassword from '../input/InputPassword.vue'
import InputButton from '../input/InputButton.vue'
import FormWrap from './FormWrap.vue'
import InputCheckbox from '../input/InputCheckbox.vue'
import InputText from '../input/InputText.vue'
import { RegisterSubscribe } from '~/lib/klaviyo.ts'

export default {
  computed: Vuex.mapState({
    user: (state) => state.account.user,
  }),
  components: {
    InputEmail,
    InputPassword,
    InputButton,
    FormWrap,
    InputCheckbox,
    InputText,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      acceptsMarketing: false,
      email: '',
      password: '',
      error: '',
    }
  },
  methods: {
    async register(event) {
      this.error = ''
      const formData = new FormData(event.target)
      const data = Object.fromEntries(formData)

      const submitData = {
        'customer[first_name]': data.firstName,
        'customer[last_name]': data.lastName,
        'customer[email]': data.email,
        'customer[password]': data.password,
        'customer[accepts_marketing]': !!data.accepts_marketing,
      }

      try {
        // Klaviyo subscription
        if (this.acceptsMarketing)
          await RegisterSubscribe({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
          })

        await this.$store.dispatch('account/register', submitData)
        alert('Registration successful! Please check your email to finish activating your account')
        window.location = '/account/login'
      } catch (e) {
        console.log(e)
        if (e.code == 'ERR_NETWORK') window.location = '/account/register'
        this.error = 'There was an error, please check your details and try again'
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.form-register {
  &__label {
    margin-bottom: 20px;
  }

  &__input {
    margin-bottom: 20px;
  }
}
</style>
