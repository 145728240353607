<template>
  <div class="template-textiles">
    <PageHeading :title="page.title" />

    <section class="textiles">
      <div v-for="(row, index) in getRows" :key="`template-textiles__row_${index}`" class="row">
        <GlobalReveal>
          <div class="row-inner">
            <div class="image" v-html="row[1]"></div>
            <div class="text" v-html="row[0]"></div>
          </div>
        </GlobalReveal>
      </div>
    </section>
  </div>
</template>

<script>
import GlobalReveal from '../components/global/GlobalReveal.vue'
import PageHeading from '../components/page/PageHeading.vue'

export default {
  components: {
    GlobalReveal,
    PageHeading,
  },
  props: {
    page: Object,
  },
  computed: {
    getRows() {
      const rows = this.page.content.split('<!--row-->')
      return rows.map((row) => {
        return row.split('<!--split-->')
      })
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.template-textiles {
  padding-top: var(--page-top);
  @include clearfix();

  section.textiles {
    padding-bottom: 200px;
    .row {
      @include clearfix();
      @include offset(3);
      @include offset(4, right);
      .row-inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-bottom: 160px;
        .image {
          @include spanX(17, 6);
          @include cx-xxs();
          img {
            margin-bottom: 5px;
          }
        }
        .text {
          @include spanX(17, 7);
          h2 {
            @include sans();
            @include cx-uc();
            @include cx-m();
            text-align: center;
            margin-bottom: 1em;
          }
          p {
            @include cx-indent();
            @include cx-xs();
            margin-bottom: 1em;
          }
          @include helper-link-underline(1);
          .anchor {
            position: absolute;
            top: -200px;
          }
        }
      }
      &:nth-child(2n) {
        .row-inner {
          flex-direction: row-reverse;
          .image {
            @include spanX(17, 5);
          }
          .text {
            @include offsetX(17, 1);
          }
        }
      }
      &:last-child {
        .row-inner {
          padding-bottom: 0;
        }
      }
    }
  }

  @include respond-to(xxl) {
    section.textiles {
      .row {
        @include offset(3, right);
        .row-inner {
          .text {
            @include spanX(18, 8);
          }
        }
      }
    }
  }

  @include respond-to(xl) {
    section.textiles {
      .row {
        .row-inner {
          .image {
            @include spanX(18, 8);
          }
        }
        &:nth-child(2n) {
          .row-inner {
            .image {
              @include spanX(18, 6);
            }
          }
        }
      }
    }
  }

  @include respond-to(m) {
    section.textiles {
      .row {
        .row-inner {
          flex-direction: column;
          padding-bottom: 120px;
          .image {
            @include spanX(18, 14);
            @include offsetX(18, 2);
            padding-bottom: 40px;
          }
          .text {
            @include spanX(18, 16);
            @include offsetX(18, 1);
          }
        }
        &:nth-child(2n) {
          .row-inner {
            flex-direction: column;
            .image {
              @include spanX(18, 14);
            }
          }
        }
      }
    }
  }

  @include respond-to(s) {
    section.textiles {
      .row {
        @include unspan();
        .row-inner {
          .image {
            @include unspan();
            padding: 0 0 40px;
            margin: 0;
          }
          .text {
            @include unspan();
            margin: 0;
            padding: 0;
          }
        }
        &:nth-child(2n) {
          .row-inner {
            flex-direction: column;
            .image {
              @include unspan();
              padding: 0 0 40px;
            }
            .text {
              @include unspan();
            }
          }
        }
      }
    }
  }
}
</style>
