interface HeaderState {
  submenu: string;
  hasLanding: boolean;
  onLanding: boolean;
  mouseOver: boolean;
  isLight: boolean;
}

interface RootState {
  overlay: {
    current: string;
  };
}

function getHeaderState(state: HeaderState, rootState: RootState) {
  const { submenu, isLight, hasLanding, onLanding, mouseOver } = state

  const hasBackground = submenu !== '' || mouseOver || !hasLanding || !onLanding

  let lightForeground = submenu === '' && isLight && hasLanding && onLanding && !mouseOver

  if (rootState.overlay.current === 'mobile') lightForeground = false

  return {
    lightForeground,
    hasBackground,
  }
}

export const state = (): HeaderState => ({
  submenu: '',
  hasLanding: false,
  onLanding: false,
  mouseOver: false,
  isLight: false,
})

export const actions = {}

export const mutations = {
  setHasLanding(state: HeaderState, value: boolean): void {
    state.hasLanding = value
  },
  setMouseOver(state: HeaderState, value: boolean): void {
    state.mouseOver = value
  },
  setOnLanding(state: HeaderState, value: boolean): void {
    state.onLanding = value
  },
  setIsLight(state: HeaderState, value: boolean): void {
    state.isLight = value
  },
  setSubmenu(state: HeaderState, value: string): void {
    state.submenu = value
  },
  toggleSubmenu(state: HeaderState, value: string): void {
    state.submenu = state.submenu === value ? '' : value
  },
}

export const getters = {
  headerDesktopClass(state: HeaderState, getters: any, rootState: RootState) {
    const { lightForeground, hasBackground } = getHeaderState(state, rootState)

    return {
      'the-header--light': lightForeground,
      'the-header--background': hasBackground,
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
