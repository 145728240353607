<template>
  <div class="product-details">
    <div class="product-details__inner">
      <h2 class="product-details__heading">
        <span v-if="title.type" class="product-details__title product-details__title--type">{{
          title.type
        }}</span>
        <span v-if="title.material" class="product-details__title product-details__title--material"
          >({{ title.material }})</span
        >
        <span v-if="title.colour" class="product-details__title product-details__title--colour">{{
          title.colour
        }}</span>
        <ShopifyProductPrice :product="product" />
      </h2>
      <ProductPayments class="product-details__payments" :product="product" />
      <ProductColours class="product-details__colours" :product="product" />
      <ProductOptions class="product-details__options" :product="product" />
      <ProductBuy :product="product" class="product-details__buy" />
      <ProductTabs class="product-details__tabs" :product="product" />
      <ProductRecommended class="product-details__recommended" :product="product" />
    </div>
  </div>
</template>

<script>
import ShopifyProductPrice from '../shopify/ShopifyProductPrice.vue'
import ProductPayments from './ProductPayments.vue'
import ProductColours from './ProductColours.vue'
import ProductOptions from './ProductOptions.vue'
import ProductBuy from './ProductBuy.vue'
import ProductTabs from './ProductTabs.vue'
import ProductRecommended from './ProductRecommended.vue'

export default {
  components: {
    ShopifyProductPrice,
    ProductPayments,
    ProductColours,
    ProductOptions,
    ProductBuy,
    ProductTabs,
    ProductRecommended,
  },
  props: {
    product: Object,
  },
  computed: {
    title() {
      const [type, material, colour] = this.product.title.split(',')

      return {
        type: type?.trim(),
        material: material?.trim(),
        colour: colour?.trim(),
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-details {
  &__inner {
    @include respond-to('m+') {
      position: sticky;
      top: var(--page-top);
    }

    /* @include respond-to('m') {
      @include mobileWrap;
    } */
  }

  &__heading {
    @include cx-l;
    @include sans;
  }

  &__title {
    &:after {
      content: ', ';
    }
    &--type {
      @include cx-uc;
    }
  }

  &__payments {
    margin-bottom: 40px;
  }

  &__buy {
    margin-bottom: 40px;
  }

  &__tabs {
    margin-bottom: 40px;
  }
}
</style>
