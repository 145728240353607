<template>
  <button class="a" @click.prevent="$store.commit('overlay/open', 'currency')">
    {{ currency.current }}
  </button>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'GlobalLink',
  props: {
    link: Object,
  },
  computed: {
    ...Vuex.mapState({
      currency: (state) => state.currency,
    }),
  },
}
</script>
