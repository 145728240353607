<template>
  <div class="review-stars">
    <component
      class="review-stars__star"
      v-for="(star, k) in getStars"
      :key="`star_${k}`"
      :is="star.component"
    ></component>
  </div>
</template>

<script>
import SvgFullStar from '~/components/svg/SvgFullStar.vue'
import SvgEmptyStar from '~/components/svg/SvgEmptyStar.vue'

export default {
  props: {
    score: Number,
  },
  computed: {
    getStars() {
      return Array(5)
        .fill(1)
        .map((v, k) => {
          return {
            component: Math.ceil(k) < this.score ? 'SvgFullStar' : 'SvgEmptyStar',
          }
        })
    },
  },
  components: {
    SvgFullStar,
    SvgEmptyStar,
  },
}
</script>
