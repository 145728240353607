<template>
  <div ref="el">
    <img :src="url" />
  </div>
</template>

<script lang="ts" setup="">
import { useElementSize } from '@vueuse/core'
import { ref, computed } from 'vue'
import { ratioAsNumber } from '../../utils/images'
import { getImage } from '../../lib/shopify'

const props = withDefaults(
  defineProps<{
    src: string
    ratio?: string
  }>(),
  {
    ratio: '1x1',
  }
)

const el = ref(null)
const { width: elWidth } = useElementSize(el)
const ratioNumber = ratioAsNumber(props.ratio)

const size = computed(() => {
  if (elWidth.value == 0) {
    const blurWidth = 600
    return {
      width: blurWidth,
      height: ratioNumber * blurWidth,
    }
  } else {
    return {
      width: elWidth.value,
      height: ratioNumber * elWidth.value,
    }
  }
})

const url = computed(() => {
  const { url: src } = getImage(props.src, {
    modifiers: {
      width: size.value.width,
      ratio: props.ratio,
    },
  })
  return src
})
</script>

<style lang="scss"></style>
