<template>
  <div class="collection-filters-menu">
    <button class="collection-filters-menu__heading" @click="toggleMenu">
      <span>{{ name }}</span
      ><span v-if="mode != 'mobile'">{{ isOpen ? '–' : '+' }}</span>
    </button>
    <TransitionHeight>
      <nav v-if="isOpen" class="collection-filters-menu__list">
        <button
          v-for="(filter, index) in filters"
          :key="`menu-${index}`"
          class="collection-filters-menu__button"
          :class="isSelected(filter) ? 'collection-filters-menu__button--active' : ''"
          @click="$store.commit('collection/toggleFilter', filter)"
        >
          {{ filter.value }}
        </button>
      </nav>
    </TransitionHeight>
  </div>
</template>

<script>
import TransitionHeight from '../transition/TransitionHeight.vue'

export default {
  components: { TransitionHeight },
  props: {
    name: String,
    mode: String,
  },
  data() {
    return {
      isOpen: this.mode == 'mobile',
    }
  },
  computed: {
    collection() {
      return this.$store.state.collection
    },
    hasSelected() {
      return this.$store.getters['collection/menuHasSelected'](this.name)
    },
    filters() {
      return this.$store.getters['collection/getFilters'](this.name)
    },
    isSelected() {
      return (option) => this.$store.getters['collection/isSelected'](option)
    },
  },
  watch: {
    hasSelected: {
      handler(hasSelected) {
        if (hasSelected) this.isOpen = true
      },
      immediate: true,
    },
  },
  methods: {
    toggle(option) {
      this.$store.commit('collection/toggleFilter', {
        menu: this.name,
        value: option,
      })
    },
    toggleMenu() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.collection-filters-menu {
  &__heading {
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include respond-to('s') {
      pointer-events: none;
    }
  }

  &__button {
    color: var(--color-accent);
    display: block;
    cursor: pointer;
    display: grid;
    grid-template-columns: 30px 1fr;
    text-align: left;

    &:before {
      content: '';
    }

    &:hover,
    &--active {
      color: var(--color-off-black);
      &:before {
        content: '•';
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}
</style>
