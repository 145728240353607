<template>
  <div class="block-inner">
    <a :href="product.url" v-if="product.url" class="link"></a>
    <div class="image">
      <ShopifyImage :src="product.image" ratio="6x8" />
    </div>
    <div class="caption">
      <h2 class="title" v-text="product.title"></h2>
      <h2 class="description" v-text="product.description"></h2>
    </div>
  </div>
</template>

<script>
import ShopifyImage from '~/components/shopify/ShopifyImage.vue'

export default {
  props: {
    product: Object,
  },
  components: {
    ShopifyImage,
  },
}
</script>
