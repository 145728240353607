<template>
  <div class="product-slideshow">
    <GlobalSlideshow slide-component="GlobalSlideImage" :slides="getSlides" />
  </div>
</template>

<script>
import GlobalSlideshow from '../global/GlobalSlideshow.vue'

export default {
  components: {
    GlobalSlideshow,
  },
  props: {
    product: Object,
  },
  computed: {
    getSlides() {
      return this.product.images
    },
  },
}
</script>

<style lang="scss">
.product-slideshow {
}
</style>
