import SanityContentImage from '~/components/sanity/content/SanityContentImage.vue'

export default {
  functional: true,
  render(h, ctx) {
    const { props } = ctx
    const { asset, alt = '', caption = '', mode = '' } = props
    // const { metadata } = asset.metadata;

    return h(
      SanityContentImage,
      {
        props: {
          asset,
          alt,
          caption,
          mode,
        },
      },
      props.children
    )
  },
}
