export default {
  computed: {
    productOnSale() {
      return !!this.productSalePrice
    },
    productPrice() {
      return parseFloat(this?.product?.price / 100 || 0)
    },
    productSalePrice() {
      const { productPrice } = this
      const productComparePrice = parseFloat(this.product?.compare_at_price || 0) / 100
      if (productComparePrice > productPrice) return productComparePrice
      return false
    },
  },
}
