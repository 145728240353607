<template>
  <a v-if="shopify.customerIsLoggedIn" href="/account">Account</a>
  <button v-else class="a" @click.prevent="$store.commit('overlay/open', 'account')">Login</button>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'GlobalLink',
  props: {
    link: Object,
  },
  computed: {
    ...Vuex.mapState({
      shopify: (state) => state.shopify,
    }),
  },
}
</script>

<style lang="scss"></style>
