<template>
  <div class="page-template-bandanas">
    <GlobalSidebar title="Scarves" links="menuSidebarBandanas" />

    <GlobalBackgroundSwitch
      v-for="(collection, index) in collections"
      :id="collection.handle"
      :key="`page-template-bandanas__products_${index}`"
      :enabled="index % 2 == 0"
      class="products"
    >
      <div class="blocks blocks-product">
        <div class="blocks-inner">
          <section class="collection">
            <GlobalReveal class="collection-inner">
              <h2>{{ collection.title }}</h2>
              <div v-html="collection.description"></div>
            </GlobalReveal>
          </section>
          <ProductCards :products="getCollectionProducts(collection.handle)" />
        </div>
      </div>
    </GlobalBackgroundSwitch>

    <section class="cta">
      <div class="cta-inner">
        <GlobalReveal>
          <a href="/collections/scarves" class="button">Shop All scarves</a>
        </GlobalReveal>
      </div>
    </section>

    <section class="instructions">
      <div class="instructions-inner">
        <GlobalReveal>
          <div class="image">
            <ShopifyImage :src="section.instructions_image" />
          </div>
          <div class="description" v-html="section.instructions_text"></div>
        </GlobalReveal>
      </div>
    </section>
  </div>
</template>

<script>
import _ from 'lodash'
import GlobalReveal from '../components/global/GlobalReveal.vue'
import ProductCards from '../components/product/ProductCards.vue'
import ShopifyImage from '../components/shopify/ShopifyImage.vue'
import GlobalSidebar from '../components/global/GlobalSidebar.vue'
import GlobalBackgroundSwitch from '../components/global/GlobalBackgroundSwitch.vue'

export default {
  components: {
    GlobalReveal,
    ProductCards,
    ShopifyImage,
    GlobalSidebar,
    GlobalBackgroundSwitch,
  },
  props: {
    collections: Array,
    figures: Array,
    section: Object,
  },
  computed: {
    getCollectionProducts() {
      return (handle) => {
        const figures = _.chain(this.figures)
          .filter((v) => {
            return v.collection == handle && v.position > 0
          })
          .uniqBy('position')
          .orderBy('position')
          .value()

        const collection = this.collections.find((c) => c.handle == handle)
        const products = collection.products

        //Splice together
        let cards = []
        let index = 1

        while (products.length) {
          const f = figures[0]
          const p = products[0]

          if (!f && !p) break

          if (f && f.position == index) {
            const _f = figures.shift()
            _f.type = 'figure'

            cards = [...cards, _f]
          } else if (p) {
            const _p = products.shift()
            _p.type = 'product'
            cards = [...cards, _p]
          }

          index += 1
        }

        return cards
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.page-template-bandanas {
  padding-top: var(--page-top);
  @include clearfix();
  @include wrapper();

  .product-cards {
    display: contents;
  }

  .cta {
    @include wrapper();
    @include clearfix();
    text-align: center;
    padding-bottom: 100px;
    a {
      @include form-button();
    }
  }

  .instructions {
    padding-top: 100px;
    padding-bottom: 100px;
    @include wrapper();
    @include clearfix();
    .instructions-inner {
      @include offset(7);
      @include offset(7, right);
    }
    .image {
      img {
        width: 100%;
      }
      padding-bottom: 40px;
    }
    .description {
      @include offsetX(10, 2);
      @include offsetX(10, 2, right);
      h2,
      h3,
      h4 {
        @include sans();
        @include cx-uc();
        text-align: center;
        padding-bottom: 20px;
      }
    }
  }

  .products {
    @include offset(4);
    @include offset(3, right);
    margin-bottom: 100px;
    counter-reset: figCounter;
    .blocks-product {
      .blocks-inner {
        display: grid;
        grid-gap: $gutter * 2;
        grid-template-columns: repeat(17, 1fr);
        padding: 0 $gutter;
        .collection {
          grid-column: 12 / 18;
          grid-row: 1;
          padding-bottom: 20px;
          h2 {
            @include cx-s();
            @include cx-uc();
            @include sans();
            padding-bottom: 20px;
            text-align: center;
          }
        }
        .block {
          grid-column: span 5;
          padding-bottom: 50px;
          &:first-of-type {
            grid-column: 1 / span 9;
            grid-row: 1 / span 2;
            align-self: end;
          }
          &:nth-of-type(2) {
            grid-row: 2;
            align-self: end;
          }
          &:nth-of-type(3n + 4) {
            grid-column: 7 / span 5;
          }
          &:nth-of-type(2),
          &:nth-of-type(3n + 5) {
            grid-column: 13 / span 5;
          }

          .image {
            img {
              width: 100%;
            }
          }

          .caption {
            min-height: 85px;
          }

          &.block-figure {
            counter-increment: figCounter;
            .image {
              transition: filter 0.2s ease-out;
              filter: grayscale(100%);
            }
            .caption {
              position: relative;
              padding-left: 50px;
              .description {
                text-transform: none;
                transition: opacity 0.2s ease-out;
                opacity: 0;
                padding-top: 10px;
              }
              &:before {
                text-transform: none;
                position: absolute;
                content: 'Fig. ' counters(figCounter, '.', decimal-leading-zero);
                left: 0;
                top: 10px;
                font-style: italic;
              }
            }
            &:hover {
              .image {
                filter: grayscale(0);
              }
              .caption {
                .description {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
  @include respond-to(xl) {
    .products {
      @include offset(2, right);
      .blocks-product {
        .blocks-inner {
          .block {
            &:first-of-type {
              grid-column: 1 / span 10;
            }
          }
        }
      }
    }
  }
  @include respond-to(l) {
    .products {
      @include offset(4);
      @include offset(1, right);
      .blocks-product {
        .blocks-inner {
          .block {
            &.block-figure {
              .caption {
                .description {
                  opacity: 1;
                }
              }
              .image {
                filter: grayscale(0);
              }
            }
            &:first-of-type {
              grid-row-end: 1;
            }
            // Reset
            &:nth-of-type(2),
            &:nth-of-type(3n + 4),
            &:nth-of-type(3n + 5) {
              grid-column: auto;
              grid-row: auto;
            }
            // End Reset
            &:nth-of-type(3n + 2) {
              grid-column: 1 / span 5;
            }
            &:nth-of-type(3n + 3) {
              grid-column: 7 / span 5;
            }
            &:nth-of-type(3n + 4) {
              grid-column: 13 / span 5;
            }
          }
        }
      }
    }
  }
  @include respond-to(l) {
    padding-top: 0;
    .products {
      .blocks-product {
        .blocks-inner {
          .block {
            padding-bottom: 20px;
          }
        }
      }
    }
  }
  @include respond-to(m) {
    .products {
      @include offset(1);
    }
    .instructions {
      padding-top: 40px;
      .instructions-inner {
        @include offset(5);
        @include offset(5, right);
      }
    }
  }
  @include respond-to(s) {
    .products {
      .blocks-product {
        .blocks-inner {
          .collection {
            grid-column: 1 / 18;
            padding-bottom: 40px;
          }
          .block {
            padding-bottom: 20px;
            // Reset
            &:nth-of-type(3n + 2),
            &:nth-of-type(3n + 3),
            &:nth-of-type(3n + 4) {
              grid-column: auto;
            }
            // End Reset
            &:first-of-type {
              grid-column: 1 / 18;
              grid-row: auto;
            }
            &:nth-of-type(2n + 2) {
              grid-column: 1 / span 8;
            }
            &:nth-of-type(2n + 3) {
              grid-column: 10 / span 8;
            }
          }
        }
      }
    }
  }
}
</style>
