<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.7 35.2"
    style="enable-background: new 0 0 56.7 35.2"
    xml:space="preserve"
  >
    <g>
      <line class="st0" x1="1.2" y1="17.4" x2="55.5" y2="17.4" />
      <polyline class="st0" points="39.2,34 55.8,17.4 39.2,0.9 	" />
    </g>
  </svg>
</template>

<script></script>

<style lang="scss"></style>
