<template>
  <div class="article-landing-image">
    <div class="article-landing-image__inner">
      <div class="article-landing-image__heading">
        <h1 class="article-landing-image__title">
          {{ article.title }}
        </h1>
        <p
          v-if="article.metafields.attributions"
          class="article-landing-image__attributions"
          v-html="article.metafields.attributions"
        ></p>
      </div>
      <div class="article-landing-image__image">
        <ShopifyImage :src="article.image" />
      </div>
    </div>
    <div class="article-landing-image__content" v-html="article.excerpt"></div>
  </div>
</template>

<script>
import ShopifyImage from '../shopify/ShopifyImage.vue'
export default {
  components: { ShopifyImage },
  props: {
    article: Object,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';
.article-landing-image {
  min-height: 100vh;
  position: relative;

  &__inner {
    display: grid;
    height: 100vh;
  }

  &__image,
  &__heading {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    z-index: 10;
    @include serif;
    text-align: center;
    color: var(--color-light);
    margin-top: auto;
    margin-bottom: 40px;
  }

  &__title {
    @include cx-xxxl;
  }

  &__attributions {
    padding-top: 10px;
    a {
      @include helper-underline();
    }
  }

  &__image {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    @include offsets(6);
    @include cx-l;
    padding-top: 40px;
  }
}
</style>
