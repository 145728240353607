<template>
  <h2 class="title">No products found! <button class="button" @click="onClick">Go back?</button></h2>
</template>

<script>
export default {
  methods: {
    onClick() {
      window.history.back()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~styles/base.scss';

.title {
  @include cx;
  @include cx-l;
  /* @include cx('40'); */
}
.button {
  @include cx-l;
  @include helper-underline;
}
</style>
