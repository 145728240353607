<template>
  <GlobalShare class="sanity-content-share" :url="url" :title="title" />
</template>

<script>
import GlobalShare from '../../global/GlobalShare.vue'
export default {
  components: { GlobalShare },
  computed: {
    url() {
      return '/'
    },
    title() {
      return ''
    },
  },
}
</script>
