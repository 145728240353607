<template>
  <div
    class="header-submenu"
    :class="`${currentSubmenu == submenu.name ? 'header-submenu--active' : ''}`"
  >
    <div class="header-submenu__inner">
      <h2 class="header-submenu__title">{{ submenu.title }}</h2>
      <HeaderSubmenuMenu :title="submenu.menu1_title" :menu="submenu.menu1" />
      <HeaderSubmenuMenu :title="submenu.menu2_title" :menu="submenu.menu2" />
      <HeaderSubmenuMenu :title="submenu.menu3_title" :menu="submenu.menu3" />
      <HeaderSubmenuCard
        :caption="submenu.caption"
        :image="submenu.image"
        :link-title="submenu.link_text"
        :link-url="submenu.link_url"
      />
    </div>
  </div>
</template>

<script>
import HeaderSubmenuMenu from './HeaderSubmenuMenu.vue'
import HeaderSubmenuCard from './HeaderSubmenuCard.vue'
export default {
  components: { HeaderSubmenuMenu, HeaderSubmenuCard },
  props: {
    submenu: Object,
  },
  computed: {
    currentSubmenu() {
      return this.$store.state.header.submenu
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.header-submenu {
  background: var(--background);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease;
  /* transition-delay: 0.4s; */

  &--active {
    opacity: 1;
    transition-delay: 0s;
    pointer-events: auto;
    z-index: 5;
  }
  &__inner {
    @include wrapper;
    padding-top: 20px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: columns(4) columns(4) columns(4) 1fr columns(5);
  }
}
</style>
