<template>
  <div v-if="options.length > 1" class="product-option-select">
    <h3 class="product-option-select__title">
      {{ title }}
    </h3>
    <nav class="product-option-select__controls">
      <button
        v-for="(option, index) in options"
        :key="`product-option-select_${title}_${index}`"
        class="product-option-select__control"
        :class="`${option.value == active ? 'product-option-select__control--active' : ''} ${
          option.available
            ? 'product-option-select__control--available'
            : 'product-option-select__control--unavailable'
        }`"
        @click="() => option.action($store)"
      >
        {{ option.title }}
      </button>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    active: String,
    options: Array,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-option-select {
  margin-bottom: 20px;
  &__title {
    @include cx-uc;
    padding-bottom: 4px;
  }

  &__controls {
    display: flex;
    gap: 10px;
  }

  &__control {
    &--active {
      @include helper-underline;
      pointer-events: none;
    }

    &--unavailable {
      opacity: 0.5;
    }
  }
}
</style>
