<template>
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.94974 11.1247L0.999996 6.17492L5.94974 1.22518M1.34918 6.17543L15.349 6.17525"
      stroke="#585045"
      stroke-width="0.75"
    />
  </svg>
</template>

<script></script>

<style lang="scss"></style>
