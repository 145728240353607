/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import stripJsonComments from 'strip-json-comments'
import qs from 'qs'
import { getRecaptchaToken } from './recaptcha'

interface AxiosGetOptions extends AxiosRequestConfig {
  raw?: boolean
}

interface AxiosPostOptions extends AxiosRequestConfig {
  data: Record<string, unknown>
}

interface Params {
  [key: string]: string | boolean | undefined
  utf8: '✓'
  form_type: string
  _method?: string | boolean
}

const axiosGetSettings: AxiosRequestConfig = {
  method: 'GET',
  dataType: 'json',
} as any

const axiosPostSettings: AxiosRequestConfig = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
}

const axiosGet = async (url: string, options: AxiosGetOptions = {}): Promise<any> => {
  const response: AxiosResponse = await axios.request({
    ...axiosGetSettings,
    ...options,
    url,
  })
  if (options.raw) return response
  if (typeof response.data === 'string') {
    try {
      return JSON.parse(stripJsonComments(response.data))
    } catch (error) {
      console.error('Error parsing JSON response:', error)
      return {}
    }
  }
  return response.data
}

const axiosPost = async <T>(
  url: string,
  { data }: AxiosPostOptions,
  options: AxiosRequestConfig = {}
): Promise<T> => {
  const response: AxiosResponse = await axios.request({
    ...axiosPostSettings,
    ...options,
    data,
    url,
  })
  return response.data
}

export function formatParams(
  data: Record<string, unknown>,
  formType: string,
  _method: string | boolean = false
): Params {
  const params: Params = {
    utf8: '✓',
    form_type: formType,
  }
  if (_method) {
    params._method = _method
  }
  // Add attributes
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (data[key] !== null && data[key] !== undefined && data[key] !== '') {
        params[key] = data[key] as string
      }
    }
  }
  console.log('Formatted params:', params)
  return params
}

const submitRequest = async function (
  path: string,
  params: Params,
  _method: string | boolean = false
): Promise<AxiosResponse> {
  const formData: Record<string, string> = {}

  // Add form decoration
  if (_method) {
    formData['_method'] = _method.toString()
  }

  // Add attributes
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
        formData[key] = String(params[key])
      }
    }
  }

  const data = qs.stringify(formData, { encode: false })

  return axios.request({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
    url: path,
    data,
  })
}

const axiosForm = async function (
  url: string,
  input: { form_type: string; utf8: '✓'; [key: string]: any },
  formType: string
): Promise<any> {
  const params = { ...input }
  console.log('Form params:', params)
  try {
    const form = document.querySelector('form') as HTMLFormElement
    if (!form) {
      console.error('Form not found')
      throw new Error('Form not found')
    }
    const recaptchaToken = await getRecaptchaToken(formType)
    params['recaptcha-v3-token'] = recaptchaToken

    const response = await submitRequest(url, params)

    if (response.status >= 300 && response.status < 400) {
      const redirectUrl = response.headers.location
      if (redirectUrl) {
        window.location.href = redirectUrl

        return false
      }
    }

    if (response.request.responseURL.includes('/challenge')) {
      console.log('go to challenge url')
      setTimeout(() => {
        window.location.href = '/challenge'
      }, 3000)
      return false
    }
    return response.data
  } catch (error) {
    console.error('Error in axiosForm:', error)
    throw error
  }
}

export { axiosGet, axiosPost, axiosForm }
