<template>
  <footer class="footer">
    <div class="footer__inner">
      <div class="footer__main">
        <div class="footer__instagram">
          <FooterInstagram :fallback-image="shopify.settings.instagram_image" />
          <div class="footer__instagram-caption">
            <a :href="shopify.settings.instagram_url">
              Instagram<br />
              @ozma_of_california
            </a>
          </div>
        </div>

        <div class="footer__menus">
          <nav class="footer__menu">
            <h3 class="footer__menu-heading">Follow Us</h3>
            <GlobalLinks class="footer__menu-links" :links="shopify.menus['footer-social']" />
          </nav>

          <nav class="footer__menu">
            <h3 class="footer__menu-heading">Our Fine Print</h3>
            <GlobalLinks class="footer__menu-links" :links="shopify.menus['footer-terms']" />
          </nav>

          <nav class="footer__menu">
            <h3 class="footer__menu-heading">Need Help?</h3>
            <GlobalLinks class="footer__menu-links" :links="shopify.menus['footer-help']" />
          </nav>
        </div>

        <FooterNewsletter class="footer__newsletter" />
      </div>
      <div class="footer__footnote">
        <a href="/" class="footer__logo">
          <SvgLogotype />
        </a>
        <div class="footer__copyright">&copy; OZMA</div>
      </div>
    </div>
  </footer>
</template>

<script>
import Vuex from 'vuex'
import Reveal from './global/GlobalReveal.vue'
import SvgLogotype from './svg/SvgLogotype.vue'
import GlobalLinks from './global/GlobalLinks.vue'
import FooterInstagram from './footer/FooterInstagram.vue'
import GlobalNewsletter from './global/GlobalNewsletter.vue'
import FooterNewsletter from './footer/FooterNewsletter.vue'

export default {
  computed: {
    ...Vuex.mapState({
      shopify: (state) => state.shopify,
    }),
  },
  components: {
    SvgLogotype,
    Reveal,
    GlobalLinks,
    FooterInstagram,
    GlobalNewsletter,
    FooterNewsletter,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.footer {
  padding-top: 80px;

  &__inner {
    background: var(--color-accent2);
  }

  &__main {
    padding-bottom: 80px;

    @include respond-to('l+') {
      grid-template-columns: 4fr 4fr 5fr 9fr 2fr;
    }

    @include respond-to('l') {
      grid-template-columns: 2fr 4fr 4fr 12fr 2fr;
    }

    @include respond-to('m+') {
      @include wrapper;
      display: grid;
      padding-top: 90px;
    }

    @include respond-to('m') {
      @include mobileWrap;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 80px;
    }
  }

  &__instagram {
    grid-column: 2 / 3;
    position: relative;
    margin-bottom: auto;
    @include respond-to('m') {
      margin-bottom: 80px;
    }
  }

  &__instagram-caption {
    @include respond-to('m+') {
      position: absolute;
      left: 0;
      bottom: 0;
      transform-origin: left bottom;
      padding-bottom: 10px;
      transform: rotate(-90deg) translate3d(0, 0, 0);
    }

    @include respond-to('m') {
      padding-top: 10px;
    }
  }

  &__menus {
    grid-column: 4 / 5;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 70px;
    /* gap: 20px; */

    > * {
      padding-right: 10px;
    }
  }

  &__newsletter {
    grid-column: 4 / 5;
  }

  &__menu-heading {
    font-style: italic;
    margin-bottom: 40px;
  }

  &__menu-links {
    font-style: italic;
    .global-link {
      color: var(--color-accent);
    }
  }

  &__footnote {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: flex-end;

    @include respond-to('m+') {
      @include wrapper;
    }

    @include respond-to('m') {
      @include mobileWrap;
      padding-bottom: 70px;
    }
  }

  &__logo {
    width: 120px;

    svg {
      width: 100%;
      height: 100%;
    }

    @include respond-to('m+') {
      margin-left: columns(4);
    }
  }
}
</style>
