<template>
  <div class="page-template" :class="getClass">
    <PageHeading :title="page.heading" />

    <section class="content">
      <div class="content-inner">
        <GlobalLegacyContent :html="page.content" type="page" />
      </div>
    </section>
  </div>
</template>

<script>
import PageHeading from '../components/page/PageHeading.vue'
import GlobalLegacyContent from '../components/global/GlobalLegacyContent'

export default {
  components: {
    GlobalLegacyContent,
    PageHeading,
  },
  props: {
    page: Object,
    theme: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    getClass() {
      return `page-template--theme-${this.theme}`
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.page-template {
  padding-top: var(--page-top);

  section.content {
    @include wrapper();
    .content-inner {
      @include offset(5);
      @include offset(5, right);
      @include clearfix;
      img {
        width: 100%;
      }
    }

    .content-center {
      text-align: center;
      padding-bottom: 60px;
    }
    .content-columns {
      @include text-columns(2);
      padding-bottom: 80px;
    }

    .content-text-image,
    .content-image-text {
      @include offsetsX(14, -1);
      @include clearfix();
      padding-bottom: 80px;
      .image {
        @include spanX(16, 8);
        @include offsetsX(16, 0);
        img {
          width: 100%;
        }
        img + p {
          padding-top: 10px;
        }
        p + img {
          padding-top: 80px;
          @include respond-to(xs) {
            padding-top: 40px;
          }
        }

        @include respond-to(xs) {
          @include unspan();
          padding-bottom: 40px;
        }
      }

      .text {
        @include cx-rows();
        @include spanX(16, 6);
        @include offsetsX(16, 1);

        @include respond-to(l) {
          @include spanX(16, 6);
          @include offsetsX(16, 1);
        }
        @include respond-to(xs) {
          @include unspan();
          // @include insets();
        }
      }
    }
    .content-text-image {
      .text {
        @include respond-to(l) {
          @include offsetsX(16, 2);
        }
        @include respond-to(xs) {
          @include unspan();
          // @include insets();
        }
      }
      .image {
        float: right;
        @include respond-to(xs) {
          padding-top: 40px;
        }
      }
    }

    h1,
    h2,
    h3,
    h4 {
      @include sans();
      @include cx-uc();
      padding-bottom: 1em;
    }
    h1 {
      @include cx-l();
    }
    h2 {
      @include cx-m();
    }
    h3 {
      @include cx-s();
    }
    p {
      margin-bottom: 1em;
      &.large {
        @include cx-l();
      }
    }
    ul {
      margin-bottom: 1em;
      list-style: disc inside;
      li {
      }
    }
    table {
      width: 100%;
      margin-bottom: 2em;
      tr {
        th {
          @include sans();
          @include cx-uc();
          text-align: left;
          // padding-bottom:2px;
          // margin-bottom:4px;
          // border-bottom:1px solid transparentize($accent, 0.5);
        }
      }
    }
    @include respond-to(l) {
      .content-inner {
        @include offset(3);
        @include offset(3, right);
      }
    }
    @include respond-to(s) {
      .content-columns {
        @include text-columns(1);
      }
    }
    @include respond-to(xs) {
      .content-inner {
        margin: 0 $mobile_inset;
      }
    }
  }

  @include respond-to(m) {
    padding-top: 120px;
  }

  @include on-body('[data-template~=page]', '') {
    background: $background3;
  }

  &--theme-sizing.page-template {
    section.content {
      width: 460px;
      max-width: 100%;
      margin: 0 auto;
      padding-bottom: 200px;
      h2 {
        text-align: center;
        padding-bottom: 40px;
      }
      table {
        margin: 0 auto 60px;
        th {
          @include cx-uc();
          @include sans();
          text-align: left;
        }
        th,
        td {
          padding-bottom: 5px;
        }

        @media screen and (max-width: 720px) {
          max-width: calc(100% - 40px) !important;
        }
      }

      .content-inner {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
</style>
