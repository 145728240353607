<template>
  <transition name="fade">
    <div class="overlay-wrap" :class="`${isActive ? 'overlay-wrap--active' : ''}`">
      <div class="overlay-wrap__inner">
        <div class="overlay-wrap__heading">
          <h2 class="overlay-wrap__title">{{ title }}</h2>
          <button class="overlay-wrap__close" @click="$store.commit('overlay/open', '')">
            <SvgClose />
          </button>
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import SvgClose from '../svg/SvgClose.vue'

export default {
  components: { SvgClose },
  props: {
    isActive: Boolean,
    title: String,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

$overlay_width: 520px;

.overlay-wrap {
  @include helper-overlay-close();

  &__inner {
    @include helper-overlay-inner($width: $overlay_width);
  }

  &__heading {
    @include cx-s();
    display: flex;
    justify-content: space-between;
    padding: 20px var(--inset) 15px;
  }

  &--active & {
    &__inner {
      @include helper-overlay-inner-active();
    }
  }

  @include respond-to(s) {
    &__inner {
      @include helper-overlay-mobile();
    }

    &__heading {
      display: none;
    }

    &--active & {
      &__inner {
        right: 0;
      }
    }
  }
}
</style>
