<template>
  <div class="review-score">
    <span
      class="review-score__star"
      v-for="(star, k) in getStars"
      :key="`star_${k}`"
      @mouseenter="select(k + 1)"
    >
      <component :is="star.component"></component>
    </span>
  </div>
</template>

<script>
import SvgFullStar from '~/components/svg/SvgFullStar.vue'
import SvgEmptyStar from '~/components/svg/SvgEmptyStar.vue'

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getStars() {
      return Array(5)
        .fill(1)
        .map((v, k) => {
          return {
            component: Math.ceil(k) < this.value ? 'SvgFullStar' : 'SvgEmptyStar',
          }
        })
    },
  },
  methods: {
    select(k) {
      this.$emit('input', k)
    },
  },
  components: {
    SvgFullStar,
    SvgEmptyStar,
  },
}
</script>

<style lang="scss">
.review-score {
  &__star {
    cursor: pointer;
  }
}
</style>
