/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios from 'axios'

const GATEWAY_URL = 'https://ozma-services.vercel.app/api'

const getProductReviews = async function ({ productId }) {
  const response = await Axios.post(`${GATEWAY_URL}/reviews`, {
    productId,
  })

  return response.data.response
}

const createProductReview = async function (submission: any) {
  return (await Axios.post(`${GATEWAY_URL}/review`, submission)).data.response
}

export { getProductReviews, createProductReview }
