<template>
  <div class="home-cta">
    <span><a :href="row.link" v-html="row.title"></a></span>
  </div>
</template>

<script>
export default {
  props: {
    row: Object,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-row.home-cta {
  width: 100%;
  text-align: center;
  grid-template-columns: 1fr;

  span {
    margin: auto;
  }

  @include helper-link-underline;

  a {
    @include cx-l;
    @include sans;
    @include cx-uc;
  }
}
</style>
