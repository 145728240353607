<template>
  <form class="password-form" @submit.prevent="submit($event)">
    <input
      v-model="email"
      type="email"
      placeholder="Email Address"
      size="10"
      maxlength="50"
      required="true"
      class="password-form__email"
    />
    <button class="password-form__submit">Submit</button>
    <div class="password-form__message" v-html="message"></div>
  </form>
</template>

<script>
import { subscribe } from 'klaviyo-subscribe'
import axios from 'axios'
import qs from 'qs'

export default {
  data() {
    return {
      cartApi: false,
      email: '',
      message: '',
      list_id: 'NSF6SY',
    }
  },
  methods: {
    async submit($evt) {
      try {
        // Sign up to klaviyo
        const klaviyoResponse = await subscribe(this.list_id, this.email, {})

        // Set customer to 'accepts marketing
        const shopifyResponse = await axios({
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;',
          },
          method: 'post',
          url: '/cart/update.js',
          data: qs.stringify({
            attributes: {
              collection_subscribed: this.email,
            },
          }),
        })

        // this.message = "You're in!";
        this.email = ''
        window.location.reload()
      } catch (e) {
        console.log(e.message)
        this.message = 'Please enter a valid email address'
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.password-form {
  &__email {
    display: block;
    width: 100%;
    max-width: 320px;
  }
}
</style>
