import { cart as ApiCart } from '../lib/shopify'

interface CartState {
  cart: any | false;
  state: string;
}

export const state = (): CartState => ({
  cart: false,
  state: 'ready',
})

export const actions = {
  async load({ commit }: { commit: Function }): Promise<void> {
    const data = await ApiCart.get()
    commit('load', data)
  },
  async add({ commit, dispatch, state }: { commit: Function; dispatch: Function; state: CartState }, { id, quantity = 1 }: { id: string; quantity?: number }): Promise<any> {
    commit('setCartState', 'adding')

    await ApiCart.add({
      items: [{ id, quantity }],
    })

    await dispatch('load')
    commit('setCartState', 'added')
    setTimeout(() => {
      if (state.state === 'added') commit('setCartState', 'ready')
    }, 4000)
    return state.cart
  },
  async remove({ commit, dispatch, state }: { commit: Function; dispatch: Function; state: CartState }, { id }: { id: string }): Promise<void> {
    commit('setCartState', 'removing')

    await ApiCart.remove({
      updates: { [id]: 0 },
    })

    await dispatch('load')
    commit('setCartState', 'removed')
    setTimeout(() => {
      if (state.state === 'removed') commit('setCartState', 'ready')
    }, 4000)
  },
  async update({ commit, dispatch, state }: { commit: Function; dispatch: Function; state: CartState }, { id, quantity }: { id: string; quantity: number }): Promise<void> {
    commit('setCartState', 'updating')

    await ApiCart.update({
      updates: { [id]: quantity },
    })

    await dispatch('load')
    commit('setCartState', 'updated')
    setTimeout(() => {
      if (state.state === 'updated') commit('setCartState', 'ready')
    }, 4000)
  },
}

export const mutations = {
  load(state: CartState, data: any): void {
    state.cart = data
  },
  setCartState(state: CartState, val: string): void {
    state.state = val
  },
}

export const getters = {
  getItemCount(state: CartState): number {
    const cart = state.cart
    if (!cart) return 0
    return cart.item_count
  },
  getCartState(state: CartState): string {
    return state.state
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
