<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 638.65 159.29"
    class="svg-logo"
  >
    <defs></defs>
    <title>OZMA_Logotype</title>
    <path
      d="M564,35.5l28,69.28H537.11Zm42.59,105a23.9,23.9,0,0,1,2,8.82c0,6.84-5.29,9.27-19.2,9.71v6.62h61.55v-6.62c-6.17-1.11-10.8-2.43-13-8.17L580.13,9.25h-27.8L559,24.69,509.31,150.88c-2.21,6-6.84,7.06-13,8.17v6.62h44.35v-6.62C526.29,158.38,521,156,521,149.12a23.57,23.57,0,0,1,2.19-8.6l10.6-27.58h61.55Zm-155.23-1.1c0,1.76.22,4.63.22,5.5,0,10.6-5.07,12.8-20.52,14.13v6.62h65.08v-6.62c-11.91-.89-16.54-2.87-17.87-13.47L464.83,33.51c0-11.68,4.42-15.21,18.54-16.1V10.79h-42.8c-.44,5.08-1.55,8.17-3.31,13l-36.41,103-39.7-103c-1.77-4.84-2.87-7.93-3.31-13H314.59v6.62c14.13.89,18.76,4.42,18.76,16.1L319.67,145.58c-1.11,10.38-5.74,12.58-17.87,13.47v6.62h48.53v-6.62c-15.43-1.33-20.51-3.53-20.51-14.13,0-.87.22-3.74.44-5.5l11-95.75,47,122H396l43.89-122Zm-251.51,18.3L286.14,15.43V10.79H174.28L173,53.16h6c11-25.83,14.78-34.42,32.87-34.42h42.8L167.88,161.25v4.42H282.61l7.27-45.45h-6.39c-15.88,28.45-21.62,37.5-39.93,37.5ZM82.46,167.87c40.59,0,72.14-32.21,72.14-82.73C154.6,45,129.66,8.59,84.44,8.59,43.85,8.59,12.3,40.8,12.3,91.31c0,40.16,24.93,76.56,70.16,76.56m2-8.16c-30.44,0-44.13-36.63-44.13-72.15,0-37.71,14.13-70.81,42.15-70.81,30.44,0,43.9,36.63,43.9,72.14,0,37.73-14.12,70.82-41.92,70.82"
      transform="translate(-12.3 -8.59)"
    />
  </svg>
</template>

<script></script>

<style lang="scss">
.svg-logo {
  fill: var(--foreground);
}
</style>
