<template>
  <div class="footer-newsletter">
    <h3 class="footer-newsletter__heading">
      {{ heading }}
    </h3>

    <Newsletter class="footer-newsletter__newsletter">
      <template #button>
        <SvgArrow class="footer-newsletter__arrow" />
      </template>
    </Newsletter>

    <Mobile class="footer-newsletter__mobile">
      <template #button>
        <SvgArrow class="footer-newsletter__arrow" />
      </template>
    </Mobile>
  </div>
</template>

<script>
import Newsletter from '~/components/global/GlobalNewsletter.vue'
import Mobile from '~/components/global/GlobalMobile.vue'
import SvgClose from '../svg/SvgClose.vue'
import SvgArrow from '../svg/SvgArrow.vue'

export default {
  components: {
    Newsletter,
    Mobile,
    SvgClose,
    SvgArrow,
  },
  computed: {
    settings() {
      return this.$store.state.shopify.settings
    },
    heading() {
      return this.settings.footer_newsletter_text
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.footer-newsletter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @include respond-to('xxs') {
    grid-template-columns: 1fr;
  }

  &__arrow {
    width: 10px;
  }

  &__heading {
    grid-column: 1 / -1;
    padding-bottom: 20px;
  }

  .global-newsletter {
    @include cx-m;

    &__fields {
      padding: 5px 0 3px;
      border-bottom: 1px solid $dark;
      display: flex;
    }

    &__input {
      @include serif;
      flex: 1;
    }

    &__message {
      padding-top: 5px;
      color: $midgrey;
    }
  }
}
</style>
