<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 45.8 42.7"
    style="enable-background: new 0 0 45.8 42.7"
    xml:space="preserve"
  >
    <polygon
      class="st0"
      points="24.9,1 22.7,3.2 39.4,19.9 0.8,19.9 0.8,23 39.4,23 22.7,39.7 24.9,41.9 45.3,21.5 "
    />
  </svg>
</template>

<script></script>

<style lang="scss"></style>
