<template>
  <div class="excerpt" v-html="excerpt"></div>
</template>

<script>
export default {
  props: {
    text: String,
  },
  data() {
    return {
      limit: 150,
    }
  },
  computed: {
    excerpt() {
      return this.trimHtml(this.text, {
        limit: this.limit,
        moreLink: ' <span class="a">See More</span>',
      })
    },
  },
  methods: {
    trimHtml(html, options = {}) {
      const limit = options.limit || 100,
        preserveTags = typeof options.preserveTags !== 'undefined' ? options.preserveTags : true,
        wordBreak = typeof options.wordBreak !== 'undefined' ? options.wordBreak : false,
        suffix = options.suffix || '...',
        moreLink = options.moreLink || ''

      const arr = html
        .replace(/</g, '\n<')
        .replace(/>/g, '>\n')
        .replace(/\n\n/g, '\n')
        .replace(/^\n/g, '')
        .replace(/\n$/g, '')
        .split('\n')

      let sum = 0,
        row,
        cut,
        add,
        tagMatch,
        tagName,
        more = false
      const tagStack = []

      for (let i = 0; i < arr.length; i++) {
        row = arr[i]
        // count multiple spaces as one character
        const rowCut = row.replace(/[ ]+/g, ' ')

        if (!row.length) {
          continue
        }

        if (row[0] !== '<') {
          if (sum >= limit) {
            row = ''
          } else if (sum + rowCut.length >= limit) {
            cut = limit - sum

            if (row[cut - 1] === ' ') {
              while (cut) {
                cut -= 1
                if (row[cut - 1] !== ' ') {
                  break
                }
              }
            } else {
              add = row.substring(cut).split('').indexOf(' ')

              // break on halh of word
              if (!wordBreak) {
                if (add !== -1) {
                  cut += add
                } else {
                  cut = row.length
                }
              }
            }

            row = row.substring(0, cut) + suffix

            if (moreLink) {
              row += moreLink
            }

            sum = limit
            more = true
          } else {
            sum += rowCut.length
          }
        } else if (!preserveTags) {
          row = ''
        } else if (sum >= limit) {
          tagMatch = row.match(/[a-zA-Z]+/)
          tagName = tagMatch ? tagMatch[0] : ''

          if (tagName) {
            if (row.substring(0, 2) !== '</') {
              tagStack.push(tagName)
              row = ''
            } else {
              while (tagStack[tagStack.length - 1] !== tagName && tagStack.length) {
                tagStack.pop()
              }

              if (tagStack.length) {
                row = ''
              }

              tagStack.pop()
            }
          } else {
            row = ''
          }
        }

        arr[i] = row
      }

      return arr.join('\n').replace(/\n/g, '')
    },
  },
}
</script>

<style lang="scss"></style>
