<template>
  <div class="blocks blocks-lookbooks">
    <div
      v-for="(lookbook, idx) in getLookbooks"
      :id="lookbook.target"
      :key="`blocks-lookbooks__lookbook_${idx}`"
      class="block"
    >
      <GlobalBackgroundSwitch :enabled="(idx + 1) % 2">
        <GlobalReveal>
          <div class="block-inner">
            <GlobalSlideshow :slides="lookbook.images" theme="lookbook" />
            <div class="details">
              <a :href="lookbook.url" class="link"></a>
              <h3 class="title">Lookbook <span v-text="lookbook.title"></span></h3>
              <ArticleExcerpt :text="lookbook.excerpt" />
            </div>
          </div>
        </GlobalReveal>
      </GlobalBackgroundSwitch>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import GlobalBackgroundSwitch from '../global/GlobalBackgroundSwitch.vue'
import GlobalReveal from '../global/GlobalReveal.vue'
import ArticleExcerpt from '../article/ArticleExcerpt.vue'
import GlobalSlideshow from '../global/GlobalSlideshowLegacy.vue'

function formatImages(lookbook) {
  const html = lookbook.content
  const lookbook_el = window.document.createElement('html')
  lookbook_el.innerHTML = html

  if (html) {
    const images = lookbook_el.getElementsByTagName('img')

    return images.map((v) => {
      let alts = []
      let alt = v.getAttribute('alt')
      if (alt) {
        alts = alt.replace('/', '<br>').split('~')
        alt = alts.length == 1 ? alts[0] : `<a href="${alts[1]}">${alts[0]}</a>`
      } else {
        alt = ''
      }
      return {
        class: v.getAttribute('class'),
        url: v.getAttribute('src'),
        alt: alt,
        caption: alt,
        link: alts.length > 1 ? alts[1] : lookbook.url,
      }
    })
  } else {
    return []
  }
}

export default {
  components: {
    GlobalBackgroundSwitch,
    GlobalReveal,
    ArticleExcerpt,
    GlobalSlideshow,
  },
  props: {
    lookbooks: Array,
  },
  computed: {
    getLookbooks() {
      const ret = this.lookbooks.map((lookbook) => {
        return {
          target: `${lookbook.handle.replace('/', '-')}`,
          title: lookbook.title,
          excerpt: lookbook.excerpt,
          url: lookbook.url,
          images: formatImages(lookbook),
        }
      })
      return ret
    },
  },
}
</script>

<style lang="scss"></style>
