export const DEFAULT_CURRENCY = 'USD'

interface CurrencyState {
  store: string;
}

export const state = (): CurrencyState => ({
  store: DEFAULT_CURRENCY,
})

export const actions = {
  async load({ commit }: { commit: Function }): Promise<void> {
    commit('set', (window as any).Shopify.currency.active || DEFAULT_CURRENCY)
  },
}

export const mutations = {
  set(state: CurrencyState, currency: string): void {
    state.store = currency
  },
}

export const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
