<template>
  <transition name="fade">
    <div class="overlay overlay-instalments" :class="{ active: overlay == 'instalments' }">
      <div class="overlay-inner">
        <div class="close" @click="close"><SvgClose /></div>
        <h2>Pay now or later - it's up to you.</h2>
        <p>Buy what you love and split the cost. It's easy and interest free.</p>
        <p>
          Select Shop Pay at checkout to pay in full or in 4 equal installments. Your first payment is
          taken at checkout and the remaining 3 are automatically taken every two weeks.
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
import SvgClose from '../svg/SvgClose.vue'

export default {
  components: {
    SvgClose,
  },
  computed: {
    overlay() {
      return this.$store.state.overlay.current
    },
  },
  methods: {
    close() {
      this.$store.commit('overlay/open', '')
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay.overlay-instalments {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: none;
  pointer-events: none;
  &.active {
    display: flex;
  }
  .overlay-inner {
    position: relative;
    margin: auto;
    background: $light;
    border: 1px solid $dark;
    padding: 40px 40px 60px;
    width: calc(100vw - 40px);
    max-width: 420px;
    text-align: center;
    @include cx-l();
    pointer-events: auto;

    h2 {
      @include cx-xl();
    }

    p {
      margin-top: 1em;
    }

    @include respond-to(xs) {
      // padding: 30px 30px 40px;
      @include cx-m();
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    @include respond-to(s) {
      display: none;
    }
  }
}
</style>
