<template>
  <GlobalLinkAccount
    v-if="link.url === '#account'"
    class="global-link global-link--account"
    :link="link"
  />
  <GlobalLinkLogout
    v-else-if="link.url.startsWith('#logout')"
    class="global-link global-link--logout"
    :link="link"
  />
  <GlobalLinkBlank
    v-else-if="link.url === '#blank'"
    class="global-link global-link--blank"
    :link="link"
  />
  <GlobalLinkCart v-else-if="link.url === '#cart'" class="global-link global-link--cart" :link="link" />
  <GlobalLinkSearch
    v-else-if="link.url === '#search'"
    class="global-link global-link--search"
    :link="link"
  />
  <GlobalLinkSearchmobile
    v-else-if="link.url === '#searchmobile'"
    class="global-link global-link--search-mobile"
    :link="link"
  />
  <span v-else-if="link.url === '#currency'"></span>
  <GlobalLinkSubmenu
    v-else-if="link.url.startsWith('#submenu')"
    class="global-link global-link--submenu"
    :link="link"
  />
  <GlobalLinkBandana
    v-else-if="link.url.startsWith('#bandana')"
    class="global-link global-link--bandana"
    :link="link"
  />
  <GlobalLinkDefault v-else class="global-link global-link--default" :link="link" />
</template>

<script>
import GlobalLinkAccount from './link/GlobalLinkAccount.vue'
import GlobalLinkLogout from './link/GlobalLinkLogout.vue'
import GlobalLinkBlank from './link/GlobalLinkBlank.vue'
import GlobalLinkCart from './link/GlobalLinkCart.vue'
import GlobalLinkSearch from './link/GlobalLinkSearch.vue'
import GlobalLinkSearchmobile from './link/GlobalLinkSearchmobile.vue'
import GlobalLinkCurrency from './link/GlobalLinkCurrency.vue'
import GlobalLinkSubmenu from './link/GlobalLinkSubmenu.vue'
import GlobalLinkBandana from './link/GlobalLinkBandana.vue'
import GlobalLinkDefault from './link/GlobalLinkDefault.vue'

export default {
  name: 'GlobalLink',
  components: {
    GlobalLinkAccount,
    GlobalLinkLogout,
    GlobalLinkBlank,
    GlobalLinkCart,
    GlobalLinkSearch,
    GlobalLinkSearchmobile,
    GlobalLinkCurrency,
    GlobalLinkSubmenu,
    GlobalLinkBandana,
    GlobalLinkDefault,
  },
  props: {
    link: Object,
  },
}
</script>
