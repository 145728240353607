import Axios from 'axios'
import qs from 'qs'
import { LiquidCart, LiquidProduct, LiquidVariant, LiquidLineItem } from '../types/liquid'

export const NewsletterSubscribe = async function ({ listId, email, data }: { listId: string; email: string; data?: Record<string, unknown> }): Promise<AxiosResponse<any>> {
  if (!listId) throw new Error('List ID Required')
  if (!email) throw new Error('Email Required')

  const result = await Axios.request({
    url: 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe',
    method: 'POST',
    headers: {
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
    data: qs.stringify({
      g: listId,
      email,
      ...data,
    }),
  })

  if (result.data.errors && Array.isArray(result.data.errors) && result.data.errors.length) {
    throw new Error(result.data.errors.join(', '))
  }

  return result
}

export const MobileSubscribe = async function ({ phone }) {
  if (!phone) throw new Error('Number Required')

  try {
    const result = await Axios.request({
      method: 'POST',
      url: 'https://ozma-services.vercel.app/api/klaviyo/sms',
      data: {
        phone,
      },
    })

    return result
  } catch (e) {
    throw new Error(e)
  }
}

export const RegisterSubscribe = async function ({ firstName, lastName, email }) {
  const listId = 'NSF6SY'

  // if(!phone) throw new Error("Email Required");

  try {
    const result = await Axios.request({
      url: 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe',
      method: 'POST',
      headers: {
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
      data: qs.stringify({
        g: listId,
        email,
        $fields: '$source, $first_name, $last_name',
        $source: 'Account Creation',
        $first_name: firstName,
        $last_name: lastName,
      }),
    })

    return result
  } catch (e) {
    throw new Error(e)
  }
}

// export const klaviyoViewProduct = (context) => {
//   const object = transformklaviyoViewProduct(context);

//   window._learnq = [
//     ...(window._learnq || []),
//     [
//       "track",
//       "Viewed Product",
//       object,
//     ]
//   ];
// }

type CartItemProps = {
  shopifyItem: LiquidLineItem
  shopifyProduct: LiquidProduct
}

function transformKlaviyoAddToCartItem({ shopifyItem, shopifyProduct }: CartItemProps) {
  const imageUrl = shopifyProduct.images[0]?.src || ''
  const collections = shopifyProduct.collections.map((category) => category.title)

  return {
    ProductID: shopifyProduct.id,
    ProductName: shopifyProduct.title,
    SKU: shopifyItem.sku,
    Quantity: shopifyItem.quantity,
    ItemPrice: shopifyItem.price,
    RowTotal: shopifyItem.line_price,
    ProductURL: shopifyProduct.url,
    ImageURL: imageUrl,
    ProductCategories: collections,
  }
}

export type AddToCardProps = {
  shopifyProduct: LiquidProduct
  shopifyVariant: LiquidVariant
  cart: LiquidCart
  quantity: number
}

function transformKlaviyoAddToCartProduct({
  shopifyProduct,
  shopifyVariant,
  quantity,
  cart,
}: AddToCardProps) {
  const imageUrl = shopifyProduct.images[0].src
  const collections = shopifyProduct.collections.map((category) => category.title)
  const url = shopifyProduct.url

  return {
    $value: parseFloat(shopifyVariant.price) * quantity,
    AddedItemProductName: shopifyProduct.title,
    AddedItemProductID: shopifyProduct.id,
    AddedItemSKU: shopifyVariant.sku,
    AddedItemCategories: collections,
    AddedItemImageURL: imageUrl,
    AddedItemURL: url,
    AddedItemPrice: shopifyVariant.price,
    AddedItemQuantity: quantity,
    Items: cart.items.map((shopifyItem) =>
      transformKlaviyoAddToCartItem({
        shopifyItem,
        shopifyProduct,
      })
    ),
  }
}

export const klaviyoAddToCart = (context: AddToCardProps) => {
  const object = transformKlaviyoAddToCartProduct(context)
  window._learnq = [...(window._learnq || []), ['track', 'Added to Cart', object]]
}

export default {
  klaviyoAddToCart,
  NewsletterSubscribe,
  MobileSubscribe,
  RegisterSubscribe,
}
