import SanityContentVideo from '~/components/sanity/content/SanityContentVideo.vue'
export default {
  functional: true,
  render(h, ctx) {
    const { props } = ctx
    const { asset } = props
    // const { metadata } = asset.metadata;

    return h(
      SanityContentVideo,
      {
        props: {
          asset,
        },
      },
      props.children
    )
  },
}
