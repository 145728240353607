<template>
  <OverlayWrap class="overlay-cart" :title="`Bag (${getItemCount})`" :is-active="overlay == 'cart'">
    <div class="inner">
      <div v-if="hasItems" class="items">
        <div class="items-inner">
          <transition-group name="cart-items">
            <div v-for="li in cart.items" :key="li.id" class="item" :class="getItemClass">
              <div class="item-inner">
                <div class="item-image">
                  <a :href="li.url">
                    <ShopifyImage :src="li.image" ratio="6x8" />
                  </a>
                </div>

                <div class="item-description">
                  <ShopifyMoney class="price" :amount="li.line_price / 100" />

                  <h3 class="title">
                    <a :href="li.url">
                      {{ li.product_title }}
                    </a>
                  </h3>

                  <div class="selection">
                    <div v-if="li.variant_title" class="options">
                      {{ li.variant_title }}
                    </div>
                    <div>
                      QTY:
                      <button class="select" @click="decrement(li)">-</button>

                      <span>
                        {{ li.quantity }}
                      </span>

                      <button class="select" @click="increment(li)">+</button>
                    </div>
                  </div>

                  <div class="remove">
                    <button class="a" @click="remove(li)">Remove</button>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>

      <div v-else class="empty">
        <div class="empty-inner">
          <p>Nothing in your cart yet.</p>
        </div>
      </div>

      <div class="controls">
        <div class="controls-inner">
          <div v-if="hasItems" class="total total-nodiscount">
            <ShopifyMoney :amount="cart.total_price / 100" />
            &nbsp;&nbsp;Total
          </div>
        </div>

        <div v-if="hasItems" class="next">
          <a href="/checkout">Checkout</a>
        </div>

        <div v-else class="next">
          <a href="/">Keep Shopping</a>
        </div>
      </div>
    </div>
  </OverlayWrap>
</template>

<script>
import Vuex from 'vuex'
import SvgClose from '../svg/SvgClose.vue'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import ShopifyMoney from '../shopify/ShopifyMoney.vue'
import OverlayWrap from './OverlayWrap.vue'

export default {
  computed: {
    ...Vuex.mapState({
      overlay: (state) => state.overlay.current,
      cart: (state) => state.cart.cart,
      cartStatus: (state) => state.cart.state,
    }),
    getItemCount() {
      return this.$store.getters['cart/getItemCount']
    },
    getItemClass() {
      return {
        updating: this.cartStatus != 'ready',
      }
    },
    hasItems() {
      return this.getItemCount > 0
    },
  },
  components: { SvgClose, ShopifyImage, ShopifyMoney, OverlayWrap },
  methods: {
    increment(line) {
      console.log(this.cart)
      // console.log('increment', line)
      this.$store.dispatch('cart/update', { id: line.id, quantity: line.quantity + 1 })
    },
    decrement(line) {
      // console.log('decrement', line)
      this.$store.dispatch('cart/update', { id: line.id, quantity: line.quantity - 1 })
    },
    remove(line) {
      // console.log('remove', line)
      this.$store.dispatch('cart/update', { id: line.id, quantity: 0 })
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-cart {
  .inner {
    display: flex;
    position: relative;
    min-height: 200px;
    flex-direction: column;
    justify-content: space-between;
    a {
      text-decoration: none;
    }
    > .title {
      @include cx-s();
      padding: 20px var(--inset) 15px;
    }
    .empty {
      flex-grow: 1;
      padding: 0 var(--inset);
      .empty-inner {
        @include cx-m();
        padding-top: 20px;
        text-align: center;
      }
    }
    .items-inner {
      @include clearfix();
      flex-grow: 1;
      padding: 0 var(--inset);
      max-height: calc(100vh - 300px);
      overflow-y: auto;
      .item {
        @include cx-s();
        position: relative;
        transition: all 1s ease-out;
        &.updating {
          pointer-events: none;
          opacity: 0.7;
        }
        .item-inner {
          display: flex;
          justify-content: space-between;
          align-items: top;
          padding: 5px 0;
        }
        .item-image {
          flex-basis: 120px;
          img {
            mix-blend-mode: multiply;
          }
        }
        .item-description {
          flex-grow: 1;
          padding-left: 20px;
          @include sans();
          @include clearfix();
          .price {
            float: right;
          }
          .title {
            padding-bottom: 10px;
            @include cx-uc();
          }
          .select {
            cursor: pointer;
            padding: 0 3px;
            user-select: none;
            &:hover {
              border-bottom: 1px solid $dark;
            }
          }
          .remove {
            @include serif();
            padding-top: 20px;
            @include helper-underline();
          }
        }
      }
    }
    .controls {
      text-align: left;
      padding: 20px var(--inset) 30px;
      .controls-inner {
        position: relative;
        @include cx-uc();
        @include cx-s();
      }
      .total {
        @include sans();
        @include cx-uc();
        @include cx-xs();

        &-nodiscount {
          @include cx-s();
          padding-bottom: 20px;
          padding-top: 20px;
          border-top: 1px solid $dark;
        }

        &-without-discount {
          border-top: 1px solid $dark;
          padding-top: 10px;
          padding-bottom: 2px;
        }

        &-with-discount {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        span {
          float: right;
        }
      }
      .next {
        text-align: center;
        a {
          @include form-button();
          margin: 0;
          display: block;
        }
      }
    }
  }
}
</style>
