<template>
  <div class="collection-page-landing">
    <div class="collection-page-landing__figure">
      <AccentuateVideo
        class="collection-page-landing__video"
        :src="getVideo"
        v-if="getVideo"
        ratio="6x8"
      />
      <AccentuateImage class="collection-page-landing__image" :src="getSrc" v-else ratio="6x8" />
    </div>

    <section class="collection-page-landing__description">
      <h2>{{ page.title }}</h2>
      <div v-html="page.content"></div>
    </section>

    <ProductCards class="collection-page-landing__cards" :show-video="true" :products="products" />
  </div>
</template>

<script>
import AccentuateImage from '~/components/shopify/ShopifyAccentuateImage.vue'
import AccentuateVideo from '~/components/shopify/ShopifyAccentuateVideo.vue'

import ProductCards from '~/components/product/ProductCards.vue'

export default {
  props: {
    page: Object,
    collection: Object,
    products: Array,
  },
  components: {
    ProductCards,
    AccentuateImage,
    AccentuateVideo,
  },
  computed: {
    getVideo() {
      if (!this.page.metafields.video) return
      const [video] = this.page.metafields.video
      return video.src
    },
    getSrc() {
      const [image] = this.page.metafields.image
      return image.original_src
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.collection-page-landing {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px calc(100% / 17);

  @include respond-to(m) {
    grid-template-columns: 1fr 1fr;
  }

  @include respond-to(s) {
    /* grid-column: 2 / 3; */
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include respond-to(s) {
      object-fit: initial;
      height: auto;
    }
  }

  &__figure {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    margin-right: calc(100% / 17 * 2);
    @include respond-to(xl) {
      grid-row: initial;
    }

    @include respond-to(s) {
      margin-right: 0;
    }
  }

  &__description {
    margin-left: calc(100% / 17 * -2);
    @include respond-to(s) {
      order: -1;
      grid-column: 1 / -1;
      margin-left: 0;
    }

    h2 {
      @include cx-s;
      @include cx-uc;
      @include sans;
      padding-bottom: 20px;
      text-align: center;

      @include respond-to(s) {
        display: none;
      }
    }
  }

  &__cards {
    display: contents;
    :first-child {
      grid-column: 3 / 4;

      @include respond-to(xl) {
        grid-column: initial;
      }
    }
  }
}
</style>
