<template>
  <div class="global-slideshow" :class="getClass">
    <div class="slideshow">
      <div class="slides" :style="formatSlidesStyle">
        <div
          class="slide"
          v-for="(slide, index) in formatSlides"
          :data-index="slide.idx"
          :style="formatSlideTranslateStyle(slide)"
          :key="`global-slideshow__slide_${index}`"
        >
          <div class="slide-inner">
            <a class="link" v-if="slide.link" :href="slide.link"></a>
            <div class="image"><img :src="slide.url" /></div>
            <div class="caption">
              <p class="title" v-html="slide.caption"></p>
            </div>
          </div>
        </div>
      </div>

      <div class="controls">
        <div class="touch touch-left" ref="sliderSwipeLeft"></div>
        <div class="touch touch-right" ref="sliderSwipeRight"></div>

        <div class="control control-left" @click.prevent="prevSlide()">
          <SvgPrevious class="arrow" />
        </div>

        <div class="control control-right" @click.prevent="nextSlide()">
          <SvgNext class="arrow" />
        </div>

        <div class="pager">
          <div class="page">
            <ul>
              <li
                v-for="(slide, slideIndex) in slides"
                :class="{ active: slideIndex === selected }"
                @click="selected = slideIndex"
                :key="`global-slideshow__page_${slideIndex}`"
              ></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import $ from 'jquery'
import Hammer from 'hammerjs'
import BezierEasing from 'bezier-easing'
import SvgPrevious from '../svg/SvgPrevious.vue'
import SvgNext from '../svg/SvgNext.vue'
import { preloadImages } from '~/lib/preload'

export default {
  props: {
    slides: Array,
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      panOffset: 0,
      width: 0,
      selected: 0,
      height: 0,
    }
  },
  watch: {
    watchSelected: {
      handler(v) {
        this.selected = v
      },
      immediate: true,
    },
  },
  async mounted() {
    //Preload images
    await preloadImages(this.slides.map((v) => v.url))

    // Dimensions
    this.$nextTick(() => {
      this.updateDims()
    })

    window.addEventListener('resize', () => this.updateDims())

    //Handle swiping
    const $touch_els = this.$el.querySelectorAll('.touch')
    _.each($touch_els, ($touch_el) => {
      const hm = new Hammer($touch_el)
      hm.on('panstart', (ev) => {
        this.panStart(ev)
      })
      hm.on('panmove', (ev) => {
        this.panMove(ev)
      })
      hm.on('panend', (ev) => {
        this.panEnd(ev)
      })
    })
    this.updateSelected(0)
  },
  methods: {
    updateDims() {
      const $slide = $(this.$el).find('.slide')
      this.width = $slide.width()
      // this.height = 0; //Reset to 0, otherwise it just keeps getting taller
      const heights = _.map($slide, (v) => $(v).height())
      setTimeout(() => {
        // this.height = Math.max(...heights);
      }, 50)
    },
    panStart(ev) {
      this.panOffset = 0
    },
    panMove(ev) {
      //Max drag as ratio
      const max = 0.6
      //Convert delta X to an -1 - 1 number
      let ratio = ev.deltaX / this.width
      //Constrain the ratio to -0.5 to 0.5;
      if (ratio < -max) ratio = -max
      if (ratio > max) ratio = max
      //Easing should slow the further you drag
      const ease = function (t) {
        return t * (2 - t)
      }
      const abs = Math.abs(ratio) * (1 / max) //ease(Math.abs(ratio) * 2);
      ratio = ratio > 0 ? abs / (1 / max) : -abs / (1 / max)
      //Convert back from ratio to a number
      const offset = ratio * this.width
      this.panOffset = offset
      //Take over scroll if you have gone too far?
    },
    panEnd(ev) {
      //If we have panned further than X, pan to next slide and set selected slide
      const swipe_distance = 50 //this.width / 6; //Lets use what feels normal to the finger
      if (Math.abs(this.panOffset) > swipe_distance) {
        if (this.panOffset < 0) {
          this.nextSlide()
        } else {
          this.prevSlide()
        }
      } else {
        this.resetSlide()
      }
    },
    updateSelected(select) {
      //Update selected
      this.$emit('select', {
        total: this.slides.length,
        select: select,
        slideshow: 'default',
      })

      //Update selected
      this.selected = select
    },
    nextSlide() {
      //Difference between current offset and width
      const start = this.panOffset
      const end = -this.width
      this.animateOffset(start, end, () => {
        //Set slide and revert pan offset
        this.panOffset = 0
        const select = this.selected + 1 >= this.slides.length ? 0 : this.selected + 1
        this.updateSelected(select)
      })
    },
    prevSlide() {
      //Difference between current offset and width
      const start = this.panOffset
      const end = this.width
      this.animateOffset(start, end, () => {
        //Set slide and revert pan offset
        this.panOffset = 0
        const select = this.selected - 1 < 0 ? this.slides.length - 1 : this.selected - 1
        this.updateSelected(select)
      })
    },
    resetSlide() {
      //Smoothly go back to 0
      const start = this.panOffset
      const end = 0
      this.animateOffset(start, end, () => {
        this.panOffset = 0
      })
    },
    setSlide(slide_idx) {
      //Animate from current slide to whichever slide we are going to
      const start = this.panOffset
      const end = (this.selected - slide_idx) * this.width + this.panOffset
      this.animateOffset(start, end, () => {
        this.updateSelected(slide_idx)
        this.panOffset = 0
      })
    },
    animateOffset(start, end, cb) {
      //Convert offset to a sensible number of frames
      const frame_total = 80
      let frames = frame_total
      let active = true
      //Easing function
      // var ease = function (t) { return t * (2 - t) };
      const easing = BezierEasing(0.77, 0, 0.175, 1)
      const ease = function (t) {
        return easing(t)
      }
      //Get distance as ratio
      const difference = end - start
      //Animate from start to end duraion frames
      const loop = (now) => {
        frames -= 1
        if (!!active) {
          //Get frames as ratio
          const animation_ratio = ease((frame_total - frames) / frame_total)
          const position = start + animation_ratio * difference
          this.panOffset = position
          if (frames <= 0) {
            active = false
            cb()
          }
          requestAnimationFrame(loop)
        }
      }
      requestAnimationFrame(loop)
    },
    formatSlide(slide, slide_idx, selected) {
      return _.merge(slide, {
        is_hanging: false,
        idx: slide_idx,
        selected: selected,
        start_or_end: -1,
        selected_offset: -1,
      })
    },
    formatHangingSlide(slides, selected, selected_offset = 1) {
      let selected_idx
      const length = slides.length
      const selected1 = selected + selected_offset
      //Get index, wrap around end of array
      if (selected + selected_offset >= length) {
        selected_idx = selected1 - length
      } else if (selected1 < 0) {
        const temp_pos = selected_offset + selected
        selected_idx = length + temp_pos
      } else {
        selected_idx = selected1
      }
      const slide = _.clone(slides[selected_idx])
      return _.merge(slide, {
        is_hanging: true,
        idx: selected_idx,
        selected: selected,
        selected_offset: selected_offset,
      })
    },
  },
  computed: {
    formatSlideTranslateStyle() {
      return (slide) => {
        let offset
        const width = this.width
        const panOffset = this.panOffset
        if (!slide.is_hanging) {
          offset = panOffset
        } else {
          const pseudo_index = slide.selected_offset
          offset = pseudo_index * width + panOffset
        }
        //Slide translate offset is set by its index * width + its current drag
        return { transform: `translateX(${offset}px)` }
      }
    },
    formatSlidesStyle() {
      return { height: `${this.height + 60}px` }
    },
    formatSlides() {
      return [
        this.formatHangingSlide(this.slides, this.selected, -1),
        this.formatSlide(this.slides[this.selected], this.selected, this.selected),
        this.formatHangingSlide(this.slides, this.selected, 1),
      ]
    },
    getClass() {
      if (this.theme == 'lookbook') return 'global-slideshow--lookbook'
      return ''
    },
  },
  components: { SvgPrevious, SvgNext },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.global-slideshow {
  .slideshow {
    position: relative;
    padding-bottom: 30px;

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    .slides {
      position: relative;
      padding-top: 133.33%;
      transition: padding 0.2s ease-out;
      overflow: hidden;
      .slide {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: left;
        .image {
          position: absolute;
          left: 0;
          top: 0;
          height: calc(100%);
          width: 100%;
          margin: 0;
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
        .caption {
          position: absolute;
          left: 0;
          bottom: 0;
          @include sans();
          @include cx-xs();
          padding-top: 10px;
        }
      }
    }

    .controls {
      .touch-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 110;
        cursor: pointer;
        display: none;
      }
      .control-right,
      .control-left {
        position: absolute;
        top: 0;
        height: 100%;
        width: 50%;
        z-index: 100;
        cursor: pointer;
        svg {
          position: absolute;
          top: 50%;
          transform: translateX(0) translateY(-50%);
          width: 40px;
          height: auto;
          fill: none;
          stroke: #5c5144;
          stroke-width: 2.5;
          transition: transform 0.2s ease-out;
        }
        @include touch() {
          display: none;
        }
      }
      .control-right {
        left: 50%;
        svg {
          right: $gutter + 10px;
        }
        @include hover() {
          svg {
            // animation: nextAnimation 0.7s infinite;
            transform: translateX(10px) translateY(-50%);
          }
        }
      }
      .control-left {
        left: 0;
        svg {
          left: $gutter + 10px;
        }
        @include hover() {
          svg {
            // animation: prevAnimation 0.7s infinite;
            transform: translateX(-10px) translateY(-50%);
          }
        }
      }

      .touch {
        @include touch() {
          display: block;
        }
      }
    }
    .pager {
      position: absolute;
      bottom: 00px;
      left: 10px;
      right: 10px;
      z-index: 150;
      .overlay {
        float: right;
        cursor: pointer;
      }
      .page {
        text-align: center;
        counter-reset: pageCount;
        ul {
          @include hlist();
          li {
            counter-increment: pageCount;
            padding: 0 3px;
            color: $midgrey;
            font-size: 16px;
            // span {
            @include sans();
            @include cx-xxs();
            display: inline-block;
            cursor: pointer;
            &:after {
              content: counter(pageCount);
            }
            // }
            &.active {
              color: $dark;
            }
          }
        }
      }
    }
    &.slideshow-single {
      .controls {
        .control-left,
        .control-right {
          display: none;
        }
        .touch-left,
        .touch-right {
          width: 50%;
          &.touch-left {
            left: 0;
          }
          &.touch-right {
            right: 0;
          }
        }
      }
      .pager {
        ul {
          display: none;
        }
      }
    }
  }

  &--lookbook {
    .slideshow {
      .slides {
        padding-top: 70%;
        .slide {
          .link {
            @include nontouch() {
              @include cover-link();
              z-index: 5;
            }
          }
          .image {
            height: calc(100% - 40px);
          }
          .caption {
            @include cx-xxs();
            padding-top: 5px;
          }
        }
      }
      .controls {
        .control-right,
        .control-left {
          height: calc(100% - 60px);
          width: calcPercentage(16, 3);
          svg {
            position: absolute;
            top: 50%;
            transform: translateX(0) translateY(-50%);
            width: 32px;
            height: auto;
            fill: none;
            stroke: #5c5144;
            stroke-width: 2.5;
            transition: transform 0.2s ease-out;
          }
        }
        .control-right {
          left: auto;
          right: calcPercentage(16, -3);
          svg {
            left: 20px;
          }
          @include hover() {
            svg {
              // animation: nextAnimation 0.7s infinite;
              transform: translateX(10px) translateY(-50%);
            }
          }
        }
        .control-left {
          left: calcPercentage(16, -3);
          svg {
            left: auto;
            right: 20px;
          }
          @include hover() {
            svg {
              // animation: prevAnimation 0.7s infinite;
              transform: translateX(-10px) translateY(-50%);
            }
          }
        }
      }
      .pager {
        @include sans();
        position: absolute;
        bottom: 95px;
        left: 0;
        width: 100%;
        color: $background;
        font-size: 18px;
        .page {
          ul {
            display: flex;
            justify-content: center;
            padding: 0 10px;
            li {
              padding: 5px;
              cursor: pointer;
              -webkit-font-smoothing: antialiased;
              font-size: 14px;
              color: transparentize($light, 0.5);
              &.active {
                color: $light; //transparentize($light,0.5);
              }

              &:after {
                content: '\25CB';
              }
              &.active:after {
                content: '\25CF';
              }
            }
          }
        }
        display: none;
      }
    }

    @include respond-to(m) {
      .slideshow {
        .slides {
        }
        .controls {
          .control-left,
          .control-right {
            display: none;
          }
          .touch-right {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .pager {
            display: none;
          }
        }
      }
    }
  }
}
</style>
