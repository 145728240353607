<template>
  <div v-if="product.metafields.collection.products > 0" class="product-more">
    <h2 class="product-more__heading">
      More In<br />
      {{ product.metafields.collection.title }}
    </h2>
    <GlobalCarousel
      :slides="product.metafields.collection.products.slice(0, 6)"
      :slide-component="'GlobalSlideProduct'"
    />
  </div>
</template>

<script>
import GlobalCarousel from '../global/GlobalCarousel.vue'

export default {
  components: { GlobalCarousel },
  props: {
    product: Object,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-more {
  &__heading {
    @include sans;
    @include cx-uc;
    @include cx-l;
    display: flex;

    @include respond-to('m+') {
      @include wrapper;
      margin-bottom: 60px;

      &:before {
        display: block;
        content: ' ';
        width: columns(4);
      }
    }

    @include respond-to(m) {
      margin-bottom: 40px;
    }
  }

  @include respond-to('m') {
    @include wrapper;
  }
}
</style>
