import sanitizeHtml from 'sanitize-html'

const shareHtml = '<div is="share" :url="`https://ozmaofcalifornia.com/${shareUrl}`"></div>'

export function legacyArticleContent(html: string): string {
  const commentHTML = html
    .replaceAll(
      '<!--row-image-text-->',
      '<div class="row row-image-text"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll(
      '<!--row-text-image-->',
      '<div class="row row-text-image"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll(
      '<!--row-image-image-->',
      '<div class="row row-image-image"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll(
      '<!--row-image-->',
      '<div class="row row-image-wide"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll('<!--endrow-->', '</div></div></div>')
    .replaceAll('<!--text-->', '<div class="text">')
    .replaceAll('<!--image-->', '<div class="image">')
    .replaceAll('<!--end-->', '</div>')
    .replaceAll('<!--share-->', shareHtml)

  const sanitisedHTML = sanitizeHtml(commentHTML, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedClasses: {
      '*': ['row*', 'text', 'image'],
    },
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      div: ['style'],
      p: ['style'],
    },
    exclusiveFilter: (frame) => {
      return (
        ['div', 'h2', 'p', 'span', 'i'].includes(frame.tag) &&
        !frame.text.trim() &&
        frame.mediaChildren.length == 0
      )
    },
    selfClosing: ['img', 'br'],
  })

  const finalHtml = sanitisedHTML
    .replaceAll(
      '<p>[row-image-text]</p>',
      '<div class="row row-image-text"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll(
      '<p>[row-text-image]</p>',
      '<div class="row row-text-image"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll(
      '<p>[row-image-image]</p>',
      '<div class="row row-image-image"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll(
      '<p>[row-image]</p>',
      '<div class="row row-image-wide"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll('<p>[endrow]</p>', '</div></div></div>')
    .replaceAll('<p>[text]</p>', '<div class="text">')
    .replaceAll('<p>[image]</p>', '<div class="image">')
    .replaceAll('<p>[caption]</p>', '<div class="image">')
    .replaceAll('<p>[end]</p>', '</div>')
    .replaceAll('<p>[share]</p>', shareHtml)
    .replaceAll(
      '[row-image-text]',
      '<div class="row row-image-text"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll(
      '[row-text-image]',
      '<div class="row row-text-image"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll(
      '[row-image-image]',
      '<div class="row row-image-image"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll(
      '[row-image]',
      '<div class="row row-image-wide"><div is="reveal" ><div class="row-inner">'
    )
    .replaceAll('[endrow]', '</div></div></div>')
    .replaceAll('[text]', '<div class="text">')
    .replaceAll('[image]', '<div class="image">')
    .replaceAll('[caption]', '<div class="image">')
    .replaceAll('[end]', '</div>')
    .replaceAll('[share]', shareHtml)
    .replaceAll('<p>&nbsp;</p>', '')
    .replaceAll('<p><br /> </div></p>', '</div>')
    .replaceAll('<p><br /></div></p>', '</div>')

  return finalHtml
}

export function legacyPageContent(html) {
  const commentHtml = html
    .replaceAll('<!--row-center-->', '<div is="reveal" class="content-center">')
    .replaceAll('<!--row-columns-->', '<div is="reveal" class="content-columns">')
    .replaceAll('<!--row-image-text-->', '<div is="reveal" class="content-image-text">')
    .replaceAll('<!--row-text-image-->', '<div is="reveal" class="content-text-image">')
    .replaceAll('<!--row-image-image-->', '<div is="reveal" class="content-image-image">')
    .replaceAll('<!--endrow-->', '</div>')
    .replaceAll('<!--text-->', '<div class="text">')
    .replaceAll('<!--image-->', '<div class="image">')
    .replaceAll('<!--end-->', '</div>')

  const sanitisedHTML = sanitizeHtml(commentHtml, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: false,
    parseStyleAttributes: false,
  })

  return sanitisedHTML
}
