<template>
  <div class="global-share">
    <div class="global-share__inner">
      <h3>Share this post</h3>
      <ul>
        <li><a target="_blank" :href="getUrl('email')">Email</a></li>
        <li><a target="_blank" :href="getUrl('facebook')">Facebook</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
    title: String,
  },
  methods: {
    getUrl: function (type) {
      if (type == 'facebook') return `https://www.facebook.com/sharer/sharer.php?u=${this.url}`
      if (type == 'twitter') return `https://twitter.com/home?status=${this.url}`
      if (type == 'instagram') return `https://twitter.com/home?status=${this.url}`
      if (type == 'email') return `mailto:?subject=Ozma - ${this.title}&body=${this.url}`
      return ''
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.global-share {
  @include hlist;
  @include helper-link-underline;
  padding-top: 60px;

  h3 {
    @include cx-m;
    @include cx-uc;
    @include sans;
    font-style: normal;
    padding-bottom: 5px;
  }

  ul {
    li {
      @include cx-xs;
      margin-left: 0;
      margin-right: 10px;
    }
  }
}
</style>
