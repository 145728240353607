import { axiosGet, axiosPost } from './client'

interface CartData {
  [key: string]: unknown
}

export async function get<T>(): Promise<T> {
  return await axiosGet<T>(`/cart.json`)
}

export async function add<T>(data: CartData): Promise<T> {
  return await axiosPost<T>(`/cart/add.js`, { data })
}

export async function remove<T>(data: CartData): Promise<T> {
  return await axiosPost<T>(`/cart/update.js`, { data })
}

export async function update<T>(data: CartData): Promise<T> {
  return await axiosPost<T>(`/cart/update.js`, { data })
}

export async function clear<T>(data: CartData): Promise<T> {
  return await axiosPost<T>(`/cart/clear.js`, { data })
}
