<template>
  <section class="landing background-alt">
    <div class="landing-inner">
      <div
        class="caption"
        :style="landingCaptionStyle"
        :class="{ light: landing.home_landing_light }"
        v-html="landing.home_landing_text"
      ></div>

      <div class="slide">
        <div
          v-for="image in landingImages"
          :key="image.src"
          class="slide__image"
          :style="`background-image:url('${image.src}')`"
          :class="image.index == index ? 'slide__image--active' : ''"
        >
          <a v-if="image.url && image.url != ''" class="slide__link" :href="image.url"></a>

          <video v-if="!!image.videoSrc" class="slide__video" autoplay loop muted playsinline>
            <source :src="image.videoSrc" />
          </video>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BezierEasing from 'bezier-easing'
import BrowserMixin from '~/mixins/browser'

const SLIDE_DELAY = 6500

export default {
  mixins: [BrowserMixin],
  props: {
    landing: Object,
  },
  data() {
    return {
      index: 0,
      loaded: 0,
    }
  },
  computed: {
    landingImages() {
      const landing = this.landing
      const images = []

      for (const key in landing) {
        if (key.indexOf('home_landing_image') !== -1) {
          const number = parseInt(key.slice(-1))

          let videoSrc = ''

          if (
            !!landing[`home_landing_video_${number}`] &&
            landing[`home_landing_video_${number}`] != ''
          ) {
            videoSrc = landing[`home_landing_video_${number}`]
          }

          images.push({
            index: parseInt(number) - 1,
            src: landing[key],
            videoSrc: videoSrc,
            url: landing[`home_landing_url_${number}`],
          })
        }
      }

      return images
    },
    landingCaptionStyle() {
      const scrollTop = this.scrollTop
      const windowHeight = this.resizeHeight

      const transform = ['rotate(-90deg)', 'translate3d(0,0,0)']

      const easing = BezierEasing(0, 0, 1, 0.5)
      const calc = function (min, max, ratio) {
        return (max - min) * ratio + min
      }

      //Ignore if landing off screen
      if (scrollTop > windowHeight) {
        return {
          transform: 1,
        }
      }

      //Fade out and move right as we scroll down
      const exponent = scrollTop / (windowHeight / 1.7)
      const easedExponent = easing(Math.min(exponent, 1))

      const opacity = calc(1, 0, easedExponent)
      const left = calc(0, -40, easedExponent)

      transform.push(`translateX(${left}px)`)

      return {
        transform: transform.join(' '),
        opacity,
      }
    },
  },
  mounted() {
    this.preloadImages()
    this.slideshowInterval()
  },
  methods: {
    slideshowInterval() {
      const limit = this.landingImages.length - 1

      const interval = () => {
        setTimeout(interval, SLIDE_DELAY)

        if (this.loaded - 1 < limit) return

        if (this.index >= limit) {
          this.index = 0
        } else {
          this.index += 1
        }
      }

      setTimeout(interval, SLIDE_DELAY)
    },
    preloadImages() {
      this.landingImages.forEach((img) => {
        const image = new Image()
        image.onload = () => {
          this.loaded += 1
          image.onload = null
        }
        image.src = img.src
      })
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.landing {
  // background:$background2;
  .landing-inner {
    height: 100vh;
    position: relative;
    min-height: 400px;
    a {
      @include cover-link();
      z-index: 10;
    }
  }
  .slide {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;

    &__image {
      background-size: cover;
      background-position: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 2s ease;

      &--active {
        opacity: 1;
      }
    }

    &__video {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__link {
      @include cover-link();
      z-index: 15;
    }
  }
  .caption {
    @include sans();
    color: $dark;
    transform: rotate(-90deg) translate3d(0, 0, 0);
    transform-origin: left top;
    position: absolute;
    left: calcPercentage(25, 1);
    bottom: 40px;
    z-index: 100;
    &.light {
      color: $light;
    }
  }
}
</style>
