<template>
  <div class="page-about">
    <div class="page-about__sidebar">
      <div class="page-about__sidebar__inner">
        <h1 class="page-about__sidebar__heading">About</h1>
        <ul>
          <li
            v-for="(section, k) in sections"
            :key="'about_sidebar_' + k"
            class="page-about__sidebar__link"
            :class="getLinkClass(k)"
          >
            <span @click.prevent="goToRow(k)" v-html="section.name"></span>
          </li>
        </ul>
      </div>
    </div>
    <div class="page-about__sections">
      <div
        v-for="(section, k) in sections"
        :id="'about-section-' + k"
        ref="sections"
        :key="'about_section_' + k"
        class="page-about__section"
        :class="getSectionClass(section)"
      >
        <div class="page-about__image">
          <img :src="section.image" alt="" />
        </div>
        <div class="page-about__details">
          <h2 class="page-about__title">
            <span v-html="section.title1"></span>
            <span v-html="section.title2"></span>
          </h2>
          <div class="page-about__description" v-html="section.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto'
export default {
  props: {
    loaded: {
      type: Boolean,
    },
    sections: {
      type: Array,
    },
  },
  data() {
    return {
      intersectedKey: 0,
      observers: null,
    }
  },
  mounted() {
    const { sections } = this.$refs
    this.observers = sections.map((v, k) => this.startObserver(v, k))
  },
  unmounted() {
    this.observers.forEach((v) => v.disconnect())
  },
  methods: {
    goToRow(key) {
      VueScrollTo.scrollTo(`#about-section-${key}`, 500, {
        offset: -120,
      })
    },
    getSectionClass(section) {
      return `page-about__section--${section.style}`
    },
    getLinkClass(k) {
      if (k == this.intersectedKey) return 'page-about__sidebar__link--active'
      return false
    },
    startObserver(section, key) {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0]
          if (entry.isIntersecting) this.intersectedKey = key
        },
        {
          threshold: 1,
        }
      )
      observer.observe(section)
      return observer
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';
.page-about {
  @include wrapper();
  display: grid;
  --column-count: 24;
  counter-reset: heading;
  grid-template-columns: repeat(var(--column-count), 1fr);
  padding-top: var(--page-top);

  &__sidebar {
    grid-area: 1 / 1 / auto / 4;
    counter-reset: sidebar;

    @include respond-to('m') {
      display: none;
    }
    &__inner {
      position: sticky;
      top: 120px;
      bottom: 0;
      padding-left: $mobile_inset;
    }
    &__heading {
      margin-bottom: 1em;
    }
    &__link {
      padding-left: 25px;
      position: relative;
      cursor: pointer;
      color: $accent;
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        counter-increment: sidebar;
        content: '0' counter(sidebar);
        opacity: 0;
        transition: opacity 0.2s ease-out;
      }
      &--active,
      &:hover {
        color: $dark;
        &:before {
          opacity: 1;
        }
      }
    }
  }
  &__sections {
    @include respond-to('m+') {
      grid-area: 1 / 5 / auto / -3;
      --column-count: 18;
    }
    @include respond-to('m') {
      grid-area: 1 / 1 / auto / -1;
      --column-count: 26;
      margin: 0 $mobile_inset;
    }
  }
  &__section {
    @include clearfix;
    display: grid;
    padding: 0 $gutter;
    grid-gap: $gutter * 2;
    @include respond-to('m+') {
      grid-template-columns: repeat(var(--column-count), 1fr);
      + .page-about__section {
        margin-top: 150px;
      }

      &--large-image-left {
        .page-about {
          &__image {
            grid-area: 1 / 1 / auto / span 10;
          }
          &__details {
            grid-area: 1 / 13 / auto / -1;
          }
        }
      }
      &--medium-image-left {
        .page-about {
          &__image {
            grid-area: 1 / 1 / auto / span 8;
          }
          &__details {
            grid-area: 1 / 13 / auto / -1;
          }
        }
      }
      &--small-image-left {
        .page-about {
          &__image {
            grid-area: 1 / 1 / auto / span 6;
          }
          &__details {
            grid-area: 1 / 13 / auto / -1;
          }
        }
      }
      &--large-image-right {
        .page-about {
          &__details {
            grid-area: 1 / 1 / auto / span 6;
          }
          &__image {
            grid-area: 1 / 9 / auto / -1;
          }
        }
      }
      &--small-image-right {
        .page-about {
          &__details {
            grid-area: 1 / 1 / auto / span 6;
          }
          &__image {
            grid-area: 1 / 13 / auto / -1;
          }
        }
      }
    }

    @include respond-to('m') {
      grid-area: 1fr;
      margin: 0 auto;
      max-width: 100%;
      width: 420px;
      .page-about {
        &__title {
          padding-top: 2em;
        }
        &__image {
          order: 2;
          padding-top: 2em;
        }
      }
    }
  }
  &__image {
    img {
      width: 100%;
    }
  }
  &__description {
    @include cx-full();
    strong {
      display: inline-block;
      font-weight: normal;
      @include sans;
      @include cx-uc();
      padding-bottom: 0.5em;
    }
    * + p strong {
      padding-top: 1em;
    }
  }
  &__title {
    @include cx-xl;
    @include sans;
    @include cx-uc;
    padding-top: 100px;
    margin-bottom: 40px;
    span {
      display: block;
      padding-left: 50px;
      position: relative;
      &:nth-child(1) {
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          counter-increment: heading;
          content: counter(heading) '.';
        }
      }
    }
  }
}
</style>
