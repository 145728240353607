<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    this.observer = this.startObserver(this.$el)
    this.$nextTick(() => this.send())
  },
  unmounted() {
    this.observer.disconnect()
  },
  methods: {
    send(entry = false) {
      const rect = entry.contentRect || this.$el.getBoundingClientRect()

      if (rect)
        this.$emit('resize', {
          width: rect.width,
          height: rect.height,
        })
    },
    startObserver(section, key) {
      const observer = new ResizeObserver((entries) => {
        const entry = entries[0]
        const rect = entry.contentRect

        this.send(entry)
      })

      observer.observe(section)
      return observer
    },
  },
}
</script>
