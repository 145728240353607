<template>
  <FormWrap
    class="form-forgot"
    title="forgot Password"
    :error="error"
    :submit="forgot"
    action="/account/recover"
    id="vue_recover_customer_password"
  >
    <div class="form-forgot__label">We will send you an email to reset your password.</div>
    <InputEmail
      v-model="email"
      class="form-forgot__input form-forgot__input--email"
      name="email"
      placeholder="Your Email Address"
      required="true"
    />
    <InputButton class="form-forgot__button" title="forgot Password" />
  </FormWrap>
</template>

<script>
import Vuex from 'vuex'
import InputEmail from '../input/InputEmail.vue'
import InputPassword from '../input/InputPassword.vue'
import InputButton from '../input/InputButton.vue'
import FormWrap from './FormWrap.vue'

export default {
  computed: Vuex.mapState({
    user: (state) => state.account.user,
  }),
  components: { InputEmail, InputPassword, InputButton, FormWrap },
  data() {
    return {
      error: '',
      email: '',
      password: '',
    }
  },
  methods: {
    async forgot(event) {
      this.error = ''
      try {
        const data = Object.fromEntries(new FormData(event.target))
        const submitData = {
          email: data.email,
        }

        const success = await this.$store.dispatch('account/forgot', submitData)
        if (success) {
          alert('Please check your email to finish resetting your password')
          window.location = '/account/login'
        }
      } catch (e) {
        console.log(e)
        if (e.code == 'ERR_NETWORK') window.location = '/account/login'
        this.error = 'There was an error, please check your details and try again'
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';
.form-forgot {
  &__label {
    margin-bottom: 20px;
  }

  &__input {
    margin-bottom: 20px;
  }
}
</style>
