<template>
  <div class="collection-password">
    <div class="collection-password__heading">
      <h1>Private Collection</h1>
    </div>
    <div class="collection-password__description">
      <p>Please enter your email address to access this page</p>
    </div>
    <CollectionPasswordForm class="collection-password__form" />
  </div>
</template>

<script>
import CollectionPasswordForm from '../form/FormCollection.vue'

export default {
  components: {
    CollectionPasswordForm,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.collection-password {
  @include offset(4);
  @include offset(1, right);
  &__heading {
    @include cx-l();
    @include cx-uc();
    @include sans();
    // text-align: center;
    padding-bottom: 40px;
  }

  &__description {
    padding-bottom: 40px;
  }
}
</style>
