import imageUrlBuilder from '@sanity/image-url'
import { SanityClient } from './client'

const builder = imageUrlBuilder(SanityClient)

const SanityImage = (ref) => builder.image(ref)
const SanityImageUrl = (asset, ...dimensions) =>
  SanityImage(asset)
    .size(...dimensions)
    .quality(75)
    .format('jpg')
    .url()

export { SanityImageUrl, SanityImage }
