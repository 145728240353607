<template>
  <SanityContentTemplate class="article-content" :content="content" />
</template>

<script>
import SanityContentTemplate from '../sanity/SanityContentTemplate'

export default {
  components: { SanityContentTemplate },
  props: {
    content: String,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.article-content {
  @include wrapper;

  > * {
    @include clearfix();
    + * {
      padding-top: 120px;
      @include respond-to(s) {
        padding-top: 40px;
      }
    }

    .row-inner {
      @include offset(1);
      @include offset(1, right);
      @include respond-to(s) {
        @include insets();
      }
    }
  }

  > .sanity-content-share {
    display: flex;
    /* text-align: center; */
    .global-share__inner {
      margin-left: columns(3);
    }
  }

  > .sanity-content-image {
    margin-left: columns(4);
    margin-right: columns(4);

    .sanity-content-image__image {
      padding: 0 var(--gutter);
      width: 100%;
      padding-bottom: 10px;
    }

    @include respond-to(xs) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  > .sanity-content-columns {
    display: grid;
    grid-gap: 40px var(--gutter);
    grid-template-columns: columns(2) columns(7) columns(3) columns(6) columns(6);

    @include respond-to(l) {
      grid-template-columns: columns(2) columns(7) columns(3) columns(8) columns(4);
    }

    @include respond-to(xs) {
      grid-template-columns: 1fr;
    }

    .sanity-content-image {
      grid-column: 2 / 3;

      .sanity-content-columns__caption {
        padding-top: 10px;
      }

      @include respond-to(xs) {
        grid-column: 1 / -1;
      }
    }

    .sanity-content-blocks {
      @include cx-rows;
      grid-column: 4 / 5;

      @include respond-to(xs) {
        grid-column: 1 / -1;
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
      }

      /* blockquote {
        @include cx-l();
        @include offsetX(6, -1);
        @include offsetX(6, -1, right);
        padding-top: 40px;
        padding-bottom: 60px;
        @include respond-to(l) {
          @include offsetsX(22, -1);
        }
        @include respond-to(xs) {
          @include insets(0);
        }
      } */
    }

    &--text-image,
    &--image-text {
      .sanity-content-image {
        @include respond-to(xs) {
          padding-top: 40px;
        }
      }
    }

    &--image-image {
      padding: 0 calc(1 * var(--gutter) + 2 * var(--column));
      margin-top: 120px;

      display: grid;
      grid-gap: 40px calc(2 * var(--gutter) + 3 * var(--column));
      grid-template-columns: 1fr 1fr;

      @include respond-to(l) {
        grid-gap: 24px calc(1 * var(--gutter) + 2 * var(--column));
      }

      @include respond-to(s) {
        margin-top: 64px;
        grid-template-columns: 1fr;
        padding: 0 0;
      }

      p {
        margin-top: 10px;
      }

      &::before {
        content: none;
      }

      .sanity-content-image {
        /* @include spanX(22, 8);
        @include offsetX(22, 1);
        @include offsetX(22, 2, right); */

        grid-column: auto;

        &__image + &__caption {
          padding-top: 10px;
        }

        @include respond-to(s) {
          @include unwrap();
          padding-bottom: 40px;
          margin: 0;
        }
      }
    }
  }
}
</style>
