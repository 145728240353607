<template>
  <div class="global-slideshow">
    <Swiper
      ref="slideshow"
      :pagination="pagination"
      :modules="[Pagination]"
      :options="swiperOptions"
      :auto-update="false"
      :delete-instance-on-destroy="false"
      :cleanup-styles-on-destroy="false"
      class="global-slideshow__swiper"
    >
      <SwiperSlide v-for="(slide, k) in slides" :key="`global_slideshow_${k}`">
        <component :is="slideComponent" class="global-slideshow__slide" :index="k" :slide="slide" />
      </SwiperSlide>
    </Swiper>
    <div class="global-slideshow__controls">
      <div class="global-slideshow__pagination"></div>
    </div>
  </div>
</template>

<script>
import 'swiper/css'
import 'swiper/css/pagination'

import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper/modules'
import BrowserMixin from '~/mixins/browser'

import GlobalSlideProduct from './GlobalSlideProduct.vue'
import GlobalSlideCommunity from './GlobalSlideCommunity.vue'
import GlobalSlideImage from './GlobalSlideImage.vue'

export default {
  directives: {
    // swiper: directive,
  },
  components: {
    Swiper,
    SwiperSlide,
    GlobalSlideProduct,
    GlobalSlideCommunity,
    GlobalSlideImage,
  },
  mixins: [BrowserMixin],
  props: {
    slideComponent: String,
    slides: Array,
    options: Object,
  },
  data() {
    return {
      ready: false,
      slideshowTransition: 0,
      slideshowTranslate: 0,
      dragging: false,
      slideSize: 0,
      Pagination,
    }
  },
  computed: {
    isTablet() {
      return window.innerWidth < 820
    },
    swiperOptions() {
      return {
        ...this.options,
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
      }
    },
    pagination() {
      return {
        el: '.global-slideshow__pagination',
        type: 'bullets',
        clickable: true,
      }
    },
    swiper() {
      return this.$refs.slideshow.$swiper
    },
  },
  methods: {
    next() {
      this.swiper.slideNext()
    },
    prev() {
      this.swiper.slidePrev()
    },
  },
}
</script>

<style lang="scss">
/* @import 'swiper/css/swiper.css'; */
@import '~styles/base.scss';

.global-slideshow {
  position: relative;

  &__swiper {
    height: 100%;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    width: 100%;
    /* width: columns(5); */
    /* margin-left: columns(1.5); */
  }

  &__slide {
    padding: 0 var(--gutter) 0;
  }

  &__controls {
    display: flex;
    margin-top: 20px;
  }

  &__pagination {
    margin: 0 auto;
    display: flex;
    gap: 5px;
    width: auto !important;

    --swiper-pagination-color: var(--foreground);
  }

  &__control {
    display: inline-flex;
    /* margin-left: auto; */
    cursor: pointer;
    padding: 10px;

    svg {
      width: 30px;
    }
  }
}
</style>
