<template>
  <div class="page-blog">
    <GlobalSidebar class="page-blog__sidebar" title="Categories" :links="getLinks" />

    <ArticleIndex v-if="blog.handle == 'journal'" class="page-blog__index" :articles="blog.articles" />

    <!-- <LookbookIndex
      v-if="blog.handle == 'lookbooks'"
      class="page-blog__index"
      :articles="blog.articles"
    /> -->
  </div>
</template>

<script>
import GlobalSidebar from '../components/global/GlobalSidebar.vue'
import ArticleIndex from '../components/article/ArticleIndex.vue'
import LookbookIndex from '../components/lookbook/LookbookIndex.vue'

export default {
  components: {
    GlobalSidebar,
    ArticleIndex,
    LookbookIndex,
  },
  props: {
    blog: Object,
  },
  computed: {
    getLinks() {
      if (this.blog.handle == 'journal') return 'sidebar_journal'
      return 'sidebar-lookbooks'
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.page-blog {
  @include wrapper;
  display: grid;
  padding-top: var(--page-top);
  grid-template-columns: 4fr 19fr 1fr;

  @include respond-to(l) {
    grid-template-columns: 180px 1fr 20px;
  }

  @include respond-to(s) {
    grid-template-columns: 1fr;
    &__sidebar {
      display: none;
    }

    &__index {
      grid-column: 1 / -1;
    }
  }
}
</style>
