<template>
  <div class="product-colours" v-if="getColours.length > 0">
    <ProductOptionSelect title="Color Select" :active="`${product.id}`" :options="getOptions" />
  </div>
</template>

<script>
import ProductOptionSelect from './ProductOptionSelect.vue'

export default {
  props: {
    product: Object,
  },
  computed: {
    getColours() {
      return this.product.metafields?.colours || []
    },
    getOptions() {
      return this.getColours.map((product) => {
        return {
          title: this.getColorText(product.title),
          value: `${product.id}`,
          action: () => (window.location = `/products/${product.handle}`),
          available: true,
        }
      })
    },
    getColorText() {
      return (title) => {
        const splits = title.split(',')
        if (splits.length == 1) return ''
        const color = splits[splits.length - 1].trim()
        return color
      }
    },
  },
  methods: {},
  components: { ProductOptionSelect },
}
</script>

<style lang="scss"></style>
