import Link from '~/components/sanity/block/SanityBlockLink'
import Image from '~/components/sanity/block/SanityBlockImage'
import Video from '~/components/sanity/block/SanityBlockVideo'
import Columns from '~/components/sanity/block/SanityBlockColumns'

export const typesImage = Image
export const typesVideo = Video
export const typesColumns = Columns

export const marksLink = Link

export const stylesNormal = {
  functional: true,
  render(h, { children }) {
    return h('p', { class: 'sanity-content-paragraph' }, children)
  },
}

export const stylesH2 = {
  functional: true,
  render(h, { children }) {
    return h('h2', { class: 'sanity-content-heading' }, children)
  },
}

export const stylesH3 = {
  functional: true,
  render(h, { children }) {
    return h('h3', { class: 'sanity-content-subheading' }, children)
  },
}

export const stylesBlockquote = {
  functional: true,
  render(h, { children }) {
    return h('blockquote', { class: 'sanity-content-blockquote' }, children)
  },
}

export const defaultTypes = [typesImage, typesVideo]
export const defaultMarks = { link: marksLink }
export const defaultStyles = {
  normal: stylesNormal,
  h2: stylesH2,
  h3: stylesH3,
}
