<template>
  <div class="lookbook-index">
    <section class="lookbooks">
      <div class="lookbooks-inner">
        <GlobalReveal>
          <h1>Lookbooks</h1>
        </GlobalReveal>

        <LookbookCards :lookbooks="articles" />
      </div>
    </section>

    <GlobalPagination />
  </div>
</template>

<script>
import GlobalPagination from '../global/GlobalPagination.vue'
import GlobalReveal from '../global/GlobalReveal.vue'
import GlobalSidebar from '../global/GlobalSidebar.vue'
import LookbookCards from './LookbookCards.vue'

export default {
  components: {
    GlobalPagination,
    GlobalReveal,
    GlobalSidebar,
    LookbookCards,
  },
  props: {
    articles: Array,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.lookbook-index {
  section.lookbooks {
    @include wrapper();
    @include clearfix();
    .lookbooks-inner {
      @include offset(4);
      @include offset(4, right);
      padding: 0 $gutter;
    }

    h1 {
      @include serif();
      @include cx-xxl();
      padding-bottom: 60px;
    }

    .blocks.blocks-lookbooks {
      .block {
        padding-bottom: 100px;
        .block-inner {
          position: relative;
          display: block;
          width: 100%;
          /* @include helper-slideshow-lookbook(); */
          .details {
            @include offsetX(16, 5);
            @include offsetX(16, 5, right);
            padding: 30px $gutter 0;
            position: relative;
            .link {
              @include cover-link();
              z-index: 10;
            }
            .title {
              @include cx-xs();
              font-style: italic;
              color: $accent;
              padding-bottom: 5px;
              text-align: center;
            }
            .excerpt {
              h3 {
                @include cx-s();
                @include cx-uc();
                @include sans();
                padding-bottom: 20px;
                text-align: center;
              }
              p {
                @include cx-xxs();
              }
              .a {
                @include helper-underline();
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }

  @include respond-to(m) {
    section.lookbooks {
      .lookbooks-inner {
        margin: 0 $mobile_inset;
        padding: 0;
        .blocks {
          .block {
            .block-inner {
              .details {
                @include mobileWrap;
                padding-top: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
