<template>
  <OverlayWrap class="overlay-account" :title="`Account`" :is-active="isActive">
    <div class="overlay-account__sections">
      <div class="overlay-account__section">
        <FormLogin v-if="mode == 'login'" class="overlay-account__form" />
        <FormForgot v-if="mode == 'reset'" class="overlay-account__form" />
        <FormRegister v-if="mode == 'register'" class="overlay-account__form" />

        <button v-if="mode == 'login'" class="overlay-account__switch" @click="mode = 'reset'">
          Forgot password?
        </button>

        <button
          v-if="mode == 'reset' || mode == 'register'"
          class="overlay-account__switch"
          @click="mode = 'login'"
        >
          Cancel
        </button>
      </div>

      <FormWrap class="overlay-account__section" title="New User">
        <template #note>
          <div class="overlay-account__content">
            By creating an account with our store, you will be able to move through the checkout process
            faster, store multiple shipping addresses, view and track your orders in your account and
            more.
          </div>
          <button class="input-button" @click="mode = 'register'">CREATE ACCOUNT</button>
        </template>
      </FormWrap>
    </div>
  </OverlayWrap>
</template>

<script>
import SvgClose from '../svg/SvgClose.vue'
import FormLogin from '../form/FormLogin.vue'
import FormWrap from '../form/FormWrap.vue'
import OverlayWrap from './OverlayWrap.vue'
import FormForgot from '../form/FormForgot.vue'
import FormRegister from '../form/FormRegister.vue'
export default {
  components: {
    SvgClose,
    FormLogin,
    FormWrap,
    OverlayWrap,
    FormForgot,
    FormRegister,
  },
  data() {
    return {
      mode: 'login',
    }
  },
  computed: {
    isActive() {
      return this.$store.state.overlay.current == 'account'
    },
  },
  mounted() {
    const hash = window.location.hash

    if (hash == '#forgot') this.mode = 'forgot'
    if (hash == '#register') this.mode = 'register'
    if (hash == '#reset') this.mode = 'reset'
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

$login_width: 450px;
$login_inset: 20px;

.overlay-account {
  &--active {
    right: $mobile_inset;
  }

  &__sections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    padding: 0 var(--inset);
    gap: 0 40px;
    margin: 20px 0;

    &:before {
      position: absolute;
      content: ' ';
      width: 1px;
      top: 0;
      bottom: 0;
      left: 50%;
      overflow: hidden;
      border-left: 1px solid var(--foreground);
    }
  }

  &__content {
    margin-bottom: 20px;
  }

  &__switch {
    @include helper-underline;
    margin-top: 10px;
  }

  .input-button {
    width: 100%;
  }
}
</style>
