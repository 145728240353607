<template>
  <form action="/search">
    <button v-if="!isSearchActive" class="a" @click.prevent="toggleSearch(true)">Search</button>

    <div v-else>
      <input type="hidden" name="type" value="product" />
      <input
        id="search"
        type="text"
        name="q"
        placeholder="Search"
        :value="shopify.searchTerms || ''"
        required
      />
      <button class="submit"><SvgArrow /></button>
    </div>
  </form>
</template>

<script>
import Vuex from 'vuex'
import SvgArrow from '~/components/svg/SvgArrow.vue'

export default {
  name: 'GlobalLink',
  props: {
    link: Object,
  },
  data() {
    return {
      isSearchActive: false,
    }
  },
  mounted() {
    if (this.shopify.searchTerms) this.isSearchActive = true
  },
  methods: {
    toggleSearch(set = -1) {
      // TODO: Focus search on select
      // TODO: Search button color
      if (set == -1) {
        this.isSearchActive = !this.isSearchActive
      }
      {
        this.isSearchActive = set
      }
    },
  },
  computed: {
    getItemCount() {
      return this.$store.getters['cart/getItemCount']
    },
    ...Vuex.mapState({
      shopify: (state) => state.shopify,
    }),
  },
  components: {
    SvgArrow,
  },
}
</script>

<style lang="scss"></style>
