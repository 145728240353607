<template>
  <ProductCard :product="slide" v-if="slide.title" />
</template>

<script>
import ProductCard from '~/components/product/ProductCard.vue'

export default {
  props: {
    slide: Object,
  },
  components: {
    ProductCard,
  },
}
</script>

<style lang="scss"></style>
