<template>
  <transition name="fade">
    <div v-if="overlay == 'gallery'" class="overlay-gallery">
      <div class="overlay-gallery__close" @click="$store.commit('overlay/open', '')">
        <SvgClose />
      </div>

      <div class="overlay-gallery__images">
        <ShopifyImage
          v-for="(image, index) in images"
          :key="`overlay__image--${index}`"
          class="overlay-gallery__image"
          :src="image.src"
          :alt="image.alt"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import SvgClose from '../svg/SvgClose.vue'
import ShopifyImage from '../shopify/ShopifyImage.vue'

export default {
  components: { SvgClose, ShopifyImage },
  props: {
    images: Array,
  },
  computed: {
    overlay() {
      return this.$store.state.overlay.current
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-gallery {
  position: fixed;
  left: 0;
  right: 0;
  width: 100vw;
  top: 0;
  height: 100%;
  background: rgba($background, 1);
  z-index: 1010;
  overflow-y: auto;

  &__close {
    position: fixed;
    right: 30px;
    top: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__images {
    @include wrapper;
    padding: 200px columns(4);
  }

  &__image {
    width: 100%;
    + .overlay-gallery__image {
      padding-top: 20px;
    }
  }

  @include respond-to(s) {
    &__images {
      padding: 80px 0;
    }
  }
}
</style>
