<template>
  <div class="overlay-mobile-submenu">
    <h3 v-if="title" class="overlay-mobile-submenu__heading">
      <a :href="url">{{ title }}</a>
    </h3>
    <GlobalLinks v-if="getMenu" class="overlay-mobile-submenu__links" :links="getMenu" />
  </div>
</template>

<script>
import GlobalLinks from '../global/GlobalLinks.vue'

export default {
  components: {
    GlobalLinks,
  },
  props: {
    title: String,
    url: String,
    menu: String,
  },
  computed: {
    getMenu() {
      return this.$store.state.shopify.menus[this.menu]
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-mobile-submenu {
  &__heading {
    /* @include sans; */
    @include cx-uc;
    margin-bottom: 5px;
  }

  &__links {
    --foreground: var(--color-accent);
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
  }
}
</style>
