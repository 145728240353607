<template>
  <div class="component-instagram" :class="showClass">
    <a class="link" :href="showUrl">
      <div class="image">
        <transition name="fade">
          <div class="feed" :style="showStyle" v-if="hasImage"></div>
        </transition>
        <img :src="showSrc" />
      </div>
    </a>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  props: {
    fallbackImage: String,
  },
  data() {
    return {
      token:
        '71b39fdb427e03092e18176f07c7bb160818a3999fa6847d2788055aa456cf4460284319f14425a2aa102c821cf46467cgKJUpH6Hodv3Xo42JeruEgn5HtzXAeleF3Qo9EuMyN7xT/EtsolfhsONfsQWN+G9XKsAMa2IYn4d3o4/RpcIQ==',
      image: '',
      url: 'https://www.instagram.com/ozma_of_california/',
      ajax: {
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        error: (d) => {
          console.log(d)
        },
      },
    }
  },
  // mounted () {
  //load feed
  // this.getFeed({
  //   cb: (d) => {
  //     if(!d.data) return;
  //     var imageData = _.find(d.data, {type: 'image'});

  //     if(imageData) {
  //       var standard_res = imageData.images.low_resolution;

  //       this.image = standard_res;
  //       this.url   = imageData.link;
  //     }
  //   }
  // });
  // },
  computed: {
    // getFeed: function(opts) {
    //   var url = 'https://instagram.shopify.pxl.nz/feed';

    //   var settings = _.merge(_.clone(this.ajax), {
    //     url: url,
    //     data: JSON.stringify({ access_token: this.token }),
    //     success: (d, status) => {
    //
    //       if (typeof (opts.cb) == 'function') opts.cb(d);
    //     },
    //   });

    //   //Call url
    //   $.ajax(settings);
    // },
    resize(url, format = '400x400_crop') {
      if (!url) return ''
      const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
      return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
    },
    showStyle() {
      return this.image ? { 'background-image': `url('${this.image.url}')` } : {}
    },
    showClass() {
      return this.image ? ['has-instagram'] : []
    },
    hasImage() {
      return !!this.image
    },
    showUrl() {
      return this.url
    },
    showSrc() {
      return this.fallbackImage
    },
  },
}
</script>

<style lang="scss"></style>
