<template>
  <form class="global-link-searchmobile" action="/search">
    <input class="global-link-searchmobile__hidden" type="hidden" name="type" value="product" />
    <input
      class="global-link-searchmobile__input"
      type="text"
      name="q"
      placeholder="Search"
      :value="shopify.searchTerms"
      required
    />
    <button><SvgArrow class="global-link-searchmobile__arrow" /></button>
  </form>
</template>

<script>
import Vuex from 'vuex'
import SvgArrow from '~/components/svg/SvgArrow.vue'

export default {
  name: 'GlobalLink',
  props: {
    link: Object,
  },
  data() {
    return {
      isSearchActive: false,
    }
  },
  mounted() {
    if (this.shopify.searchTerms) this.isSearchActive = true
  },
  methods: {
    toggleSearch(set = -1) {
      // TODO: Focus search on select
      // TODO: Search button color
      if (set == -1) {
        this.isSearchActive = !this.isSearchActive
      }
      {
        this.isSearchActive = set
      }
    },
  },
  computed: {
    ...Vuex.mapState({
      shopify: (state) => state.shopify,
    }),
  },
  components: {
    SvgArrow,
  },
}
</script>

<style lang="scss">
.global-link-searchmobile {
  display: flex;

  &__hidden {
    display: none;
  }

  &__input {
    flex: 1;
  }

  &__arrow {
    width: 10px;
  }
}
</style>
