export async function preloadImage(
  src: string
): Promise<{ src: string; width: number; height: number }> {
  const img = new Image()
  img.src = src
  return new Promise((resolve, reject) => {
    img.addEventListener('load', function () {
      resolve({ src, width: img.width, height: img.height })
    })
    img.addEventListener('error', function (error: Event) {
      reject(error)
    })
  })
}

export async function preloadImages(
  srcs: string[]
): Promise<Array<{ src: string; width: number; height: number }>> {
  const promises = srcs.map(async (src) => await preloadImage(src))
  return await Promise.all(promises)
}
