/* eslint-disable @typescript-eslint/no-explicit-any */
// import _ from 'lodash'
import { axiosGet, axiosForm, formatParams } from './client'

interface Address {
  id?: string
  first_name?: string
  last_name?: string
  company?: string
  address1?: string
  address2?: string
  city?: string
  province?: string
  country?: string
  zip?: string
  phone?: string
  name?: string
  province_code?: string
  country_code?: string
  country_name?: string
  default?: boolean
}

interface Order {
  id: number
  name: string
  email: string
  created_at: string
  total_price: string
  financial_status: string
  fulfillment_status: string | null
  currency: string
  // Add more properties as needed
}

type AddressInput = Partial<Address>

export async function getOrders(): Promise<Order[]> {
  const { orders } = await axiosGet(`/account`)
  return orders as Order[]
}

export async function getAddresses(): Promise<Address[]> {
  const { addresses } = await axiosGet(`/account/addresses`)
  return addresses as Address[]
}

export async function submitLogin(input: Record<string, unknown>): Promise<any> {
  const params = formatParams(input, 'customer_login')
  return axiosForm(`/account/login`, params, 'customer_login')
}

export async function submitForgot(input: Record<string, unknown>): Promise<any> {
  const params = formatParams(input, 'recover_customer_password')
  return axiosForm(`/account/recover`, params, 'recover_customer_password')
}

export async function submitReset(input: Record<string, unknown>): Promise<any> {
  const params = formatParams(input, 'reset_customer_password')
  return axiosForm(`/account/reset`, params, 'reset_customer_password')
}

export async function submitActivate(input: Record<string, unknown>): Promise<any> {
  const params = formatParams(input, 'activate_customer_password')
  return axiosForm(`/account/activate`, params, 'activate_customer_password')
}

export async function submitRegister(input: Record<string, unknown>): Promise<any> {
  const params = formatParams(input, 'create_customer')
  return axiosForm(`/account`, params, 'create_customer')
}

export async function submitAddressDelete(input: { id: string }): Promise<void> {
  const params = formatParams(input, 'customer_address')
  params._method = 'delete'
  return axiosForm(`/account/addresses/${input.id}`, params, 'customer_address')
}

export async function submitAddressCreate(input: AddressInput): Promise<void> {
  return submitAddress(input)
}

export async function submitAddressUpdate(input: AddressInput): Promise<void> {
  return submitAddress(input)
}

export async function submitAddress(address: AddressInput): Promise<void> {
  const url = address.id ? `/account/addresses/${address.id}` : '/account/addresses'
  const _method = address.id ? 'put' : false
  const params = formatParams(address, 'customer_address', _method)
  return axiosForm(url, params, 'customer_address')
}
