<template>
  <ObserveIntersection @intersect="onIntersect">
    <slot></slot>
  </ObserveIntersection>
</template>

<script>
import ObserveIntersection from '../observe/ObserveIntersection.vue'
export default {
  components: { ObserveIntersection },
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'background2',
    },
  },
  data() {
    return {
      active: false,
    }
  },
  methods: {
    onIntersect(isIntersecting) {
      if (!this.$el) return
      if (!this.enabled) return

      if (isIntersecting != this.active) {
        this.$store.commit('layout/setBackgroundColor', isIntersecting ? this.color : false)
        this.active = isIntersecting
      }
    },
  },
}
</script>

<style lang="scss"></style>
