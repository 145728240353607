<template>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L7 7" stroke="#A29389" />
    <path d="M7 1L1 7" stroke="#A29389" />
  </svg>
</template>

<script></script>

<style lang="scss"></style>
