<template>
  <button class="input-button" :class="{ disabled }" @click="$emit('input', value)">
    {{ title }}
  </button>
</template>

<script>
export default {
  props: ['title', 'value', 'disabled'],
}
</script>

<style lang="scss">
@import '~styles/base.scss';
@import '~styles/frontend/helper-forms.scss';

.input-button {
  @include appearance-none;
  @include sans;
  @include cx-m;
  @include cx-uc;
  width: auto;
  padding: 10px 25px;
  color: $dark;
  // background:$light;
  display: inline-block;
  width: auto;
  border: 1px solid $dark;
  text-align: center;
  transition: all 0.2s ease-out;

  &:hover,
  &:active {
    text-decoration: none;
    background: transparentize($accent, 0.8);
    color: $dark;
    border-color: $dark;
  }
  &:active {
    background: $lightgrey;
  }
}
</style>
