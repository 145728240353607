import { axiosGet } from './client'

interface GetPageParams {
  tag?: string;
  handle: string;
  page?: number;
}

export async function getPage<T>({ tag, handle, page = 1 }: GetPageParams): Promise<T> {
  if (tag) return axiosGet<T>(`/collections/${handle}/${tag}?page=${page}&view=api`);
  return axiosGet<T>(`/collections/${handle}?page=${page}&view=api`);
}
