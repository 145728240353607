<template>
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="20.4243"
      y1="1.42426"
      x2="0.625273"
      y2="21.2233"
      stroke="var(--foreground)"
      stroke-width="1.2"
    />
    <line
      x1="0.624276"
      y1="1.37652"
      x2="20.4233"
      y2="21.1755"
      stroke="var(--foreground)"
      stroke-width="1.2"
    />
  </svg>
</template>

<script></script>

<style lang="scss"></style>
