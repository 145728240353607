<template>
  <transition name="fade">
    <div class="overlay overlay-newsletter" :class="{ active: active }">
      <div class="newsletter-inner">
        <div class="close" @click="close">
          <SvgClose />
        </div>

        <div class="heading">
          <h2 v-if="mode == 'newsletter'">{{ newsletterTitle }}</h2>
          <h2 v-if="mode == 'mobile'">{{ mobileTitle }}</h2>
        </div>

        <div v-if="mode == 'newsletter'" class="inner">
          <div class="join">
            {{ newsletterHeader }}
          </div>

          <div class="image">
            <img :src="newsletterImage" alt="" />
          </div>

          <div class="discount">
            {{ newsletterFooter }}
          </div>
        </div>

        <Newsletter v-if="mode == 'newsletter'" @submitted="mode = 'mobile'">
          <div class="form-text">{{ newsletterCta }}</div>
        </Newsletter>

        <Mobile v-if="mode == 'mobile'" @submitted="active = false">
          <div class="form-text">{{ mobileCta }}</div>
        </Mobile>
      </div>
    </div>
  </transition>
</template>

<script>
import Newsletter from '~/components/global/GlobalNewsletter.vue'
import Mobile from '~/components/global/GlobalMobile.vue'
import SvgClose from '../svg/SvgClose.vue'

export default {
  components: {
    Newsletter,
    Mobile,
    SvgClose,
  },
  data() {
    return {
      active: false,
      mode: 'newsletter',
    }
  },
  computed: {
    settings() {
      return this.$store.state.shopify.settings
    },
    newsletterTitle() {
      return this.settings.overlay_newsletter_title
    },
    newsletterHeader() {
      return this.settings.overlay_newsletter_header
    },
    newsletterImage() {
      return this.settings.overlay_newsletter_image
    },
    newsletterFooter() {
      return this.settings.overlay_newsletter_footer
    },
    newsletterCta() {
      return this.settings.overlay_newsletter_cta
    },
    mobileTitle() {
      return this.settings.overlay_mobile_title
    },
    mobileHeader() {
      return this.settings.overlay_mobile_header
    },
    mobileCta() {
      return this.settings.overlay_mobile_cta
    },
  },
  mounted() {
    const hideTime = window.localStorage.getItem('newsletter_overlay') || false
    // const oldText = window?.localStorage?.getItem("newsletter_overlay_text");
    // const hasChanged = this.text !== oldText;

    // Don't show if shown in last X time
    if (hideTime) {
      const currentTime = Date.now()
      const days = 1000 * 60 * 60 * 24
      const timeAgo = Math.round((currentTime - parseInt(hideTime)) / days)

      if (timeAgo < 90) return
    }

    setTimeout(() => {
      // Don't show if overlay already set
      if (window._data.logged_in) return

      // window.localStorage.setItem("newsletter_overlay_text", this.text);

      //Finally, show
      this.active = true
    }, 3000)
  },
  methods: {
    close() {
      window.localStorage.setItem('newsletter_overlay', Date.now())
      this.active = false
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay.overlay-newsletter {
  &.active {
    .newsletter-inner {
      right: $mobile_inset;
      opacity: 1;
      pointer-events: auto;

      @media (max-width: 420px) {
        left: $mobile_inset;
        width: auto;
      }
    }
  }

  @include helper-overlay-close;

  .newsletter-inner {
    @include helper-overlay-inner;
    top: auto;
    bottom: 20vh;
    @include cx-m;
    @include sans;
    text-align: center;
    padding: 13px 0 16px;
    z-index: 1100;
  }

  .heading {
    padding-bottom: 13px;
    border-bottom: 1px solid $dark;
    // margin-bottom: 10px;
    @include cx-uc;
  }

  .close {
    top: 14px;

    @media (max-width: 720px) {
      display: block;
    }
  }

  .image {
    width: 80px;
    margin: 0 auto;
    padding: 0 $gutter 0;
  }

  .join,
  .discount,
  .form-text {
    margin: 0 auto;
    width: 100%;
    max-width: 260px;
  }

  .join {
    padding: 13px $gutter 30px;
    @include cx-uc;
  }

  .discount {
    padding: 30px $gutter 20px;
  }

  .form-text {
    padding: 20px $gutter 20px;
  }

  .global-newsletter {
    padding: 0 $mobile_inset 0;
    position: relative;
    border-top: 1px solid $dark;
    @include cx-m;
    margin-top: -1px;
    // margin: 0 auto;
    // max-width: 100%;
    // width: 280px;

    &__input {
      @include sans;
      width: 100%;
      border-bottom: 1px solid $dark;
      padding: 5px 0 3px;
      + input {
        margin-top: 10px;
      }
    }

    &__button {
      @include sans;
      @include form-button;
      width: 100%;
      margin-top: 25px;
    }

    &__message {
      padding-top: 5px;
      color: $midgrey;
    }
  }
}
</style>
