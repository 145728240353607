<template>
  <section v-if="active" ref="grid" class="overlay-grid">
    <div class="overlay-grid__columns">
      <div v-for="(v, k) in getColumns()" :key="`grid_column_${k}`" class="overlay-grid__column"></div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      columns: 0,
      inset: 0,
      gutter: 0,
    }
  },
  mounted() {
    // Persistence
    this.active = localStorage.getItem('showGrid') == 'true'

    // Listen
    window.document.addEventListener('keydown', (e) => this.onKeyDown(e))

    // Get CSS vars
    this.$nextTick(() => {
      const computedStyle = window.getComputedStyle(this.$parent.$el)
      this.columns = parseInt(computedStyle.getPropertyValue('--columns') || 0)
      this.inset = parseInt(computedStyle.getPropertyValue('--inset') || 0)
      this.gutter = parseInt(computedStyle.getPropertyValue('--gutter') / 2 || 0)
    })
  },
  methods: {
    toggleActive() {
      this.active = !this.active
      localStorage.setItem('showGrid', this.active)
    },
    onKeyDown(event) {
      if (
        event.altKey &&
        event.ctrlKey &&
        event.shiftKey &&
        event.keyCode === 71 // 'G'
      )
        this.toggleActive()
    },
    getColumns() {
      const count = this.columns + 1
      return Array(count).fill(count)
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-grid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 1;
  z-index: 100000;

  &__columns {
    position: relative;
    white-space: nowrap;
    /* margin-left: var(--inset); */
    /* width: calc(100vw - (var(--inset) * 2) - var(--scrollbar-inset)); */
    @include wrapper;
  }

  &__column {
    width: calc((1 / var(--columns)) * 100%);
    height: 100vh;
    position: relative;
    display: inline-block;

    &:before,
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.025);
      width: 2px;
      left: -1px;
      z-index: -1;
    }

    &:after {
      width: calc(var(--gutter));
      left: calc(-1 * (var(--gutter) / 2));
      transform: translateX(-1px);
      background: none;
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      border-right: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
