<template>
  <svg
    width="28"
    height="19"
    viewBox="0 0 28 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-hamburger"
  >
    <line x1="28" y1="5.6" x2="-7.34354e-08" y2="5.6" stroke="var(--foreground)" stroke-width="1.2" />
    <line x1="28" y1="13.6" x2="-7.34354e-08" y2="13.6" stroke="var(--foreground)" stroke-width="1.2" />
  </svg>
</template>

<script></script>

<style lang="scss">
.svg-hamburger {
  fill: var(--foreground);
}
</style>
