<template>
  <form class="review-form" @submit.prevent="submit">
    <div class="review-form__fields" v-if="!submitted">
      <div class="review-form__field">
        <label for="review_name" class="review-form__label">Your Name</label>
        <input
          id="review_name"
          v-model="name"
          type="text"
          class="review-form__input review-form__input--text"
          required
        />
      </div>
      <div class="review-form__field">
        <label for="review_email" class="review-form__label">Email Address</label>
        <input
          id="review_email"
          v-model="email"
          type="text"
          class="review-form__input review-form__input--text"
          required
        />
      </div>
      <div class="review-form__field">
        <label for="review_title" class="review-form__label">Review Title</label>
        <input
          id="review_title"
          v-model="title"
          type="text"
          class="review-form__input review-form__input--text"
          required
          placeholder=""
        />
      </div>
      <div class="review-form__field">
        <label for="review_score" class="review-form__label">Score</label>
        <ReviewScore
          id="review_score"
          v-model="score"
          type="number"
          class="review-form__input review-form__input--score"
        />
      </div>
      <div class="review-form__field review-form__field--textarea">
        <label for="review_content" class="review-form__label">Review Content</label>
        <textarea
          id="review_content"
          v-model="content"
          type="text"
          class="review-form__input review-form__input--textarea"
          required
        ></textarea>
      </div>
      <div class="review-form__field review-form__field--submit">
        <button type="text" class="review-form__input review-form__input--button">Submit</button>
      </div>
    </div>
    <div class="review-form__thanks" v-else>Thank you for submitting a review!</div>
  </form>
</template>

<script>
import Vuex from 'vuex'

import ReviewScore from './ReviewScore.vue'

export default {
  props: {},
  data() {
    return {
      name: '',
      email: '',
      title: '',
      content: '',
      score: 3,
      submitted: false,
    }
  },
  computed: {
    ...Vuex.mapState({
      reviews: (state) => state.product.reviews,
    }),
  },
  methods: {
    async submit() {
      await this.$store.dispatch('product/createReview', {
        product: window._data.product,
        review: {
          name: this.name,
          email: this.email,
          title: this.title,
          content: this.content,
          score: this.score,
        },
      })

      this.submitted = true
    },
  },
  components: {
    ReviewScore,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.review-form {
  // border-top: 1px solid $dark;
  // padding: 1em 0;

  &__fields {
    display: grid;
    grid-template-columns: 6fr 7fr;
    grid-gap: 1em #{$gutter * 2};
  }

  &__field {
    &--textarea {
      grid-column: auto / span 2;
    }
  }

  &__label {
    display: block;
    margin-bottom: 0.5em;
  }

  &__input {
    width: 100%;
    border: 1px solid $dark;
    display: block;

    &--button {
      width: auto;
      @include form-button;
    }

    &--text {
      padding: 5px;
    }

    &--textarea {
      padding: 5px;
      @include serif;
      min-height: 6em;
    }

    &--score {
      border: none;
    }
  }
}
</style>
