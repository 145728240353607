<template>
  <header
    class="the-header"
    :class="headerClass"
    :style="`transform: translateY(${offset}px)`"
    @onmouseenter="() => onMouse(true)"
    @onmouseleave="() => onMouse(false)"
  >
    <ObserveResize class="the-header__main" @resize="(e) => (height = e.height)">
      <div class="the-header__inner">
        <a href="/" class="the-header__home">
          <SvgLogo class="the-header__logo" />
        </a>

        <nav class="the-header__menu the-header__menu--main">
          <GlobalLinks
            class="the-header__links the-header__links--main"
            :links="shopify.menus['header-menu-v2']"
          />
        </nav>

        <nav class="the-header__menu the-header__menu--account">
          <GlobalLinks
            class="the-header__links the-header__links--account"
            :links="shopify.menus['header-account']"
          />
        </nav>

        <nav class="the-header__mobile">
          <button
            v-if="overlay != 'mobile'"
            class="the-header__mobile-control the-header__mobile-control--cart"
            @click.prevent="$store.commit('overlay/open', 'cart')"
          >
            (<span v-text="getItemCount"></span>)
          </button>

          <button
            class="the-header__mobile-control the-header__mobile-control--hamburger"
            @click.prevent="onHamburgerClick"
          >
            <SvgHamburger v-if="!isHamburgerOpen" class="the-header__hamburger" />
            <SvgHamburgerClose v-if="isHamburgerOpen" class="the-header__hamburger" />
          </button>
        </nav>

        <div class="the-header__background"></div>
      </div>
    </ObserveResize>

    <div class="the-header__submenus">
      <HeaderSubmenus />
    </div>
  </header>
</template>

<script>
import Vuex from 'vuex'
import SvgLogo from './svg/SvgLogo.vue'
import GlobalLinks from './global/GlobalLinks.vue'
import BrowserMixin from '~/mixins/browser'
import SvgHamburger from './svg/SvgHamburger.vue'
import HeaderSubmenus from './header/HeaderSubmenus.vue'
import ObserveResize from './observe/ObserveResize.vue'
import SvgHamburgerClose from './svg/SvgHamburgerClose.vue'

export default {
  components: {
    SvgLogo,
    GlobalLinks,
    SvgHamburger,
    HeaderSubmenus,
    ObserveResize,
    SvgHamburgerClose,
  },
  props: {
    offset: Number,
  },
  computed: {
    ...Vuex.mapState({
      shopify: (state) => state.shopify,
      layout: (state) => state.layout,
      overlay: (state) => state.overlay.current,
      template: (state) => state.shopify.template,
      settings: (state) => state.shopify.settings,
    }),
    headerClass() {
      return this.$store.getters['header/headerDesktopClass']
    },
    getItemCount() {
      return this.$store.getters['cart/getItemCount']
    },
    isHamburgerOpen() {
      if (this.overlay == 'mobile') return true
      if (this.overlay == 'filters') return true
      return false
    },
  },

  watch: {
    scrollTop(v) {
      this.onScroll(v)
    },
    offset(v) {
      this.onMove(v)
    },
    height(v) {
      this.onMove(v)
    },
  },

  data() {
    return {
      height: 38,
    }
  },

  mounted() {
    let hasLanding = false
    if (this.template.startsWith('index')) hasLanding = true

    this.$store.commit('header/setHasLanding', hasLanding)
    this.$store.commit('header/setIsLight', !!this.settings.home_landing_light)
    if (hasLanding) this.$store.commit('header/setOnLanding', true)
  },
  methods: {
    onScroll(top) {
      const headerOnLanding = this.$store.state.header.onLanding
      const onLanding = top < this.resizeHeight - 50
      if (onLanding != headerOnLanding) this.$store.commit('header/setOnLanding', onLanding)
    },
    onMouse(state) {
      this.$store.commit('header/setMouseOver', state)
    },
    onMove(event) {
      this.$emit('offset', this.offset + this.height)
    },
    onHamburgerClick() {
      if (this.overlay != 'filters') this.$store.commit('overlay/toggle', 'mobile')
      else this.$store.commit('overlay/open', '')
    },
  },
  mixins: [BrowserMixin],
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.the-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  pointer-events: none;

  &--light & {
    &__main {
      --foreground: var(--color-light);
    }
  }

  &--background & {
    &__main {
      background: var(--background);
      transition-delay: 0s;
    }
  }

  &__main {
    transition: background-color 0.4s ease;
    background: transparent;
    pointer-events: auto;
  }

  &__inner {
    @include wrapper;
    padding: 20px 0;
    display: flex;

    @include respond-to('m') {
      padding: 4px 0 4px;
      align-items: center;
    }
  }

  &__home {
    display: block;
    @include respond-to('m+') {
      width: columns(4);
    }

    @include respond-to('m') {
      margin-top: auto;
      margin-bottom: auto;
      height: 20px;
    }
  }

  &__logo {
    width: 85px;
    height: 100%;

    @include respond-to('m') {
      width: auto;
      height: 20px;
    }
  }

  &__menu {
    &--account {
      margin-left: auto;
      width: columns(8);
    }

    .global-link {
      &:hover,
      &--active {
        /* @include helper-underline; */
        color: var(--color-lightgrey);
      }
    }

    @include respond-to('m') {
      display: none;
    }
  }

  &__mobile {
    margin-left: auto;
    /* margin-right: 20px; */
    display: flex;
    align-items: flex-start;

    @include respond-to('m+') {
      display: none;
    }
  }

  &__mobile-control {
    height: 30px;
    display: flex;
    align-items: center;
  }

  &__hamburger {
    width: 28px;
    height: 28px;
    margin: auto 0 auto 20px;
  }

  &__links {
    flex-direction: row;
    gap: 0 20px;

    &--account {
      > :first-child {
        margin-right: auto;
      }
    }
  }

  &__submenus {
    .shopify-section {
      display: contents;
    }
  }
}
</style>
