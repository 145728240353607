const formatOptions = (menuName, textList) => {
  const optionNames = textList.split(',').map((v) => v.trim())

  return optionNames.map((optionName) => ({
    menu: menuName,
    value: optionName,
  }))
}

const getSelectedFromUrl = (allFilters) => {
  // Get tag from url (if there is one)
  const sections = window.location.pathname.split('/')
  if (sections.length == 4) {
    const tag = sections[sections.length - 1]
    const option = allFilters.find((filter) => filter.value == tag)
    if (option) return [option]
  }

  return []
}

const allFilters = [
  ...formatOptions('Size', window._data.settings.collection_filters_size),
  ...formatOptions('Type', window._data.settings.collection_filters_type),
]

export const state = () => ({
  filters: allFilters,
  selected: getSelectedFromUrl(allFilters),
})

export const actions = {}

export const mutations = {
  toggleFilter(state, value) {
    const hasFilter = state.selected.find((filter) => filter.value == value)
    if (hasFilter) state.selected = []
    else state.selected = [value]
  },
}

export const getters = {
  getFilters: (state) => (name) => {
    return state.filters.filter((filter) => filter.menu == name)
  },
  isSelected: (state) => (option) => {
    return !!state.selected.find((filter) => filter.value == option.value)
  },
  menuHasSelected: (state) => (menu) => {
    return !!state.selected.find((filter) => filter.menu == menu)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
