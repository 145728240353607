export const RECAPTCHA_SITE_KEY = '6LeHG2ApAAAAAO4rPaDW-qVpPKPOBfjbCpzJB9ey'

export const loadRecaptchaScripts = (): Promise<void[]> => {
  const scripts = [
    {
      src: `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`,
      id: 'recaptcha-enterprise',
    },
    {
      src: 'https://cdn.shopify.com/shopifycloud/storefront-forms-hcaptcha/ce_storefront_forms_captcha_recaptcha.v1.2.0.iife.js',
      id: 'shopify-captcha',
    },
    {
      src: `https://www.recaptcha.net/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}&hl=en`,
      id: 'recaptcha',
    },
  ]

  const loadScript = (src: string, id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (document.getElementById(id)) {
        resolve() // Script already loaded
        return
      }

      const script = document.createElement('script')
      script.src = src
      script.id = id
      script.async = true

      script.onload = () => resolve()
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`))

      document.head.appendChild(script)
      resolve()
    })
  }

  return Promise.all(scripts.map((script) => loadScript(script.src, script.id)))
}

const wait = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms))
export const getRecaptchaToken = async (action: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!window.grecaptcha || !window.grecaptcha.enterprise) {
      reject(new Error('reCAPTCHA not loaded'))
      return
    }
    window.grecaptcha.enterprise.ready(async () => {
      try {
        const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, { action })
        console.log({ action, token })
        await wait(100)
        resolve(token)
      } catch (error) {
        reject(error)
      }
    })
  })
}
