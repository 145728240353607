<template>
  <div class="overlay-mobile-menu">
    <h2 v-if="menu.title" class="overlay-mobile-menu__title">
      <a :href="menu.url">{{ menu.title }}</a>
    </h2>

    <OverlayMobileSubmenu
      v-if="menu.menu1"
      class="overlay-mobile-menu__submenu"
      :title="menu.menu1_title"
      :url="menu.menu1_url"
      :menu="menu.menu1"
    />

    <OverlayMobileSubmenu
      v-if="menu.menu2"
      class="overlay-mobile-menu__submenu"
      :title="menu.menu2_title"
      :url="menu.menu2_url"
      :menu="menu.menu2"
    />

    <OverlayMobileSubmenu
      v-if="menu.menu3"
      class="overlay-mobile-menu__submenu"
      :title="menu.menu3_title"
      :url="menu.menu3_url"
      :menu="menu.menu3"
    />

    <OverlayMobileSubmenu
      v-if="menu.menu4"
      class="overlay-mobile-menu__submenu"
      :title="menu.menu4_title"
      :url="menu.menu4_url"
      :menu="menu.menu4"
    />
  </div>
</template>

<script>
import OverlayMobileSubmenu from './OverlayMobileSubmenu.vue'

export default {
  components: {
    OverlayMobileSubmenu,
  },
  props: {
    menu: Object,
  },
}
</script>

<style lang="scss">
.overlay-mobile-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 0;

  &__title {
    grid-column: 1 / -1;
  }

  &__submenu {
    border-top: var(--frontend);
  }
}
</style>
