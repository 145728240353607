<template>
  <div class="header-submenus">
    <HeaderSubmenu
      v-for="(submenu, index) in submenus"
      :key="`header-submenus--${index}`"
      class="header-submenus__submenu"
      :submenu="submenu"
    />
  </div>
</template>

<script>
import HeaderSubmenu from './HeaderSubmenu.vue'

export default {
  components: { HeaderSubmenu },
  computed: {
    submenus() {
      return this.$store.state.shopify.submenus
    },
  },
}
</script>

<style lang="scss">
.header-submenus {
  display: grid;
  pointer-events: none;
  > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
}
</style>
