import SanityContentBlocks from '~/components/sanity/content/SanityContentBlocks.js'
import SanityContentImage from '~/components/sanity/content/SanityContentImage.vue'
import { h } from 'vue'

export default {
  props: {
    content: Object,
  },
  render(props) {
    const columns = props?.content?.column

    if (!columns) return h('div')

    const getColumn = (column) => {
      console.log(column)
      if (column._type == 'content')
        return h(SanityContentBlocks, {
          class: 'sanity-content-columns__column',
          content: column?.content || [],
        })

      if (column._type == 'imageWithCaption')
        return h(SanityContentImage, {
          class: 'sanity-content-columns__column',
          asset: column.image.asset,
          caption: column.caption,
        })

      return ''
    }

    let rowType = 'default'

    if (columns[0]._type == 'imageWithCaption' && columns[1]._type == 'imageWithCaption') {
      rowType = 'image-image'
    } else if (columns[0]._type == 'content' && columns[1]._type == 'content') {
      rowType = 'text-text'
    } else if (columns[0]._type == 'imageWithCaption' && columns[1]._type == 'content') {
      rowType = 'image-text'
    } else if (columns[0]._type == 'content' && columns[1]._type == 'imageWithCaption') {
      rowType = 'text-image'
    }

    return h(
      'div',
      {
        class: `sanity-content-columns sanity-content-columns--${rowType}`,
      },
      columns.map(getColumn)
    )
  },
}
