<template>
  <FormWrap class="form-reset" title="Reset" :error="error" :submit="reset" action="/account/reset">
    <label class="form-reset__label" for="password">Password</label>
    <InputPassword
      v-model="password"
      class="form-reset__input form-reset__input--password"
      name="customer[password]"
      placeholder="Your Password"
      required="true"
    />
    <label class="form-reset__label" for="password_confirmation">Password Confirmation</label>
    <InputPassword
      v-model="password_confirmation"
      class="form-reset__input form-reset__input--password"
      name="customer[password_confirmation]"
      placeholder="Confirm Password"
      required="true"
    />
    <InputButton class="form-reset__button" title="reset" />
  </FormWrap>
</template>

<script>
import Vuex from 'vuex'
import InputEmail from '../input/InputEmail.vue'
import InputPassword from '../input/InputPassword.vue'
import InputButton from '../input/InputButton.vue'
import FormWrap from './FormWrap.vue'

export default {
  components: { InputEmail, InputPassword, InputButton, FormWrap },
  computed: {
    ...Vuex.mapState({
      user: (state) => state.account.user,
    }),
    token: () => {
      const href = window.location.pathname
      return href.split('/').pop()
    },
    id: () => {
      const href = window.location.pathname
      const fragments = href.split('/')
      return fragments[fragments.length - 2]
    },
  },
  data() {
    return {
      error: '',
      password: '',
      password_confirmation: '',
    }
  },
  methods: {
    async reset(event) {
      this.error = ''
      try {
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData)
        const submitData = {
          'customer[password]': data['customer[password]'],
          'customer[password_confirmation]': data['customer[password_confirmation]'],
          id: this.id,
          token: this.token,
        }

        console.log(data, submitData)

        await this.$store.dispatch('account/reset', submitData)

        window.location = '/account'
      } catch (e) {
        console.log(e)
        if (e.code == 'ERR_NETWORK') window.location = '/account/reset'
        this.error = 'Incorrect username or password, please try again'
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.form-reset {
  &__label {
    margin-bottom: 20px;
  }

  &__input {
    margin-bottom: 20px;
  }
}
</style>
