interface LayoutState {
  baseColor: string;
  backgroundColor: boolean;
  accountMode: string;
}

export const state = (): LayoutState => ({
  baseColor: 'background1',
  backgroundColor: false,
  accountMode: 'login',
})

export const actions = {}

export const mutations = {
  setBaseColor(state: LayoutState, value: string): void {
    state.baseColor = value
  },
  setBackgroundColor(state: LayoutState, value: boolean): void {
    state.backgroundColor = value
  },
  setAccountMode(state: LayoutState, value: string): void {
    state.accountMode = value
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}

// Commented code left as-is
