<template>
  <div class="product-options" v-if="getOptionGroups.length > 0">
    <ProductOptionSelect
      v-for="optionGroup in getOptionGroups"
      :title="optionGroup.title"
      :active="optionGroup.active"
      :options="optionGroup.options"
      :key="optionGroup.title"
    />
  </div>
</template>

<script>
import ProductOptionSelect from './ProductOptionSelect.vue'

export default {
  props: {
    product: Object,
  },
  computed: {
    getOptionGroups() {
      return this.$store.getters['product/getOptionGroups']
    },
  },
  components: { ProductOptionSelect },
}
</script>

<style lang="scss"></style>
