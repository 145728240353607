<template>
  <section v-if="products.length > 0" class="related background-alt">
    <div class="heading">
      <h2 class="heading-inner">
        Seen In<br />
        This Post
      </h2>
    </div>
    <div class="collection">
      <div class="blocks blocks-related">
        <!-- {% include 'block-product', data_path: 'products' %} -->
      </div>
    </div>
    <div class="controls">
      <a :href="url" class="button">Shop All</a>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    url: String,
    products: Array,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

section.related {
  @include helper-related-products();
  padding-top: 160px;
  margin-top: 0;
  margin-bottom: 0;
  &:last-child {
    padding-bottom: 0;
  }
  @include respond-to(s) {
    padding-top: 80px;
  }
}
</style>
