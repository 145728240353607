<template>
  <section class="article-index">
    <div class="article-index__inner">
      <GlobalReveal>
        <h1 class="article-index__title">Journal</h1>
      </GlobalReveal>

      <div class="article-index__cards">
        <ArticleCard
          v-for="(article, idx) in articles"
          :key="`ArticleCard_${idx}`"
          :article="article"
        />
      </div>
    </div>
    <GlobalPagination />
  </section>
</template>

<script>
import GlobalPagination from '../global/GlobalPagination.vue'
import GlobalReveal from '../global/GlobalReveal.vue'
import ArticleCard from './ArticleCard.vue'
export default {
  components: { GlobalReveal, GlobalPagination, ArticleCard },
  props: {
    articles: Array,
    pages: Number,
    nextUrl: String,
    previousUrl: String,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.article-index {
  /* @include wrapper(); */
  /* @include clearfix(); */

  &__title {
    @include serif;
    @include cx-xxl;
    padding-bottom: 60px;

    @include respond-to(s) {
      padding-bottom: 60px;
    }
  }

  &__cards {
    display: grid;
    gap: 64px 0;

    @include respond-to('l+') {
      grid-template-columns: 4fr 4fr 4fr 6fr 1fr;
      gap: 240px 0;

      > * {
        &:nth-child(3n + 1) {
          grid-column: 1 / 3;
          align-self: flex-end;
        }
        &:nth-child(3n + 2) {
          grid-column: 4 / 5;
          align-self: flex-end;
        }
        &:nth-child(3n + 3) {
          grid-column: 2 / 4;
        }
      }
    }
  }
}
</style>
