<template>
  <div class="review-summary">
    <div class="review-summary__details">
      <h3 class="review-summary__heading">Reviews</h3>
      <ReviewStars class="review-summary__stars" :score="bottomline.average_score || 5" />
      <p class="review-summary__rating">
        <span v-if="reviews.length > 0">
          Rated {{ bottomline.average_score }} out of 5 stars <br />
          overall by
          {{ bottomline.total_review }}
          customers
        </span>
        <span v-else> No reviews yet. </span>
      </p>
    </div>
    <div class="review-summary__write" v-if="!formActive">
      <h3 class="review-summary__heading">Write a Review</h3>
      <button class="review-summary__button" @click.prevent="formActive = true">Write a Review</button>
    </div>
    <ReviewForm class="review-summary__form" v-if="formActive" />
  </div>
</template>

<script>
import Vuex from 'vuex'
import ReviewStars from './ReviewStars.vue'
import ReviewForm from './ReviewForm.vue'

export default {
  data() {
    return {
      formActive: false,
    }
  },
  computed: {
    ...Vuex.mapState({
      reviews: (state) => state.product.reviews,
      bottomline: (state) => state.product.bottomline,
    }),
  },
  components: {
    ReviewStars,
    ReviewForm,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.review-summary {
  padding: 12px 0;
  display: grid;
  grid-template-columns: 4fr 5fr 8fr;
  grid-gap: 0 #{2 * $gutter};

  @include respond-to('m') {
    grid-template-columns: 160px 1fr 1fr;
  }

  @include respond-to('xxxs') {
    grid-template-columns: 140px 1fr 1fr;
  }

  &__heading {
    text-transform: uppercase;
    @include sans;
    @include cx-l;
    padding-bottom: 1em;
  }

  &__stars {
    @include cx-xl;
    padding-bottom: 1em;
  }

  &__write {
    // grid-column: -2 / -1;
    @include respond-to('m') {
      grid-column: auto / span 2;
    }
  }

  &__form {
    grid-column: auto / span 2;
    margin-bottom: 1em;

    @include respond-to('xxxs') {
      grid-column: 1 / -1;
      margin-top: 2em;
    }
  }

  &__button {
    @include form-button();
    width: 100%;
  }
}
</style>
