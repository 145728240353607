<template>
  <div class="overlay-mobile" :class="`${overlay == 'mobile' ? 'overlay-mobile--active' : ''}`">
    <div class="overlay-mobile__inner">
      <ul class="overlay-mobile__heading">
        <GlobalLink
          class="overlay-mobile__link overlay-mobile__link--account"
          :link="{ url: '#account' }"
        />
        <GlobalLink
          class="overlay-mobile__link overlay-mobile__link--currency"
          :link="{ url: '#currency' }"
        />
        <GlobalLink
          class="overlay-mobile__link overlay-mobile__link--search"
          :link="{ url: '#searchmobile' }"
        />
      </ul>
      <OverlayMobileMenu
        v-for="(menu, index) in mmenu"
        :key="`overlay-mobile--${index}`"
        class="overlay-mobile__submenu"
        :menu="menu"
      />
    </div>
  </div>
</template>

<script>
import OverlayMobileMenu from './OverlayMobileMenu.vue'
import GlobalLink from '../global/GlobalLink.vue'

export default {
  components: {
    OverlayMobileMenu,
    GlobalLink,
  },
  computed: {
    overlay() {
      return this.$store.state.overlay.current
    },
    mmenu() {
      return this.$store.state.shopify.mmenu
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-mobile {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 998;
  background: var(--background);

  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;

  --inset-top: var(--header-bottom);
  --inset-bottom: 40px;

  &--active {
    opacity: 1;
    pointer-events: auto;
  }

  &__inner {
    margin-left: var(--inset);
    margin-right: var(--inset);
    padding-top: calc(var(--inset-top) + 20px);
    overflow-y: auto;
    height: calc(100vh);
  }

  &__heading {
    display: flex;
    gap: 20px;
    padding: 0 20px 20px;
  }

  &__submenu {
    border-top: 1px solid var(--foreground);
    padding: 20px 20px 20px;
  }
}
</style>
