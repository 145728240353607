<template>
  <div class="page-search">
    <GlobalSidebar class="page-search__sidebar" />

    <ProductCards
      v-if="products.length > 0"
      class="page-search__cards"
      :products="products"
      :show-video="true"
    />
    <div v-else class="page-search__empty">No results found for those terms</div>
  </div>
</template>

<script>
import GlobalSidebar from '../components/global/GlobalSidebar.vue'
import ProductCards from '../components/product/ProductCards.vue'

export default {
  components: {
    GlobalSidebar,
    ProductCards,
  },
  props: {
    products: Array,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.page-search {
  @include wrapper;
  padding-top: var(--page-top);

  display: grid;
  grid-template-columns: 4fr 19fr 1fr;

  @include respond-to(l) {
    grid-template-columns: 180px 1fr 20px;
  }

  @include respond-to(s) {
    grid-template-columns: 1fr;
    padding-top: calc(var(--header-bottom) + 20px);
  }

  &__sidebar {
    @include respond-to(s) {
      display: none;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px calc(100% / 17);

    @include respond-to(m) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px 20px;
    }

    @include respond-to(s) {
      grid-gap: 20px var(--inset);
    }
  }

  &__empty {
    @include cx-uc;
    @include sans;
    @include cx-l;
  }
}
</style>
