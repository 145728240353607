import SanityBlocks from '~/components/sanity/SanityBlocks.vue'

export default {
  functional: true,
  render(h, ctx) {
    const { props } = ctx
    const { columns } = props
    // const { metadata } = asset.metadata;

    return h(
      'div',
      {
        class: 'content-columns',
      },
      [
        h(SanityBlocks, {
          class: 'content-column content-column--left',
          props: { blocks: columns[0] },
        }),
        h(SanityBlocks, {
          class: 'content-column content-column--right',
          props: { blocks: columns[1] },
        }),
      ]
    )
  },
}
