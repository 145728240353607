<template>
  <button class="a" @click.prevent="scrollTo({ element: `#${link.url.replace('bandana-', '')}` })">
    {{ link.title }}
  </button>
</template>

<script>
import Vuex from 'vuex'
import { scrollTo } from '~/lib/scroll'

export default {
  name: 'GlobalLink',
  props: {
    link: Object,
  },
  methods: {
    scrollTo(options) {
      scrollTo(options)
    },
  },
}
</script>

<style lang="scss"></style>
