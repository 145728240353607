<template>
  <input
    class="input-email"
    type="email"
    name="email"
    :value="value"
    :required="required"
    :placeholder="placeholder"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
    required: String,
    name: String,
    placeholder: String,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';
@import '~styles/frontend/helper-forms.scss';

.input-email {
  @include cx-m;
  appearance: none;
  border-bottom: 1px solid #000;
  /* background: #fff; */
  padding: 10px 0;
  transform: translateZ(0);
  cursor: pointer;
  width: 100%;

  &:focus {
    outline: none;
  }
}
</style>
