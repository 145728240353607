<template>
  <div class="product-cards">
    <GlobalReveal
      v-for="(product, k) in products || []"
      :class="getClass(product)"
      class="block"
      :key="`products_${k}`"
    >
      <ProductCard :product="product" :show-video="showVideo" v-if="product.title && !isFig(product)" />
      <ProductFig v-else :product="product" />
    </GlobalReveal>
  </div>
</template>

<script>
import ProductCard from './ProductCard.vue'
import ProductFig from './ProductFig.vue'
import GlobalReveal from '../global/GlobalReveal.vue'

export default {
  props: {
    products: Array,
    showVideo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getClass(product) {
      if (!product.type) return ''
      return { 'block-figure': product.type == 'figure' }
    },
    isFig(product) {
      if (!product.type) return false
      return product.type == 'figure'
    },
  },
  components: {
    ProductCard,
    ProductFig,
    GlobalReveal,
  },
}
</script>

<style lang="scss">
.product-cards {
  /* display: contents; */
}
</style>
