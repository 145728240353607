<template>
  <section class="template-collection">
    <GlobalSidebar class="template-collection__sidebar" :links="page.metafields.sidebar_handle" />
    <CollectionPageLanding
      :id="collection.handle"
      class="template-collection__landing"
      :collection="collection"
      :products="products"
      :page="page"
    />
  </section>
</template>

<script>
import CollectionPageLanding from '~/components/collection/CollectionPageLanding.vue'
import GlobalSidebar from '~/components/global/GlobalSidebar.vue'

export default {
  components: {
    CollectionPageLanding,
    GlobalSidebar,
  },
  props: {
    page: Object,
    collection: Object,
    products: Array,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.template-collection {
  padding-top: var(--page-top);

  display: grid;
  grid-template-columns: 4fr 19fr 1fr;

  @include respond-to(l) {
    grid-template-columns: 180px 1fr 20px;
  }

  @include respond-to(s) {
    grid-template-columns: var(--inset) 1fr var(--inset);
  }

  &__sidebar {
    @include respond-to(s) {
      display: none;
    }
  }

  /* @include offset(4);
  @include offset(3, right);

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px calc(100% / 17);

  @include respond-to(xl) {
    @include offset(2, right);
  }

  @include respond-to(l) {
    @include offset(4);
    @include offset(1, right);
  }

  @include respond-to(m) {
    @include offset(1);
    grid-gap: 50px calc(100% / 17);
  }

  @include respond-to(s) {
    grid-template-columns: repeat(2, 1fr);
  } */
}
</style>
