export default {
  computed: {
    shopifyVariant() {
      if (!this.product && !this.variant) return false
      if (this.variant) return this.variant
      return this.product.variants[0]
    },
    variantOnSale() {
      return !!this.variantSalePrice
    },
    variantPrice() {
      if (!this.shopifyVariant) return false

      return parseFloat(this.shopifyVariant?.price / 100 || 0)
    },
    variantSalePrice() {
      if (!this.shopifyVariant) return false

      const { variantPrice } = this
      const productComparePrice = parseFloat(this?.shopifyVariant?.compare_at_price / 100 || 0)
      if (productComparePrice > variantPrice) return parseFloat(productComparePrice)
      return false
    },
  },
}
