<template>
  <div class="page-product">
    <div class="page-product__main">
      <ProductSlideshow class="page-product__slideshow" :product="product" />
      <ProductSidebar class="page-product__sidebar" :product="product" />
      <ProductGallery class="page-product__gallery" :product="product" />
      <ProductDetails class="page-product__details" :product="product" />
    </div>

    <ProductFeed class="page-product__feed" :product="product" />
    <ProductMore class="page-product__more" :product="product" />
    <ProductReviews class="page-product__reviews" :product-id="product.id" />
    <OverlayGallery class="page-product__overlay" :images="product.images" />
  </div>
</template>

<script>
import ProductReviews from '~/components/product/ProductReviews.vue'
import ProductSidebar from '~/components/product/ProductSidebar.vue'
import ProductGallery from '~/components/product/ProductGallery.vue'
import ProductDetails from '~/components/product/ProductDetails.vue'
import ProductMore from '~/components/product/ProductMore.vue'
import ProductFeed from '~/components/product/ProductFeed.vue'
import ProductSlideshow from '~/components/product/ProductSlideshow.vue'
import OverlayGallery from '~/components/overlay/OverlayGallery.vue'

export default {
  components: {
    ProductReviews,
    ProductSidebar,
    ProductGallery,
    ProductDetails,
    ProductMore,
    ProductFeed,
    ProductSlideshow,
    OverlayGallery,
  },
  props: {
    product: Object,
  },
  async mounted() {
    await this.$store.dispatch('product/load', this.product)
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.page-product {
  &__main {
    display: grid;

    @include respond-to('m+') {
      @include wrapper;

      grid-template-columns: 4fr 10fr 2fr 6fr 2fr;
      margin-bottom: 200px;
    }

    @include respond-to('m') {
      margin-top: 80px;
    }
  }

  &__slideshow {
    @include respond-to('m+') {
      display: none;
    }

    @include respond-to('m') {
      @include mobileWrap;
      margin-bottom: 30px;
    }
  }

  &__sidebar,
  &__gallery {
    @include respond-to('m') {
      display: none;
    }
  }

  &__details {
    grid-column: -3 / span 1;
    @include respond-to('m') {
      @include mobileWrap;
      margin-bottom: 30px;
    }
  }

  &__feed {
    margin-bottom: 80px;
  }

  &__more {
    margin-bottom: 200px;

    @include respond-to(m) {
      margin-bottom: 120px;
    }
  }
}
</style>
