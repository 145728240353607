<template>
  <a
    :href="shopify.customerLogoutUrl"
    :class="`link-logout ${shopify.isCustomerLoggedIn ? 'logged-in' : ''}`"
    >Log Out</a
  >
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'GlobalLink',
  props: {
    link: Object,
  },
  computed: {
    ...Vuex.mapState({
      shopify: (state) => state.shopify,
    }),
  },
}
</script>

<style lang="scss"></style>
