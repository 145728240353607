<template>
  <div class="page-error">
    <section class="page-error__heading">
      <h1>{{ page.title }}</h1>
    </section>

    <div class="page-error__content" v-html="page.content"></div>
  </div>
</template>

<script>
export default {
  props: {
    page: Object,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.page-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--page-top);

  &__heading {
    @include sans;
    @include cx-m;
  }

  &__content {
    @include helper-link-underline('false');
  }
}
</style>
