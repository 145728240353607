import { createStore } from 'vuex'

import currency from './currency'
import shopify from './shopify'
import product from './product'
import layout from './layout'
import cart from './cart'
import overlay from './overlay'
import header from './header'
import collection from './collection'
import account from './account'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  strict: debug,
  modules: {
    currency,
    product,
    shopify,
    layout,
    cart,
    overlay,
    header,
    collection,
    account,
  },
  plugins: [],
})
