<template>
  <div class="home-feature1">
    <div class="home-feature1__block home-feature1__block--left">
      <div class="home-feature1__block__image">
        <a :href="row.link1"></a>
        <ShopifyImage :src="row.image1" ratio="5x7"></ShopifyImage>
        <div class="home-feature1__block__image__caption" v-html="row.caption1"></div>
      </div>
      <div class="home-feature1__block__caption">
        <h2 v-html="row.title"></h2>
        <div v-html="row.caption"></div>
        <p>
          <a :href="row.caption_link_url" v-html="row.caption_link"></a>
        </p>
      </div>
    </div>
    <div class="home-feature1__block home-feature1__block--right">
      <div class="home-feature1__block__image">
        <a :href="row.link2"></a>
        <ShopifyImage :src="row.image2" ratio="5x7"></ShopifyImage>
        <div class="home-feature1__block__image__caption" v-html="row.caption2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ShopifyImage from '~/components/shopify/ShopifyImage.vue'

export default {
  props: {
    row: {
      type: Object,
    },
  },
  components: {
    ShopifyImage,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-feature1 {
  counter-reset: captionCount;
  &__block {
    &--left {
      grid-column: 4 / span 6;

      @include respond-to(s) {
        grid-column: 3 / span 8;
      }

      @include respond-to(xs) {
        grid-column: 3 / span 20;
        padding-bottom: 80px;
      }
    }
    &--right {
      grid-column: 15 / span 9;
      @include respond-to(s) {
        grid-column: 13 / span 11;
      }
      @include respond-to(xs) {
        grid-column: 4 / span 20;
      }
    }

    &__caption {
      padding-top: 90px;

      h2 {
        @include sans;
        @include cx-l;
        @include cx-uc;
        margin-bottom: 30px;
      }

      div {
        padding-bottom: 20px;
        width: 320px;
        max-width: 100%;
      }

      // @include helper-link-underline(1);
      @include helper-link-underline;
    }

    &__image {
      position: relative;
      a {
        @include cover-link();
        z-index: 5;
        &:hover ~ .home-feature1__block__image__caption:after {
          opacity: 1;
        }
      }

      &__caption {
        padding-top: 15px;
        // @include helper-hover-underline(1);
        @include helper-hover-underline;
        &:before {
          content: 'Fig ' counter(captionCount) '. ';
          counter-increment: captionCount;
          font-style: italic;
        }
      }
    }
  }
}
</style>
