import SanityBlocks from '~/components/sanity/SanityBlocks.vue'
import { h } from 'vue'

export default {
  props: {
    content: Object,
  },
  render(props) {
    const { content } = props
    // const { metadata } = asset.metadata;

    return h(
      'div',
      {
        class: 'sanity-content-blocks',
      },
      h(SanityBlocks, {
        blocks: content,
      })
    )
  },
}
