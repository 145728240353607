<template>
  <div class="overlay-filters" :class="`${overlay == 'filters' ? 'overlay-filters--active' : ''}`">
    <div class="overlay-filters__inner">
      <h2 class="overlay-filters__heading">Filters</h2>
      <CollectionFiltersMenu mode="mobile" class="collection-filters__menu" name="Size" />
      <CollectionFiltersMenu mode="mobile" class="collection-filters__menu" name="Type" />
      <CollectionFiltersControls class="collection-filters__controls" />
    </div>
  </div>
</template>

<script>
import GlobalLink from '../global/GlobalLink.vue'
import CollectionFiltersMenu from '../collection/CollectionFiltersMenu.vue'
import CollectionFiltersControls from '../collection/CollectionFiltersControls.vue'

export default {
  components: {
    GlobalLink,
    CollectionFiltersMenu,
    CollectionFiltersControls,
  },
  computed: {
    overlay() {
      return this.$store.state.overlay.current
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-filters {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 998;
  background: var(--background);

  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;

  --inset-top: var(--header-bottom);
  --inset-bottom: 40px;

  &--active {
    opacity: 1;
    pointer-events: auto;
  }

  &__inner {
    margin-left: var(--inset);
    margin-right: var(--inset);
    padding-top: calc(var(--inset-top) + 20px);
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__heading {
    @include cx-uc;
    margin-bottom: 20px;
    grid-column: 1 / -1;
  }

  &__controls {
    margin-bottom: auto;
  }
}
</style>
