<template>
  <section class="article-navigation">
    <nav class="navigation-inner">
      <ul>
        <li v-if="blog.previous_article" class="nav nav-prev">
          <a :href="blog.previous_article">
            <SvgPrevious />
            PREV
          </a>
        </li>
        <li class="line"></li>
        <li v-if="blog.next_article" class="nav nav-next">
          <a :href="blog.next_article">
            <SvgNext />
            NEXT
          </a>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
import SvgPrevious from '../svg/SvgPrevious.vue'
import SvgNext from '../svg/SvgNext.vue'
export default {
  components: { SvgPrevious, SvgNext },
  props: {
    blog: Object,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

@keyframes prevAnimation {
  0% {
    transform: translateX(0) translateY(-50%);
  }
  50% {
    transform: translateX(-10px) translateY(-50%);
  }
  100% {
    transform: translateX(0) translateY(-50%);
  }
}

@keyframes nextAnimation {
  0% {
    transform: translateX(0) translateY(-50%);
  }
  50% {
    transform: translateX(10px) translateY(-50%);
  }
  100% {
    transform: translateX(0) translateY(-50%);
  }
}

.article-navigation {
  padding-top: 120px;
  .navigation-inner {
    @include offsets(4);
    @include respond-to(l) {
      @include offsets(2);
    }
    @include respond-to(s) {
      @include insets();
    }
  }

  ul {
    display: flex;
    gap: 10px;
  }

  li {
    @include sans();
  }

  li.nav {
    position: relative;
    height: 25px;
    line-height: 25px;
    @include inlineX(16, 2);
    @include respond-to(xxl) {
      @include inlineX(16, 3);
    }
    @include respond-to(l) {
      @include inlineX(20, 4);
    }
    @include respond-to(s) {
      width: 100px;
    }

    a {
      display: block;
    }
    svg {
      position: absolute;
      top: 50%;
      transform: translateX(0) translateY(-50%);
      width: 28px;
      height: auto;
      fill: none;
      stroke: #5c5144;
      stroke-width: 2.5;
      transition: translate 1s ease;
    }
    &.nav-prev {
      a {
        text-align: right;
        padding-left: calc(50% + #{$gutter});
      }
      svg {
        left: $gutter + 10px;
      }
      &:hover {
        svg {
          animation: prevAnimation 0.7s infinite;
        }
      }
    }

    &.nav-next {
      a {
        padding-right: calc(50% + #{$gutter});
      }
      svg {
        right: $gutter + 10px;
      }
      &:hover {
        svg {
          animation: nextAnimation 0.7s infinite;
        }
      }
    }
  }

  li.line {
    flex-grow: 1;
    position: relative;
    &:after {
      display: block;
      content: ' ';
      position: absolute;
      top: 50%;
      left: $gutter;
      right: $gutter;
      height: 1px;
      background: $dark;
    }
  }
}
</style>
