<template>
  <div class="page-collection">
    <div class="page-collection__sidebar">
      <h1 class="page-collection__title">{{ collection.title }}</h1>
      <CollectionFilters class="page-collection__filters" :shopify-collection="collection" />
    </div>

    <div class="page-collection__mobile">
      <h1 class="page-collection__title">
        {{ collection.title }}
      </h1>
      <button class="page-collection__overlay" @click.prevent="store.commit('overlay/open', 'filters')">
        Filter +
      </button>
    </div>

    <CollectionLanding
      v-if="!displayPasswordform && filteredProducts.length && collection.metafields?.enable_landing"
      class="page-collection__landing"
      :id="collection.handle"
      :collection="collection"
      :products="filteredProducts"
    />

    <ProductCards
      v-if="!displayPasswordform && filteredProducts.length && !collection.metafields?.enable_landing"
      class="page-collection__cards"
      :products="filteredProducts"
      :show-video="true"
    />

    <ProductEmpty v-if="!displayPasswordform && filteredProducts.length == 0" />

    <ObserveIntersection v-if="loaded" class="page-collection__infinite" @intersect="onIntersection" />

    <CollectionPassword v-if="displayPasswordform" />
  </div>
</template>

<script lang="ts" setup>
import { collection as CollectionApi } from '../lib/shopify'
import GlobalSidebar from '../components/global/GlobalSidebar.vue'
import GlobalPagination from '../components/global/GlobalPagination.vue'
import CollectionPassword from '../components/collection/CollectionPassword.vue'
import ProductCards from '../components/product/ProductCards.vue'
import ProductEmpty from '../components/product/ProductEmpty.vue'
import { computed, nextTick, onMounted, ref } from 'vue'
import CollectionFilters from '../components/collection/CollectionFilters.vue'
import { LiquidCollectionWithProducts } from '../types/liquid'
import ObserveIntersection from '~/components/observe/ObserveIntersection.vue'
import CollectionLanding from '../components/collection/CollectionLanding.vue'
import store from '../store'

const getTag = (handle) => {
  const path = window.location.pathname
  const segments = path.split('/')
  const lastSegment = segments[segments.length - 1]

  return handle != lastSegment ? lastSegment : false
}

const props = defineProps<{
  collection: LiquidCollectionWithProducts
  isPassword: boolean | null
  hasSubscribed: boolean | null
}>()

const products = ref<LiquidCollectionWithProducts['products']>([])
const filters = ref<any>({})
const page = ref(1)
const loaded = ref(false)
const hasNextPage = ref(true)

const displayPasswordform = computed(() => {
  return !props.hasSubscribed && props.isPassword
})

const filteredProducts = computed(() => {
  // Check url for parameters
  const size = filters.value?.size
  if (size) {
    return products.value.filter((product) => {
      return product.variants.find((variant) => {
        return variant.available && (variant.options as any)?.find((option) => option == size)
      })
    })
  }
  return products.value
})

onMounted(() => {
  products.value = [...props.collection.products]
  window.addEventListener('popstate', handleHashChange)

  handleHashChange()

  if (props.collection.metafields?.enable_landing) {
    nextTick(() => {
      store.commit('layout/setBaseColor', 'background3')
    })
  }
  setTimeout(() => {
    loaded.value = true
  }, 1000)
})

const handleHashChange = () => {
  // Access the updated hash value
  const hash = window.location.hash.replace('#', '')

  try {
    const json = atob(hash) || '{}'
    const object = JSON.parse(json)
    const size = object?.size
    if (size) {
      filters.value = { size }
    } else {
      filters.value = {}
    }
  } catch (e) {
    console.log('Hash could not be decoded')
  }
}

const onIntersection = async (v) => {
  if (!hasNextPage.value) return
  await loadNextPage()
}

const loadNextPage = async () => {
  loaded.value = false

  // Get next page
  const handle = props.collection.handle
  const collection = await CollectionApi.getPage({
    handle,
    tag: getTag(handle),
    page: page.value + 1,
  })

  if (collection && collection.products.length) {
    products.value = [...products.value, ...collection.products]
    page.value += 1
  } else {
    hasNextPage.value = false
  }
  loaded.value = true
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.page-collection {
  @include wrapper;
  grid-template-columns: 4fr 19fr 1fr;
  padding-top: var(--page-top);
  display: grid;

  @include respond-to(l) {
    grid-template-columns: 180px 1fr 20px;
  }

  @include respond-to(s) {
    grid-template-columns: 1fr;
    padding-top: calc(var(--header-bottom) + 20px);
  }

  &__sidebar {
    top: var(--page-top);
    position: sticky;
    margin-bottom: auto;

    @include respond-to(s) {
      display: none;
    }
  }

  &__mobile {
    display: flex;
    justify-content: space-between;

    @include respond-to('s+') {
      display: none;
    }
  }

  &__overlay {
    @include cx-uc;
    margin-bottom: 20px;
  }

  &__title {
    @include cx-uc;
    margin-bottom: 24px;
    @include respond-to(s) {
      margin-bottom: 20px;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px calc(100% / 17);

    @include respond-to(m) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px 20px;
    }

    @include respond-to(s) {
      grid-gap: 20px var(--inset);
    }
  }

  &__infinite {
    grid-column: 1 / -1;
  }
}
</style>
