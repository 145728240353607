<template>
  <div class="shopify-money">
    <div class="shopify-money__symbol">{{ getMoneySymbol }}</div>
    <div class="shopify-money__amount">{{ getMoneyAmount(amount) }}</div>
    <div v-if="getMoneyCodeDisplay && !hideAllCurrency" class="shopify-money__code">
      {{ getMoneyCodeDisplay }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import store from '../../store'
import { DEFAULT_CURRENCY } from '../../store/currency'

const props = withDefaults(
  defineProps<{
    hideDoubleZeros?: boolean
    showDefaultCurrencyCode?: boolean
    hideAllCurrency?: boolean
    amount?: number
    showZeroCents?: boolean
  }>(),
  {
    hideDoubleZeros: true,
    showDefaultCurrencyCode: true,
    hideAllCurrency: false,
    amount: 0,
    showZeroCents: false,
  }
)

const storeCurrency = computed(() => store.state.currency.store)

const getMoneySymbol = computed(() => {
  const code = storeCurrency.value

  let symbol = '$'
  if (code == 'EUR') symbol = '€'
  if (code == 'GBP') symbol = '₤'
  if (code == 'JPY') symbol = '¥'
  if (code == 'KRW') symbol = '₩'
  if (code == 'SEK') symbol = ' '
  return symbol
})

const getMoneyCodeDisplay = computed(() => {
  if (props.showDefaultCurrencyCode) return storeCurrency.value

  return storeCurrency.value !== DEFAULT_CURRENCY ? storeCurrency.value : ''
})

const getMoneyAmount = (sourceAmount: number) => {
  // Currency convert
  let amount = sourceAmount.toFixed(2)

  // Some currencies have no cents
  if (storeCurrency.value == 'JPY') amount = parseFloat(amount).toFixed(0)
  if (storeCurrency.value == 'KRW') amount = parseFloat(amount).toFixed(0)

  //Hide double zeroes by default
  if (!props.showZeroCents) amount = amount.replace('.00', '')

  return amount
}
</script>

<style lang="scss">
.shopify-money {
  display: inline-flex;
  &__code {
    margin-left: 2px;
  }
}
</style>
