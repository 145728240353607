<template>
  <svg x="0px" y="0px" viewBox="0 0 1200 431" style="enable-background: new 0 0 1200 431">
    <g>
      <path
        d="M1168.7,332.5c0-24.3-13.9-34.9-40.4-34.9c-23.2,0-41.3,9.5-41.3,26.9c0,6.6,3.5,11,9.3,12.8l14.6-2
c-1.1-4.6-1.8-8.4-1.8-12.3c0-11,4.9-19.2,16.6-19.2c13.2,0,18.3,10.1,18.3,24v16.8l-32.4,11.9c-17.2,6.4-30.9,14.1-30.9,32.9
c0,17,11.3,28,28.9,28c15.4,0,27.4-10.4,34.9-19.4c1.8,12.1,8.6,19.6,20.1,19.6c9.3,0,17.2-4,22.9-11.7l-1.3-4
c-2.9,1.3-6,2.4-9.5,2.4c-6.2,0-7.9-4.6-7.9-13.5V332.5z M1122.8,402.6c-12.4,0-18.3-8.2-18.3-20.1c0-11.9,5.9-17.7,16.3-21.6
l23.2-9.5v41.5C1136.3,399.1,1130.5,402.6,1122.8,402.6 M1046.7,282c9,0,15.9-7.1,15.9-15.4c0-8.6-6.8-15.7-15.9-15.7
s-15.7,7.1-15.7,15.7C1031,274.9,1037.6,282,1046.7,282 M1059.7,297.6h-3.5l-35.1,11.5v3.1l13.9,7.7v78.8c0,8.2-3.5,9.7-14.6,10.4
v6.4h53.8V409c-11-0.7-14.6-2.2-14.6-10.4V297.6z M972.9,398.7c0,8.2-3.5,9.7-14.6,10.4v6.4h53.8V409c-11-0.7-14.6-2.2-14.6-10.4
v-65.5c0-23.8-10.2-35.5-30.7-35.5c-17.6,0-30.4,9.9-40.4,21.2l0.9-21.2h-3.8l-35.7,11.5v3.1l13.9,7.7v78.8
c0,8.2-3.5,9.7-14.6,10.4v6.4h53.6V409c-10.8-0.7-14.3-2.2-14.3-10.4v-74.3c7.7-6.2,17.4-11.5,27.8-11.5c12.6,0,18.5,6.6,18.5,21.4
V398.7z M840.7,335.1c11.9-13.2,21.8-14.1,35.3-14.1h3.8v-19.9c-1.8-2-4.9-3.5-9-3.5c-12.8,0-18.5,8.6-30,31.8l0.9-31.8h-3.8
l-35.7,11.5v3.1l13.9,7.7v78.8c0,8.2-3.5,9.7-14.6,10.4v6.4h58.5V409c-14.1-0.9-19.2-2.4-19.2-11.3V335.1z M741.4,417.7
c30.9,0,54.7-24.9,54.7-62.9c0-34-21.4-57.1-53.6-57.1c-30.9,0-54.7,24.9-54.7,62.7C687.8,394.7,709.2,417.7,741.4,417.7
M742.5,410.4c-19.6,0-28.5-25.8-28.5-56.5c0-29.3,9.3-49,27.4-49c19.9,0,28.7,25.6,28.7,56.5C770.1,390.7,760.6,410.4,742.5,410.4
M661.6,308.9h27.8v-9h-27.8v-28.7c0-14.3,4.9-22.1,13.5-22.1c7.5,0,9.9,6,9.9,14.3c0,3.3-0.2,5.7-0.7,8.2h13.5
c4.2-2.4,6.8-6.6,6.8-11.7c0-11-8.6-17.2-25.6-17.2c-24.9,0-42.1,14.8-42.1,43.5v12.4l-16.1,6v4.4h16.1v89.8
c0,8.2-3.5,9.7-14.6,10.4v6.4h58.5V409c-14.1-0.9-19.2-2.4-19.2-11.3V308.9z M586.3,282c9,0,15.9-7.1,15.9-15.4
c0-8.6-6.8-15.7-15.9-15.7c-9,0-15.7,7.1-15.7,15.7C570.7,274.9,577.3,282,586.3,282 M599.3,297.6h-3.5l-35.1,11.5v3.1l13.9,7.7
v78.8c0,8.2-3.5,9.7-14.6,10.4v6.4h53.8V409c-11-0.7-14.6-2.2-14.6-10.4V297.6z M537.2,242.7h-3.8l-34.9,10.8v3.3l13.9,7.5v134.4
c0,8.2-3.5,9.7-14.6,10.4v6.4h53.8V409c-11-0.7-14.6-2.2-14.6-10.4V242.7z M473.2,332.5c0-24.3-13.9-34.9-40.4-34.9
c-23.2,0-41.3,9.5-41.3,26.9c0,6.6,3.5,11,9.3,12.8l14.6-2c-1.1-4.6-1.8-8.4-1.8-12.3c0-11,4.8-19.2,16.5-19.2
c13.2,0,18.3,10.1,18.3,24v16.8L416,356.5c-17.2,6.4-30.9,14.1-30.9,32.9c0,17,11.2,28,28.9,28c15.4,0,27.4-10.4,34.8-19.4
c1.8,12.1,8.6,19.6,20.1,19.6c9.3,0,17.2-4,22.9-11.7l-1.3-4c-2.9,1.3-6,2.4-9.5,2.4c-6.2,0-7.9-4.6-7.9-13.5V332.5z M427.3,402.6
c-12.3,0-18.3-8.2-18.3-20.1c0-11.9,6-17.7,16.3-21.6l23.2-9.5v41.5C440.7,399.1,435,402.6,427.3,402.6 M320.8,258.4
c-42.8,0-75.2,32.4-75.2,83.2c0,45.2,29.3,76.1,76.1,76.1c20.3,0,36.2-4.6,49.2-12.6l3.1-40.2h-7.7c-7.1,31.5-21.2,44.3-43.2,44.3
c-32,0-49.4-32.7-49.4-73.9c0-37.7,15.4-68.8,47.4-68.8c22.1,0,36,13.2,41,38.6h7.1l-0.2-33.7C356.4,263.2,340,258.4,320.8,258.4
M165.5,308.9h27.8v-9h-27.8v-28.7c0-14.3,4.9-22.1,13.5-22.1c7.5,0,9.9,6,9.9,14.3c0,3.3-0.2,5.7-0.7,8.2h13.5
c4.2-2.4,6.8-6.6,6.8-11.7c0-11-8.6-17.2-25.6-17.2c-24.9,0-42.1,14.8-42.1,43.5v12.4l-16.1,6v4.4h16.1v89.8
c0,8.2-3.5,9.7-14.6,10.4v6.4h58.5V409c-14.1-0.9-19.2-2.4-19.2-11.3V308.9z M66.1,417.7c30.9,0,54.7-24.9,54.7-62.9
c0-34-21.4-57.1-53.6-57.1c-30.9,0-54.7,24.9-54.7,62.7C12.5,394.7,33.9,417.7,66.1,417.7 M67.2,410.4c-19.6,0-28.5-25.8-28.5-56.5
c0-29.3,9.3-49,27.4-49c19.9,0,28.7,25.6,28.7,56.5C94.8,390.7,85.3,410.4,67.2,410.4"
      />
      <path
        d="M566,43.5l28,69.3h-54.9L566,43.5z M608.6,148.5c1.3,3.3,2,6.2,2,8.8c0,6.8-5.3,9.3-19.2,9.7v6.6h61.6V167
c-6.2-1.1-10.8-2.4-13-8.2L582.1,17.2h-27.8l6.6,15.4l-49.6,126.2c-2.2,6-6.8,7.1-13,8.2v6.6h44.3V167c-14.3-0.7-19.6-3.1-19.6-9.9
c0-2.4,0.9-5.5,2.2-8.6l10.6-27.6h61.6L608.6,148.5z M453.4,147.4c0,1.8,0.2,4.6,0.2,5.5c0,10.6-5.1,12.8-20.5,14.1v6.6h65.1V167
c-11.9-0.9-16.5-2.9-17.9-13.5L466.8,41.5c0-11.7,4.4-15.2,18.5-16.1v-6.6h-42.8c-0.4,5.1-1.5,8.2-3.3,13l-36.4,103l-39.7-103
c-1.8-4.8-2.9-7.9-3.3-13h-43.2v6.6c14.1,0.9,18.8,4.4,18.8,16.1l-13.7,112.1c-1.1,10.4-5.7,12.6-17.9,13.5v6.6h48.5V167
c-15.4-1.3-20.5-3.5-20.5-14.1c0-0.9,0.2-3.7,0.4-5.5l11-95.7l47,122h7.7l43.9-122L453.4,147.4z M201.9,165.7l86.3-142.3v-4.6
H176.3L175,61.2h6c11-25.8,14.8-34.4,32.9-34.4h42.8l-86.7,142.5v4.4h114.7l7.3-45.5h-6.4c-15.9,28.5-21.6,37.5-39.9,37.5H201.9z
M84.5,175.9c40.6,0,72.1-32.2,72.1-82.7c0-40.1-24.9-76.5-70.2-76.5c-40.6,0-72.1,32.2-72.1,82.7
C14.3,139.5,39.2,175.9,84.5,175.9 M86.4,167.7c-30.4,0-44.1-36.6-44.1-72.1c0-37.7,14.1-70.8,42.1-70.8
c30.4,0,43.9,36.6,43.9,72.1C128.4,134.6,114.2,167.7,86.4,167.7"
      />
    </g>
  </svg>
</template>
