<template>
  <label class="input-checkbox">
    <input
      class="input-checkbox__input"
      type="checkbox"
      :checked="!!value"
      :value="!!value"
      :name="name"
      @input.prevent="$emit('input', $event.target.checked)"
    />
    <span v-text="title" />
  </label>
</template>

<script>
export default {
  props: ['title', 'value', 'name'],
}
</script>

<style lang="scss">
@import '~styles/base.scss';
@import '~styles/frontend/helper-forms.scss';

.input-checkbox {
  display: block;
  &__input {
    position: relative;
    margin-right: 10px;
    &:before {
      content: ' ';
      width: 14px;
      height: 14px;
      border: 1px solid $dark;
      display: inline-block;
      cursor: pointer;
    }
    &:checked:after {
      position: absolute;
      content: ' ';
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      background: $dark;
      display: block;
      cursor: pointer;
    }
  }
}
</style>
