<template>
  <div class="collection-filters">
    <div class="collection-filters__heading">Filter</div>
    <CollectionFiltersMenu class="collection-filters__menu" name="Size" />
    <CollectionFiltersMenu class="collection-filters__menu" name="Type" />
    <CollectionFiltersControls class="collection-filters__controls" />
  </div>
</template>

<script>
import CollectionFiltersMenu from './CollectionFiltersMenu.vue'
import CollectionFiltersControls from './CollectionFiltersControls.vue'

export default {
  components: { CollectionFiltersMenu, CollectionFiltersControls },
  props: {
    shopifyCollection: Object,
  },
  computed: {
    collection() {
      return this.$store.state.collection
    },
    selected() {
      return this.collection.selected
    },
  },
  watch: {
    selected: {
      handler(selected) {
        const url = `/collections/${this.shopifyCollection.handle}`

        if (selected[0]) {
          if (selected[0].menu == 'Size') {
            // Client filter
            document.location = '#' + btoa(JSON.stringify({ size: selected[0].value }))
          } else {
            // Tag filter
            window.location = `${url}/${selected[0].value}`
          }
        } else {
          window.location = url
        }
      },
      // immediate: true,
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.collection-filters {
  margin-right: 20px;

  &__heading {
    @include cx-uc;
    margin-bottom: 4px;
  }

  &__controls {
    margin-top: 20px;
  }
}
</style>
