<template>
  <div class="global-reveal" :class="showClass">
    <slot></slot>
  </div>
</template>

<script>
import BrowserMixin from '~/mixins/browser'

export default {
  mixins: [BrowserMixin],
  data() {
    return {
      offsetBottom: 300,
      active: false,
    }
  },
  computed: {
    showClass() {
      return this.active && 'global-reveal--active'
    },
  },
  methods: {
    onWindowUpdate() {
      const { active, scrollTop, resizeHeight, offsetBottom, $el } = this

      if (!$el) return ''
      if (active) return 'global-reveal--active'

      const offsetTop = $el.offsetTop

      //See if offset_top is < bottom of window
      if (offsetTop < scrollTop + resizeHeight - offsetBottom) this.active = true
    },
  },
}
</script>

<style lang="scss">
.global-reveal {
  opacity: 0;
  transition: opacity 1s ease;

  &--active {
    opacity: 1;
  }
}
</style>
