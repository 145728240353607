<template>
  <FormWrap class="form-login" title="Sign In" :error="error" :submit="login" action="/account/login">
    <label class="form-login__label" for="email">Email</label>
    <InputEmail
      v-model="username"
      class="form-login__input form-login__input--email"
      name="email"
      placeholder="Your Email Address"
      required="true"
    />
    <label class="form-login__label" for="password">Password</label>
    <InputPassword
      v-model="password"
      class="form-login__input form-login__input--password"
      name="password"
      placeholder="Your Password"
      required="true"
    />
    <InputButton class="form-login__button" title="Login" />
  </FormWrap>
</template>

<script>
import Vuex from 'vuex'
import InputEmail from '../input/InputEmail.vue'
import InputPassword from '../input/InputPassword.vue'
import InputButton from '../input/InputButton.vue'
import FormWrap from './FormWrap.vue'

export default {
  computed: Vuex.mapState({
    user: (state) => state.account.user,
  }),
  components: { InputEmail, InputPassword, InputButton, FormWrap },
  data() {
    return {
      error: '',
      username: '',
      password: '',
    }
  },
  mounted() {
    const params = Object.fromEntries(new URLSearchParams(window.location.search))
    if (params.return_url?.indexOf('account') > -1) {
      this.error = 'Login failed, please check your details and try again'
    }
    // this.error = window._data.login.form.errors.replace(/<(?:.|\n)*?>/gm, '')
  },
  methods: {
    async login(event) {
      this.error = ''
      try {
        const data = Object.fromEntries(new FormData(event.target))
        const submitData = {
          'customer[email]': data.email,
          'customer[password]': data.password,
        }

        const result = await this.$store.dispatch('account/login', submitData)
        if (!result) throw new Error('Login failed, please check your details and try again')

        window.location.href = '/account'
      } catch (e) {
        console.log(e.response.data)
        // if (e.code == 'ERR_NETWORK') window.location.href = '/account/login'
        if (e.response.data.indexOf('Invalid CAPTCHA token') > -1) {
          this.error = 'Invalid CAPTCHA Token'
        } else {
          this.error = 'Incorrect username or password, please try again'
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.form-login {
  &__label {
    margin-bottom: 20px;
  }

  &__input {
    margin-bottom: 20px;
  }
}
</style>
