<template>
  <div class="global-carousel">
    <Swiper
      ref="carousel"
      @swiper="onSwiper"
      v-bind="{ ...swiperOptions, ...desktopOptions }"
      :auto-update="false"
      :delete-instance-on-destroy="false"
      :cleanup-styles-on-destroy="false"
      class="global-carousel__swiper"
    >
      <SwiperSlide v-for="(slide, k) in slides" :key="`global_carousel_${k}`">
        <component :is="slideComponent" class="global-carousel__slide" :index="k" :slide="slide" />
      </SwiperSlide>
    </Swiper>
    <div v-if="hasControls" class="global-carousel__controls">
      <div class="global-carousel__control global-carousel__control--prev" @click.prevent="prev">
        <SvgArrowCarouselLeft />
      </div>
      <div class="global-carousel__control global-carousel__control--next" @click.prevent="next">
        <SvgArrowCarouselRight />
      </div>
    </div>
  </div>
</template>

<script>
import BrowserMixin from '~/mixins/browser'
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
// import { Swiper as SwiperClass, Mousewheel } from 'swiper/js/swiper.esm'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

// SwiperClass.use([Mousewheel])

import GlobalSlideProduct from './GlobalSlideProduct.vue'
import GlobalSlideCommunity from './GlobalSlideCommunity.vue'
import SvgArrowJournalLeft from '../svg/SvgArrowJournalLeft.vue'
import SvgArrowJournalRight from '../svg/SvgArrowJournalRight.vue'
import SvgArrowCarouselLeft from '../svg/SvgArrowCarouselLeft.vue'
import SvgArrowCarouselRight from '../svg/SvgArrowCarouselRight.vue'
import { ref } from 'vue'

export default {
  directives: {
    // swiper: directive,
  },
  components: {
    Swiper,
    SwiperSlide,
    GlobalSlideProduct,
    GlobalSlideCommunity,
    SvgArrowJournalLeft,
    SvgArrowJournalRight,
    SvgArrowCarouselLeft,
    SvgArrowCarouselRight,
  },
  mixins: [BrowserMixin],
  props: {
    slideComponent: String,
    slides: Array,
    desktopOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ready: false,
      carouselTransition: 0,
      carouselTranslate: 0,
      dragging: false,
      slideSize: 0,
    }
  },
  computed: {
    hasControls() {
      if (this.slides.length > 4) return true
      return false
    },
    swiperOptions() {
      return {
        breakpoints: {
          100: {
            slidesPerView: 'auto', //1.5,
            spaceBetween: 20,
            centeredSlides: false,
          },
          // when window width is >= 820
          820: {
            freeMode: true,
            freeModeMomentumRatio: 0.15,
            freeModeMomentumBounceRatio: 0.01,
            freeModeSticky: false,
            spaceBetween: 64,
            slidesPerView: 'auto',
            // centeredSlides: true,
            // slidesPerView: 'auto',
            ...(this.desktopOptions ? this.desktopOptions : {}),
          },
        },
      }
    },
  },
  setup() {
    const swiperRef = ref(null)

    const onSwiper = (swiper) => {
      swiperRef.value = swiper
    }

    function next() {
      if (swiperRef.value) {
        swiperRef.value.slideNext()
      }
    }

    function prev() {
      if (swiperRef.value) {
        swiperRef.value.slidePrev()
      }
    }

    return { onSwiper, next, prev }
  },
  methods: {},
}
</script>

<style lang="scss">
/* @import 'swiper/css/swiper.css'; */
@import '~styles/base.scss';

.global-carousel {
  position: relative;

  &__swiper {
    height: 100%;
    cursor: grab;

    padding-left: calc(#{columns(4)} + var(--inset));
    padding-right: var(--inset);

    @include respond-to(m) {
      padding-left: 0;
    }

    &:active {
      cursor: grabbing;
    }
  }

  .swiper-slide {
    @include respond-to('m+') {
      display: flex;
      justify-content: center;
      width: columns(5);

      // margin-left: columns(1.5);

      &:first-child {
        // margin-left: calc(#{columns(4)} + var(--inset));
      }
    }

    @include respond-to(m) {
      width: columns(16);

      // margin-left: columns(1);
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__controls {
    @include respond-to('m+') {
      @include wrapper;
      display: flex;
      margin-top: 20px;
      padding-left: calc(columns(4) - 10px);
    }
    @include respond-to(m) {
      display: none;
    }
  }

  &__control {
    display: inline-flex;
    cursor: pointer;
    padding: 10px;

    svg {
      width: 30px;
    }
  }
}
</style>
