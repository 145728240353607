import * as AccountApi from '~/lib/shopify/account'

interface AccountState {
  user: any | false
  orders: any[] | false
  addresses: any[] | false
  countries: any[]
}

export const state = (): AccountState => ({
  user: false,
  orders: false,
  addresses: false,
  countries: [],
})

export const actions = {
  async load({ dispatch }) {
    dispatch('loadCountries')
  },
  async login({ commit }, input) {
    const response = await AccountApi.submitLogin(input)
    commit('setUser', response)
    return response
  },
  async forgot(_, input) {
    return AccountApi.submitForgot(input)
  },
  async register(_, input) {
    const response = await AccountApi.submitRegister(input)
    // commit('setUser', response)
    return response
  },
  async activate(_, data) {
    return AccountApi.submitActivate(data)
  },
  async reset(_, data) {
    return AccountApi.submitReset(data)
  },
  async loadAddresses({ commit }, data) {
    const response = await AccountApi.getAddresses(data)
    commit('setAddresses', response)
    return response
  },
  async loadCountries({ commit }) {
    const countryOptionData = document.getElementById('country_option_data')
    if (!countryOptionData) {
      console.error('Country option data element not found')
      return []
    }
    const countriesHtml = countryOptionData.innerHTML

    const wrapperEl = document.createElement('div')
    wrapperEl.innerHTML = countriesHtml

    const optionEls = wrapperEl.getElementsByTagName('option')

    const data = Array.from(optionEls).map(($el) => {
      const country = $el.getAttribute('value')
      const provincesData = $el.getAttribute('data-provinces')
      let provinces = []

      try {
        provinces = JSON.parse(provincesData || '[]')
      } catch (error) {
        console.error(`Error parsing provinces for ${country}:`, error)
      }

      return {
        name: country,
        value: country,
        provinces: Array.isArray(provinces)
          ? provinces.map((v) => ({
              name: v[0],
              value: v[1],
            }))
          : [],
      }
    })

    commit('setCountries', data)

    return data
  },
  async loadOrders({ commit }, data) {
    const orders = await AccountApi.getOrders(data)
    commit('setOrders', orders)
    return orders
  },

  async createAddress(_, input) {
    return AccountApi.submitAddressCreate(input)
  },
  async updateAddress(_, input) {
    return AccountApi.submitAddressUpdate(input)
  },
  async deleteAddress(_, input) {
    return AccountApi.submitAddressDelete(input)
  },
}

export const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setAddresses(state, { addresses, newAddress }) {
    state.addresses = addresses
    state.new_address = newAddress
  },
  setOrders(state, orders) {
    state.orders = orders
  },
  setCountries(state, countries) {
    state.countries = countries
  },
}

export const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
