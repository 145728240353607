<template>
  <transition name="fade">
    <ObserveResize
      class="overlay-cookies"
      :class="`${isActive ? 'overlay-cookies--active' : 'overlay-cookies--inactive'}`"
      @resize="(event) => $emit('resize', event)"
    >
      <div class="overlay-cookies__inner">
        <div class="overlay-cookies__content">
          <span>This site uses cookies to optimize performance</span>
          <button class="overlay-cookies__accept" @click="close">Accept Cookies</button>
        </div>
      </div>
    </ObserveResize>
  </transition>
</template>

<script>
// import Vuex from 'vuex'
import SvgClose from '~/components/svg/SvgClose.vue'
import ObserveResize from '../observe/ObserveResize.vue'

const waitTime = 60 //days
const msInDay = 1000 * 60 * 60 * 24

export default {
  components: {
    SvgClose,
    ObserveResize,
  },
  data() {
    return {
      isActive: false,
    }
  },
  mounted() {
    const timeLastHidden = window.localStorage.getItem('cookies_overlay')

    // Don't show if shown in last X time
    if (!!timeLastHidden) {
      const timeNow = Date.now()
      const timeAgo = Math.round((timeNow - timeLastHidden) / msInDay)
      if (timeAgo < waitTime) return
    }

    setTimeout(() => {
      this.isActive = true
    }, 1000)
  },
  methods: {
    close() {
      window.localStorage.setItem('cookies_overlay', Date.now())
      this.isActive = false
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-cookies {
  @include cx-s;
  @include sans;

  position: fixed;
  z-index: 1101; // Just in front of newsletter
  bottom: 40px;
  right: $mobile_inset;

  width: 320px;

  border: 1px solid var(--foreground);
  opacity: 0;
  transition: all 0.2s ease;

  @media (max-width: 420px) {
    left: $mobile_inset;
    width: auto;
    bottom: 16px;
  }

  &--active {
    opacity: 1;
  }

  &--inactive {
    opacity: 0;
    pointer-events: none;
  }

  &__inner {
    line-height: 20px;
    background: var(--background);
    display: grid;
    grid-template-columns: 1fr;

    text-align: center;
    padding: 13px 0 16px;
  }

  &__content {
    padding: 5px var(--inset);
    display: flex;
    flex-wrap: wrap;
    gap: 0px 30px;
  }

  &__accept {
    @include sans;
    @include cx-s;
    @include cx-uc;
    transition: opacity 0.2s ease;

    @include form-button-dark;
    width: 100%;
    margin-top: 14px;

    @include hover {
      opacity: 0.5;
    }
  }
}
</style>
