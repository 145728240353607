<template>
  <div class="home-full-bleed">
    <div class="home-full-bleed__media" :style="getMediaStyle">
      <div class="home-full-bleed__caption__wrap">
        <div class="home-full-bleed__caption">
          <div class="home-full-bleed__title" v-html="row.caption"></div>
          <div class="home-full-bleed__link" v-if="row.link_text">
            <a :href="row.link" v-html="row.link_text"></a>
          </div>
        </div>
      </div>
      <video autoplay playsinline muted loop v-if="row.video && row.video != ''">
        <source :src="row.video" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    getMediaStyle() {
      return {
        'background-image': `url(${this.row.image})`,
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-full-bleed {
  height: 100vh;
  padding: 0;
  position: relative;
  margin-left: -$gutter;
  margin-right: -$gutter;
  &__media {
    @include cover();
    background-size: cover;
    background-position: center;

    display: flex;

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__caption__wrap {
    align-self: flex-end;
    display: grid;
    width: 100%;
    grid-auto-rows: minmax(min-content, max-content);
    padding: 0 $gutter;
    grid-template-columns: repeat(24, 1fr);
    grid-column-gap: #{$gutter * 2};
    padding-bottom: 80px;
    position: relative;
    z-index: 10;
  }

  &__caption {
    grid-column: 2 / span 10;
    color: $light;
    a {
      &,
      &:hover {
        color: currentColor;
      }
    }
    @include cx-l();
    @include cx-uc();
    @include sans();
    // @include helper-link-underline($light);
    @include helper-link-underline;
  }

  &__link {
    padding-top: 1em;
  }
}
</style>
