<template>
  <div
    class="app"
    :style="`--header-bottom: ${contentOffset}px;--background:var(--color-${backgroundColor})`"
  >
    <OverlayBanner @resize="headerOffset = $event.height" />

    <TheHeader :offset="headerOffset" @offset="contentOffset = $event">
      <slot name="header"></slot>
    </TheHeader>

    <div class="app-inner">
      <slot />
    </div>

    <TheFooter />

    <OverlayCart />
    <OverlayMobile />
    <OverlayCookies />
    <OverlayInstalments />
    <OverlayAccount />
    <OverlayNewsletter />
    <OverlayFilters />

    <!-- 
    <OverlayCurrency />
    <OverlaySizing />
    <OverlayGallery />
     -->

    <OverlayGrid />
    <OverlayClose />
  </div>
</template>

<script>
import BrowserMixin from '../mixins/browser'

import TheHeader from '../components/TheHeader.vue'
import TheFooter from '../components/TheFooter.vue'

import OverlayBanner from '../components/overlay/OverlayBanner.vue'
import OverlayGrid from '../components/overlay/OverlayGrid.vue'
import OverlayCart from '../components/overlay/OverlayCart.vue'
import OverlayClose from '../components/overlay/OverlayClose.vue'
import OverlayCurrency from '../components/overlay/OverlayCurrency.vue'
import OverlayMobile from '../components/overlay/OverlayMobile.vue'
import OverlayCookies from '../components/overlay/OverlayCookies.vue'
import OverlayInstalments from '../components/overlay/OverlayInstalments.vue'
import OverlayAccount from '../components/overlay/OverlayAccount.vue'
import OverlayNewsletter from '../components/overlay/OverlayNewsletter.vue'
import OverlayFilters from '~/components/overlay/OverlayFilters.vue'
import { loadRecaptchaScripts } from '../lib/shopify/recaptcha'

export default {
  components: {
    TheHeader,
    TheFooter,
    OverlayBanner,
    OverlayGrid,
    OverlayCart,
    OverlayClose,
    OverlayCurrency,
    OverlayMobile,
    OverlayCookies,
    OverlayInstalments,
    OverlayAccount,
    OverlayNewsletter,
    OverlayFilters,
  },
  mixins: [BrowserMixin],

  data() {
    return {
      headerOffset: 0,
      contentOffset: 0,
    }
  },
  computed: {
    settings() {
      return this.$store.state.shopify.settings
    },
    template() {
      return this.$store.state.shopify.template
    },
    layout() {
      return this.$store.state.layout
    },
    backgroundColor() {
      return this.layout.backgroundColor || this.layout.baseColor
    },
  },
  mounted() {
    this.$store.dispatch('cart/load')
    this.$store.dispatch('currency/load')

    // Enable focus boxes
    window.addEventListener('keyup', (event) => {
      if (event.which != 9) return
      this.enableFocus = true
    })

    // Show cart if hash is #cart
    const hash = window.location.hash
    if (hash == '#cart') {
      this.$store.commit('overlay/open', 'cart')
    }

    // Add Recaptcha Script
    loadRecaptchaScripts()

    // Set background colour
    const pageExceptions = ['page-silk-tee', 'page-stockists', 'page-bandanas']
    let background = 'background1'

    if (pageExceptions.find((e) => this.template.indexOf(e) > -1)) {
      background = 'background2'
    }

    if (this.template.indexOf('page') > -1) background = 'background3'
    if (this.template.indexOf('customer') > -1) background = 'background3'

    this.$store.commit('layout/setBaseColor', background)
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

@import '~styles/utils/reset';
@import '~styles/utils/normalise';

@import '~styles/frontend/transitions';
@import '~styles/frontend/customer';

:root {
  --app-header-breakpoint: 720;
  --page-top: 140px;

  --wrap: #{$wrap};
  --gutter: #{$gutter};
  --columns: #{$columns};
  --inset: #{$inset};
  --scroll-inset: 8px;

  --color-light: #{$light};
  --color-lightestgrey: #{$lightestgrey};
  --color-lightgrey: #{$lightgrey};
  --color-midgrey: #{$midgrey};
  --color-darkgrey: #{$darkgrey};
  --color-darkestgrey: #{$darkestgrey};
  --color-dark: #{$dark};
  --color-background1: #{$background};
  --color-background2: #{$background2};
  --color-background3: #{$background3};
  --color-background-footer: #{$background_footer};
  --color-accent: #{$accent};
  --color-accent2: #f3efef;

  --color-off-black: #{$off_black};

  --background: var(--color-background);
  --foreground: var(--color-dark);

  --wrapper: calc(100vw - (var(--inset) * 2) - var(--scroll-inset));

  --column: calc(
    (1 / var(--columns)) *
      (100vw - (var(--inset) * 2) - var(--scroll-inset) - ((var(--columns) - 1) * var(--gutter))) +
      var(--gutter)
  );

  @include respond-to(l) {
    --page-top: 160px;
    --inset: #{$mobile_inset};
    --wrap: #{$mobile_width};
    --scroll-inset: 0px;
  }
}

a,
button,
.a {
  color: var(--foreground);
}

input,
button,
select {
  @include cx;
  @include cx-s;
  @include serif;
  border-radius: 0;
}

.app {
  @include serif;
  @include cx;
  @include cx-s;

  max-width: 100%;
  min-height: 100vh;
  // overflow-x:hidden;
  color: var(--foreground);
  background: var(--background);
  transition: background-color 0.3s;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    pointer-events: none;
    z-index: -1;
    background: $background2;
  }

  &[data-overlay='gallery'] {
    ::-webkit-scrollbar {
      width: 0px; /* remove scrollbar space */
      background: transparent; /* optional: just make scrollbar invisible */
    }
  }

  &--background-alt {
    &:after {
      opacity: 1;
    }
  }

  &--background1 {
    --background: var(--color-background);
  }

  &--background2 {
    --background: var(--color-background2);
  }

  &--background3 {
    --background: var(--color-background3);
  }
}

.app-inner {
  @include clearfix();
  transition: transform $easing_time $easing;
  /* position: relative; */
  /* z-index: 10; */
  min-height: calc(100vw - var(--page-top));
}

.app-returnmagic iframe {
  min-height: 1200px;
}
</style>
