<template>
  <div class="article-show">
    <ArticleLandingImage :article="article" v-if="getLayout == 'imageLanding'" />
    <ArticleLandingDefault :article="article" v-if="getLayout == 'default'" />

    <section class="content">
      <GlobalLegacyContent
        v-if="!sanity"
        class="component-rows"
        :html="article.content"
        :share-url="`https://ozmaofcalifornia.com/${article.url}`"
        type="article"
      />
      <ArticleContent v-if="sanity" :content="sanity.content" />
    </section>

    <ArticleNavigation :blog="blog" />

    <!-- <ArticleRelated /> -->
  </div>
</template>

<script>
import GlobalLegacyContent from '~/components/global/GlobalLegacyContent'
import ArticleLandingImage from './ArticleLandingImage.vue'
import ArticleLandingDefault from './ArticleLandingDefault.vue'
import ArticleRelated from './ArticleRelated.vue'
import ArticleNavigation from './ArticleNavigation.vue'
import SanityContentTemplate from '../sanity/SanityContentTemplate'
import ArticleContent from './ArticleContent.vue'

export default {
  components: {
    GlobalLegacyContent,
    ArticleLandingImage,
    ArticleLandingDefault,
    ArticleRelated,
    ArticleNavigation,
    SanityContentTemplate,
    ArticleContent,
  },
  props: {
    article: Object,
    blog: Object,
  },
  computed: {
    sanity() {
      return this.article.sanity?.data
    },
    blocks() {
      return JSON.parse(this.sanity.content)
    },
    getLayout() {
      if (this.sanity) return this.sanity.layout

      // Legacy
      return this.article.tags.indexOf('alt') > -1 ? 'imageLanding' : 'default'
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.article-show {
  @include clearfix;
  padding-top: 0;

  section.content {
    padding-top: 120px;
  }
}
</style>
