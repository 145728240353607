<template>
  <div class="product-sidebar">
    <div class="product-sidebar__inner">
      <nav class="product-sidebar__header">
        <a href="/collections/all">Shop</a>
        <span v-if="product.metafields.collection.handle">
          /
          <a :href="`/collections/${product.metafields.collection.handle}`">
            {{ product.metafields.collection.title }}
          </a>
        </span>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-sidebar {
  &__inner {
    position: sticky;
    top: var(--page-top);
  }

  &__header {
    @include cx-uc;
  }
}
</style>
