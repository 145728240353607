<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 66.4 42.7"
    style="enable-background: new 0 0 66.4 42.7"
    xml:space="preserve"
  >
    <polygon
      class="st0"
      points="45.5,1 43.3,3.2 60,19.9 0.8,19.9 0.8,23 60,23 43.3,39.7 45.5,41.9 65.9,21.5 "
    />
  </svg>
</template>

<script></script>

<style lang="scss"></style>
