<template>
  <ObserveResize @resize="onResize" class="product-card" :class="`product-card--${mode}`">
    <a :href="product.url" class="product-card__link"></a>

    <div class="product-card__image">
      <AccentuateVideo
        v-if="product.metafields?.collection_video"
        class="product-card__video"
        :src="product.metafields.collection_video"
        ratio="6x8"
      />

      <div v-else class="product-card__mood" :style="getMoodStyle"></div>
      <ShopifyImage class="product-card__image" :src="product.featured_image" ratio="6x8" />
    </div>

    <div class="product-card__caption">
      <div>
        <h2 class="product-card__title">
          <span class="product-card__type">{{ getTitle.type }}</span>
          <span v-if="getTitle.material" class="product-card__material"
            >({{ getTitle.material }})
          </span>
        </h2>
        <ShopifyProductPrice :hide-all-currency="true" class="product-card__price" :product="product" />
      </div>
      <div>
        <span v-if="getTitle.colour" class="product-card__colour">{{ getTitle.colour }}</span>
        <span v-if="getColoursText" class="product-card__colours"> Also in: {{ getColoursText }}</span>
      </div>
    </div>
  </ObserveResize>
</template>

<script>
import { resizeImageSrc } from '~/lib/helpers'
import ShopifyImage from '~/components/shopify/ShopifyImage.vue'
import AccentuateVideo from '~/components/shopify/ShopifyAccentuateVideo.vue'
import ShopifyProductPrice from '../shopify/ShopifyProductPrice.vue'
import ShopifyProductTitle from '../shopify/ShopifyProductTitle.vue'
import ObserveResize from '../observe/ObserveResize.vue'

// Get colour from product title
function colour(title) {
  const splits = title.split(',')
  if (splits.length == 1) return ''

  return splits[splits.length - 1].trim()
}

export default {
  props: {
    product: Object,
    showVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: 'large',
    }
  },
  computed: {
    getMoodStyle() {
      let image_path = false

      image_path = this.product.images?.[1]?.src

      if (!image_path) return {}

      image_path = resizeImageSrc(image_path, '600x800_crop_center')

      return { 'background-image': `url(${image_path}` }
    },
    getDisplayPrice() {
      const { price, compare_at_price, available } = this.product

      const displayPrice = this.getPrice({
        price: price,
        original: compare_at_price,
      })

      let message = false

      // var is_low = !!product.variants.find((v) => {
      //   return v.inventory_management == "shopify"
      //     && v.inventory_policy == "deny"
      //     && v.inventory_quantity <= 1
      //     && v.inventory_quantity > 0
      // });

      if (!available) message = 'Sold Out'
      // if (is_low) message = 'Low in Stock';

      return message ? `${displayPrice}, <span class="message">(${message})</span>` : displayPrice
    },
    getTitle() {
      const [type, material, colour] = this.product.title.split(',')

      return {
        type: type?.trim() || '',
        material: material?.trim() || '',
        colour: colour?.trim() || '',
      }
    },
    getColoursText() {
      const colours = (this.product.metafields?.colours || [])
        .filter((product) => colour(this.product.title) != colour(product.title))
        .map((product) => colour(product.title))

      return colours.length > 0 ? colours.join(', ') : false
    },
  },
  methods: {
    onResize({ width }) {
      this.mode = width > 180 ? 'large' : 'small'
    },
  },
  components: {
    ShopifyImage,
    AccentuateVideo,
    ShopifyProductPrice,
    ShopifyProductTitle,
    ObserveResize,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';
.product-card {
  position: relative;

  &__link {
    @include cover-link;
    z-index: 12;
  }

  &__image {
    position: relative;
  }

  &__mood {
    @include cover-image;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    z-index: 10;
  }

  &__video {
    @include cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 11;
  }

  &__caption {
    padding-top: 10px;
    @include sans;
    @include cx-s;
  }

  &__type {
    @include cx-uc;
  }

  &__material {
    @include respond-to('xs+') {
      white-space: wrap;
    }
  }

  &__price {
    .original {
      text-decoration: line-through;
    }
    .message {
      text-transform: none;
      color: $midgrey;
    }
  }

  &__colours {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
    text-align: right;
  }

  &--large & {
    &__caption {
      > div {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
      }
    }

    &__price {
      margin-left: auto;
      text-align: right;
      justify-content: flex-end;
    }
  }

  &--small & {
  }

  &:hover & {
    &__mood {
      opacity: 1;
    }
  }
}
</style>
