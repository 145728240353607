<template>
  <a :href="linkUrl" class="header-submenu-card">
    <ShopifyImage class="header-submenu-card__image" :src="image" />
    <div v-if="caption" class="header-submenu-card__caption">{{ caption }}</div>
    <div v-if="linkTitle" class="header-submenu-card__link">
      {{ linkTitle }}
    </div>
  </a>
</template>

<script>
import GlobalLinks from '../global/GlobalLinks.vue'
import ShopifyImage from '../shopify/ShopifyImage.vue'

export default {
  components: {
    GlobalLinks,
    ShopifyImage,
  },
  props: {
    caption: String,
    image: String,
    linkUrl: String,
    linkTitle: String,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.header-submenu-card {
  display: block;

  &__image {
    margin-bottom: 5px;
  }

  &__link {
    opacity: 0.5;
  }

  transition: opacity 0.2s ease;
  @include hover {
    opacity: 0.8;
  }
}
</style>
