import '../styles/gift-card.scss'

import { createApp } from 'vue'
import store from '../store'

import LayoutDefault from '../layout/LayoutDefault.vue'

import PageHome from '../pages/PageHome.vue'
import PageCollection from '../pages/PageCollection.vue'
import PageSearch from '../pages/PageSearch.vue'
import PageBlog from '../pages/PageBlog.vue'
import PageArticle from '../pages/PageArticle.vue'
import PageDefault from '../pages/PageDefault.vue'
import PageProduct from '../pages/PageProduct.vue'
import PageError from '../pages/PageError.vue'

import PageTemplateCollection from '../pages/PageTemplateCollection.vue'
import PageTemplateBandanas from '../pages/PageTemplateBandanas.vue'
import PageTemplateAbout from '../pages/PageTemplateAbout.vue'
import PageTemplateTextiles from '../pages/PageTemplateTextiles.vue'

import HeaderSubmenus from '../components/header/HeaderSubmenus.vue'

import FormLogin from '../components/form/FormLogin.vue'
import FormRegister from '../components/form/FormRegister.vue'
import FormForgot from '../components/form/FormForgot.vue'
import FormReset from '../components/form/FormReset.vue'

const app = createApp({
  components: {
    PageHome,
    PageCollection,
    PageSearch,
    PageBlog,
    PageArticle,
    PageDefault,
    PageProduct,
    PageError,

    PageTemplateCollection,
    PageTemplateBandanas,
    PageTemplateAbout,
    PageTemplateTextiles,

    LayoutDefault,

    HeaderSubmenus,

    FormLogin,
    FormRegister,
    FormForgot,
    FormReset,
  },
})

app.use(store)
app.mount('#app')
