<template>
  <div class="sanity-content-video">
    <SanityVideo
      class="sanity-content-video__video"
      :video="{ asset }"
      :should-play="false"
      :click-to-play="true"
      :controls="true"
    />
  </div>
</template>

<script>
export default {
  props: {
    asset: Object,
  },
}
</script>

<style lang="scss">
.sanity-content-video {
  &__video {
    position: relative;
    padding-top: 65%;
  }
}
</style>
