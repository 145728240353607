<template>
  <PortableText :value="blocks" :components="serializers" class="sanity-content" />
</template>

<script>
import { PortableText } from '@portabletext/vue'

import { defaultMarks, defaultStyles, defaultTypes, typesColumns } from '~/lib/sanity/blocks'

export default {
  name: 'SanityContent',
  components: {
    PortableText,
  },
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    serializers() {
      return {
        types: [...defaultTypes, typesColumns],
        marks: defaultMarks,
        styles: defaultStyles,
      }
    },
  },
}
</script>

<style lang="scss">
.sanity-content {
  white-space: pre-line;
  > * + *:not(div) {
    margin-top: 1em;
  }

  a {
    // @include underlineLinks;
  }

  em {
    // @include textTypeItalic;
  }

  .sanity-content-link {
    // @include textWeight800;
    // @include shadowUnderlineOn;

    // @include hover {
    //   @include shadowUnderlineOff;
    // }
  }
}
</style>
