<template>
  <a
    class="a"
    :href="url"
    :class="getClass"
    @mouseenter="$store.commit('header/toggleSubmenu', handle)"
  >
    {{ link.title }}
  </a>
</template>

<script>
export default {
  name: 'GlobalLink',
  props: {
    link: Object,
  },
  computed: {
    submenu() {
      return this.$store.state.header.submenu
    },
    handle() {
      return this.link.url.replace('#submenu_', '')
    },
    url() {
      if (this.handle == 'shop') return '/collections/new-arrivals'
      if (this.handle == 'bandanas') return '/collections/bandanas'
      if (this.handle == 'journal') return '/blogs/journal'
      if (this.handle == 'about') return '/pages/our-story'
      return '/'
    },
    getClass() {
      let isActive = this.handle == this.submenu

      // Handle paths
      const path = window.location.pathname
      if (this.handle == 'shop' && path.startsWith('/collections/new-arrivals')) isActive = true
      if (this.handle == 'bandanas' && path.startsWith('/collections/bandanas')) isActive = true
      if (this.handle == 'journal' && path.startsWith('/blogs/journal')) isActive = true
      if (this.handle == 'about' && path.startsWith('/pages/our-story')) isActive = true

      return {
        'global-link--active': isActive,
      }
    },
  },
}
</script>

<style lang="scss"></style>
