import animateScrollTo from 'animated-scroll-to'

const OPTIONS = {
  // Indicated if scroll animation should be canceled on user action (scroll/keypress/touch)
  // if set to "false" user input will be disabled until scroll animation is complete
  cancelOnUserAction: true,

  // Animation easing function, with "easeOutCubic" as default
  easing: (t) => --t * t * t + 1,

  // DOM element that should be scrolled
  // Example: document.querySelector('#element-to-scroll'),
  elementToScroll: window,

  // Horizontal scroll offset
  // Practical when you are scrolling to a DOM element and want to add some padding
  horizontalOffset: 0,

  // Maximum duration of the scroll animation
  maxDuration: 3000,

  // Minimum duration of the scroll animation
  minDuration: 250,

  // Duration of the scroll per 1000px
  speed: 500,

  // Vertical scroll offset
  // Practical when you are scrolling to a DOM element and want to add some padding
  verticalOffset: -40,
}

export const scrollTo = ({
  element = 'body',
  verticalOffset = -40,
}: {
  element?: string
  verticalOffset?: number
}): void => {
  const targetElement = document.querySelector(element)
  if (!targetElement) {
    console.error(`Element "${element}" not found`)
    return
  }
  animateScrollTo(document.querySelector(element), {
    ...OPTIONS,
    verticalOffset,
  })
}
