<template>
  <div
    :class="`${isActive ? 'overlay-close--active' : ''}`"
    class="overlay-close"
    @click="close"
    @mouseenter="$store.commit('header/setSubmenu', '')"
  ></div>
</template>

<script>
import Vuex from 'vuex'

export default {
  computed: {
    ...Vuex.mapState({
      overlay: (state) => state.overlay.current,
      submenu: (state) => state.header.submenu,
    }),
    isActive() {
      if (this.submenu !== '') return true
      if (this.overlay !== '' && this.overlay !== 'banner') return true
      return false
    },
  },
  methods: {
    close() {
      this.$store.commit('overlay/open', '')
    },
  },
}
</script>

<style lang="scss">
/* @import '~styles/base.scss'; */

.overlay-close {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 150;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
  background: var(--color-off-black);

  &--active {
    opacity: 0.5;
    pointer-events: auto;
  }
}
</style>
