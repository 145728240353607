<template>
  <form
    class="global-newsletter global-newsletter--default"
    method="get"
    @submit.prevent="submit($event)"
  >
    <slot></slot>
    <div class="global-newsletter__fields">
      <input
        v-model="email"
        class="global-newsletter__input"
        type="email"
        placeholder="Email Address"
        required
      />
      <button class="global-newsletter__button">
        <slot name="button"> Submit </slot>
      </button>
    </div>
    <div v-if="message !== ''" class="global-newsletter__message" v-html="message"></div>
  </form>
</template>

<script>
import { NewsletterSubscribe } from '~/lib/klaviyo.ts'
export default {
  props: {
    listId: {
      type: String,
      default: 'NSF6SY',
    },
  },
  data() {
    return {
      email: '',
      message: '',
    }
  },
  methods: {
    async submit() {
      try {
        await NewsletterSubscribe({
          listId: this.listId,
          email: this.email,
        })
        this.message = 'Thanks for signing up!'
        this.email = ''
        this.$emit('submitted')
      } catch (e) {
        console.log(e)
        this.message = 'Please enter a valid email address'
      }
    },
  },
}
</script>
