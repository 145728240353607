<template>
  <div class="collection-filters-controls">
    <div class="collection-filters-controls__buttons">
      <button
        v-for="(filter, index) in selected"
        :key="`collection-filters-controls__selected--${index}`"
        class="collection-filters-controls__button"
        @click="$store.commit('collection/toggleFilter', filter.value)"
      >
        <div class="collection-filters-controls__button-inner">
          <SvgFilterCross class="collection-filters-controls__cross" style="width: 10px" />
          {{ filter.value }}
        </div>
      </button>
    </div>
    <!-- <button class="collection-filters-controls__clear">Clear All</button> -->
  </div>
</template>

<script>
import SvgCross from '../svg/SvgCross.vue'
import SvgFilterCross from '../svg/SvgFilterCross.vue'
export default {
  components: { SvgCross, SvgFilterCross },
  computed: {
    collection() {
      return this.$store.state.collection
    },
    selected() {
      return this.collection.selected
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';
.collection-filters-controls {
  &__buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 4px;
  }

  &__button {
    @include cx-s;
    display: inline-flex;
    background-color: rgba(243, 239, 239, 0.5);
    padding: 2px 4px 1px;
    vertical-align: baseline;
    margin-right: auto;
    color: var(--color-accent);
  }

  &__button-inner {
    position: relative;
    padding-left: 27px;
    padding-right: 8px;
  }

  &__cross {
    /* margin: auto 22px auto 0; */
    align-self: baseline;
    position: absolute;
    left: 4px;
    top: 5px;
    svg {
      fill: var(--color-accent);
    }
  }

  &__clear {
    margin-top: 20px;
  }
}
</style>
