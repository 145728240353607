<template>
  <button class="a" @click.prevent="$store.commit('overlay/open', 'cart')">
    Cart (<span v-text="getItemCount"></span>)
  </button>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'GlobalLink',
  props: {
    link: Object,
  },
  computed: {
    getItemCount() {
      return this.$store.getters['cart/getItemCount']
    },
    ...Vuex.mapState({
      cart: (state) => state.cart,
    }),
  },
}
</script>

<style lang="scss"></style>
