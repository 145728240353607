<template>
  <div class="product-recommended" v-if="product.metafields.recommended.length">
    <div class="product-recommended__inner">
      <h3 class="product-recommended__header">Complete the look</h3>
      <div class="product-recommended__carousel">
        <ProductCards class="product-recommended__cards" :products="getProducts" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductCards from './ProductCards.vue'

export default {
  props: {
    product: Object,
  },
  computed: {
    getProducts() {
      return (this.product.metafields?.recommended || []).slice(0, 3)
    },
  },
  components: {
    ProductCards,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-recommended {
  &__inner {
    position: sticky;
    top: var(--page-top);
  }

  &__header {
    @include cx-uc;
    margin-bottom: 24px;
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    @include respond-to('m+') {
      display: grid;
    }

    @include respond-to('m') {
      min-width: var(--wrap);
    }

    .product-card {
      &__caption {
        @include cx-xxs;
      }
    }
  }

  &__carousel {
    overflow-x: auto;
    padding-bottom: 10px;
  }
}
</style>
