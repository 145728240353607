<template>
  <h2 class="shopify-product-title">
    <span class="shopify-product-title__type">{{ title.type }}</span>
    <span v-if="title.material" class="shopify-product-title__material">({{ title.material }}) </span>
    <span v-if="title.colour" class="shopify-product-title__colour">{{ title.colour }}</span>
  </h2>
</template>

<script>
export default {
  props: {
    product: Object,
  },
  computed: {
    title() {
      const [type, material, colour] = this.product.title.split(',')

      return {
        type,
        material,
        colour,
      }
    },
  },
}
</script>

<style lang="scss">
.shopify-product-title {
}
</style>
