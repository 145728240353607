<template>
  <nav class="global-links">
    <GlobalLink
      v-for="(link, index) in links.links"
      :key="`GlobalLinks__link_${index}`"
      class="global-link global-links__link"
      :link="link"
    />
  </nav>
</template>

<script>
import GlobalLink from './GlobalLink.vue'

export default {
  name: 'GlobalLinks',
  components: {
    GlobalLink,
  },
  props: {
    links: Object,
  },
}
</script>

<style lang="scss">
.global-links {
  display: flex;
  flex-direction: column;
}
</style>
