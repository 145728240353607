<template>
  <form
    class="global-newsletter global-newsletter--mobile"
    method="get"
    @submit.prevent="submit($event)"
  >
    <slot></slot>
    <div class="global-newsletter__fields">
      <input
        v-model="phone"
        class="global-newsletter__input"
        type="tel"
        pattern="^(\d{3} \d{3} \d{4}|\d{10})$"
        placeholder="Mobile Number"
        title="US Phone number XXX XXX XXXX"
        minlength="10"
        maxlength="12"
        required
      />
      <button class="global-newsletter__button">
        <slot name="button"> Submit </slot>
      </button>
    </div>
    <div v-if="message !== ''" class="global-newsletter__message" v-html="message"></div>
  </form>
</template>

<script>
import { MobileSubscribe } from '~/lib/klaviyo.ts'

export default {
  data() {
    return {
      phone: '',
      message: '',
    }
  },
  methods: {
    async submit() {
      try {
        const phone = this.phone.replaceAll('-', '').replaceAll(' ', '')
        await MobileSubscribe({ phone: `+1${phone}` })
        this.message = 'Thanks for signing up!'
        this.email = ''
        this.$emit('submitted')
      } catch (e) {
        console.log(e)
        this.message = 'Please enter a valid phone number'
      }
    },
  },
}
</script>
