import VariantPriceMixin from './variantPrice'
import ProductPriceMixin from './productPrice'
import ProductStockMixin from './productStock'

export default {
  props: {
    product: Object,
    variant: Object,
  },
  mixins: [ProductPriceMixin, VariantPriceMixin, ProductStockMixin],
}
