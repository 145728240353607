<template>
  <span class="link-blank">&nbsp;</span>
</template>

<script>
export default {
  name: 'GlobalLink',
  props: {
    link: Object,
  },
}
</script>
