<template>
  <div class="home-feature2">
    <div class="home-feature2__block home-feature2__block--image">
      <a :href="row.link1" class="home-feature2__block__link"></a>
      <ShopifyImage :src="row.image1" class="home-feature2__block__image" ratio="5x7" />
      <div class="home-feature2__block__caption" v-html="row.caption1"></div>
    </div>
    <div class="home-feature2__block home-feature2__block--caption">
      <h2 class="home-feature2__block__title" v-html="row.title"></h2>
      <div class="home-feature2__block__caption" v-html="row.caption"></div>
      <p>
        <a :href="row.caption_link_url" v-html="row.caption_link"></a>
      </p>
    </div>
    <div class="home-feature2__block home-feature2__block--image">
      <a :href="row.link2" class="home-feature2__block__link"></a>
      <ShopifyImage :src="row.image2" class="home-feature2__block__image" ratio="5x7" />
      <div class="home-feature2__block__caption" v-html="row.caption2"></div>
    </div>
    <div class="home-feature2__block home-feature2__block--image">
      <a :href="row.link3" class="home-feature2__block__link"></a>
      <ShopifyImage :src="row.image3" class="home-feature2__block__image" ratio="5x7" />
      <div class="home-feature2__block__caption" v-html="row.caption3"></div>
    </div>
  </div>
</template>

<script>
import ShopifyImage from '~/components/shopify/ShopifyImage.vue'

export default {
  props: {
    row: {
      type: Object,
    },
  },
  components: {
    ShopifyImage,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-feature2 {
  counter-reset: imageCounter;
  grid-template-areas:
    '. . a a a a a a a a a a . . b b b b b b b b . . '
    '. . . . . . . . . . . . . . c c c c c c c c . . '
    '. . . . d d d d d d . . . . c c c c c c c c . . ';

  @include respond-to(s) {
    grid-template-areas:
      '. a a a a a a a a a a a . b b b b b b b b b b . '
      '. . . . . . . . . . . . . c c c c c c c c c c . '
      '. . d d d d d d d d . . . c c c c c c c c c c . ';
  }

  @include respond-to(xs) {
    grid-template-areas:
      '. . . a a a a a a a a a a a a a a a a a a a . . '
      '. b b b b b b b b b b b b b b b b b b b b . . . '
      '. . . c c c c c c c c c c c c c c c c c c c . . '
      '. d d d d d d d d d d d d d d d d d d d d . . . ';
  }

  > * {
    &:nth-child(1) {
      grid-area: a;
      margin-bottom: 40px;
    }
    &:nth-child(2) {
      grid-area: b;
      margin-top: 90px;
    }
    &:nth-child(3) {
      grid-area: c;
    }
    &:nth-child(4) {
      grid-area: d;
    }
    @include respond-to(xs) {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 0;
        margin-bottom: 80px;
      }
      &:nth-child(2) {
        margin-bottom: 100px;
      }
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }

  &__block {
    &--image {
      position: relative;
      counter-increment: imageCounter;

      .home-feature2__block__link {
        @include cover-link;
        z-index: 10;
        &:hover ~ .home-feature2__block__caption:after {
          opacity: 1;
        }
      }

      .home-feature2__block__caption {
        padding-top: 30px;
        margin-left: 30px;
        @include cx-uc;
        @include sans;
        @include cx-m;
        // @include helper-hover-underline(1);
        @include helper-hover-underline;

        // &:before {
        //   float: left;
        //   content: counter(imageCounter) ".";
        //   width: 30px;
        // }
      }
    }
    &--caption {
      // @include helper-hover-underline(1);
      @include helper-link-underline;
      .home-feature2__block__caption {
        padding-bottom: 30px;
        width: 320px;
        max-width: 100%;
        > div {
          margin-bottom: 30px;
        }
      }
    }

    &__title {
      @include sans;
      @include cx-l;
      @include cx-uc;
      margin-bottom: 30px;
    }
  }
}
</style>
