<template>
  <div class="product-tabs">
    <div class="product-tabs__headings">
      <button
        v-for="(content, _index) in getContents"
        :key="`product-tabs__heading--${_index}`"
        class="product-tabs__heading"
        :class="`${index == _index ? 'product-tabs__heading--active' : ''}`"
        @click="index = _index"
      >
        {{ content.title }}
      </button>
    </div>

    <div class="product-tabs__body">
      <div
        v-for="(content, _index) in getContents"
        :key="`product-tabs__content--${_index}`"
        class="product-tabs__content"
        :class="`${index == _index ? 'product-tabs__content--active' : ''}`"
        v-html="content.html"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
  },
  data() {
    return {
      index: 0,
    }
  },
  computed: {
    getContents() {
      return [
        {
          title: 'Design',
          html: this.product.metafields.design.html,
        },
        {
          title: 'Sizing',
          html: this.product.metafields.sizing.html,
        },
        {
          title: 'Sustainability',
          html: this.product.metafields.sustainability.html,
        },
      ].filter((v) => v.html)
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';
.product-tabs {
  &__headings {
    display: flex;
    position: relative;
    border-bottom: 1px solid var(--color-accent2);
  }

  &__heading {
    position: relative;
    z-index: 10;
    border-left: 1px solid var(--color-accent2);
    border-top: 1px solid var(--color-accent2);
    border-right: 1px solid var(--color-accent2);
    border-bottom: 1px solid var(--color-accent2);

    padding: 5px 10px;
    margin-right: 10px;
    @include cx-uc;
    transform: translateY(1px);

    &--active {
      border-bottom: 1px solid var(--background);
    }
  }

  &__body {
    display: grid;
    position: relative;

    table {
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        width: 20px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
      }
      /* table-layout: fixed; */
      thead {
        @include cx-uc;
        @include sans;
      }

      th + th,
      td + td {
        padding-left: 40px;
      }

      th,
      td {
        padding-bottom: 6px;
        white-space: nowrap;
        text-align: left;

        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }

  &__content {
    @include cx-full;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    opacity: 0;
    pointer-events: none;
    padding-top: 20px;
    transition: opacity 0.2s ease;
    max-width: 100%;
    overflow-x: hidden;
    white-space: pre-line;

    &--active {
      opacity: 1;
      pointer-events: auto;
      z-index: 10;
      overflow-x: auto;
    }
  }
}
</style>
