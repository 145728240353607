<template>
  <div class="review-list">
    <div class="review-list__review" v-for="(review, k) in reviews" :key="`review_${k}`">
      <div class="review-list__user">
        <span>{{ review.user.display_name }}</span>
        <span v-if="review.verified_buyer" class="review-list__verified">Verified Buyer</span>
      </div>
      <div class="review-list__rating">
        <ReviewStars class="review-list__stars" :score="review.score" />
        <div class="review-list__date">{{ getDate(review.created_at) }}</div>
      </div>
      <div class="review-list__breakdown"></div>
      <div class="review-list__content">
        <h3 class="review-list__content__heading" v-html="review.title"></h3>
        <div class="review-list__content__description" v-html="review.content"></div>
        <div class="review-list__content__reply review-list__reply" v-if="review.comment">
          <div class="review-list__reply__heading">OZMA ({{ getDate(review.comment.created_at) }})</div>
          <div class="review-list__reply__content" v-html="review.comment.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import moment from 'moment'

import ReviewStars from './ReviewStars.vue'

export default {
  props: {
    score: Number,
  },
  computed: {
    ...Vuex.mapState({
      reviews: (state) => state.product.reviews,
    }),
  },
  methods: {
    getDate(date) {
      return moment(date).format('MMMM DD, YYYY')
    },
  },
  components: {
    ReviewStars,
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.review-list {
  border-top: 1px solid $dark;

  &__review {
    padding: 1em 0;
    border-bottom: 1px solid $dark;
    display: grid;
    grid-template-columns: 4fr 13fr;
    grid-gap: 2em #{$gutter};

    @include respond-to('m') {
      grid-template-columns: 160px 1fr;
    }

    @include respond-to('xxxs') {
      grid-template-columns: 140px 1fr;
      grid-gap: 1em #{$gutter};
    }
  }

  &__stars {
    transform: scale(0.7);
    transform-origin: center left;
  }

  &__user {
    span {
      display: block;
    }
  }

  &__reply {
    &__heading {
      // @include sans;
      margin-bottom: 0.5em;
    }
  }

  &__content {
    &__heading {
      @include sans;
      margin-bottom: 0.5em;
    }

    @include respond-to('xxxs') {
      grid-column: auto / span 2;
    }

    &__reply {
      margin: 30px 0 0 0;
      padding-left: 20px;
      position: relative;
      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background: $dark;
        opacity: 0.5;
      }
    }
  }

  &__verified {
    opacity: 0.5;
  }
}
</style>
