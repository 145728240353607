<template>
  <div class="page-home">
    <HomeLanding :landing="landing" />
    <HomeRows :sections="sections" />
  </div>
</template>

<script>
import HomeLanding from '../components/home/HomeLanding.vue'
import HomeRows from '../components/home/HomeRows.vue'

export default {
  components: {
    HomeLanding,
    HomeRows,
  },
  props: {
    landing: Object,
    sections: Array,
  },
}
</script>

<style lang="scss">
.page-home {
  padding-top: 0;
}
</style>
