<template>
  <section class="article-landing-default">
    <div class="article-landing-default__inner">
      <GlobalBackgroundSwitch>
        <div class="article-landing-default__image">
          <ShopifyImage :src="article.image" ratio="16x9" />
        </div>
        <div class="article-landing-default__heading">
          <h1 class="article-landing-default__title">{{ article.title }}</h1>
          <p
            v-if="article.metafields.attributions"
            class="article-landing-default__attributions"
            v-html="article.metafields.attributions"
          ></p>
        </div>
      </GlobalBackgroundSwitch>

      <div class="article-landing-default__description" v-if="sanityExcerpt">
        <SanityContent :blocks="sanityExcerpt" />
      </div>

      <div v-else class="article-landing-default__description" v-html="article.excerpt"></div>
    </div>
  </section>
</template>

<script>
import ShopifyImage from '../shopify/ShopifyImage.vue'
import GlobalBackgroundSwitch from '../global/GlobalBackgroundSwitch.vue'
import SanityContent from '../sanity/SanityContent.vue'
export default {
  components: {
    ShopifyImage,
    GlobalBackgroundSwitch,
    SanityContent,
  },
  props: {
    article: Object,
  },
  computed: {
    sanityExcerpt() {
      return this.article.sanity?.data?.excerpt
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.article-landing-default {
  padding-top: 180px;
  &__inner {
    @include offsets(4);
    @include respond-to(l) {
      @include offsets(2);
    }
    @include respond-to(s) {
      @include insets;
    }
  }

  &__image {
    padding: 0 var(--gutter) 40px;
  }

  &__heading {
    text-align: center;
    @include sans;
    padding-bottom: 20px;
  }

  &__title {
    @include cx-l;
    @include cx-uc;
  }

  &__attributions {
    padding-top: 10px;
    a {
      @include helper-underline();
    }
  }

  &__description {
    @include offsetsX(16, 2);
    padding: 0 var(--gutter);

    @include respond-to(l) {
      @include offsetsX(20, 2);
    }

    @include respond-to(s) {
      margin: 0;
    }
  }
}
</style>
