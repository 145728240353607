<template>
  <div class="sanity-content-image" :class="getClass">
    <SanityImage
      class="sanity-content-image__image"
      :asset="asset"
      :alt="alt || ''"
      :ratio="getRatio"
      @onPortrait="onPortrait"
    />
    <div v-if="caption" class="sanity-content-image__caption"><SanityBlocks :blocks="caption" /></div>
  </div>
</template>

<script>
import SanityBlocks from '../SanityBlocks.vue'
import SanityImage from '../SanityImage.vue'

export default {
  props: {
    asset: Object,
    alt: String,
    caption: Array,
    mode: String,
  },
  data() {
    return {
      isPortrait: -1,
    }
  },
  computed: {
    getClass() {
      const classes = []
      if (this.mode) classes.push(`sanity-content-image--${this.mode}`)
      if (this.isPortrait != -1) {
        classes.push(
          this.isPortrait ? 'sanity-content-image--portrait' : 'sanity-content-image--landscape'
        )
      }
      return classes
    },
    getRatio() {
      const dimensions = this.asset?.metadata?.dimensions
      if (dimensions) return `${dimensions.width}x${dimensions.height}`
      return null
    },
  },
  methods: {
    onPortrait(v) {
      this.isPortrait = v
    },
  },
  components: { SanityBlocks, SanityImage },
}
</script>

<style lang="scss">
.sanity-content-image {
  &__caption {
    padding-top: 4px;
  }
}
</style>
